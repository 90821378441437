<script>
import { inject } from "vue";
import useNumber from "@/composable/components/number";
import useText from "@/composable/components/text";
import services from "@/composable/services";
import { HttpStatusCodes } from "@/utils/HttpUtils";
import { ValidationObserver } from "vee-validate";
import TextField from "@/components/forms/TextField.vue";

export default {
  emit: ["changeState"],
  props: {
    signIn: {
      required: true,
      type: Function,
    },
  },
  components: { ValidationObserver, TextField },
  setup(props, context) {
    const { refNumber: activeStep } = useNumber();
    const { refText: newPassword } = useText();
    const { refText: newPasswordConfirm } = useText();
    const login = inject("login");
    const email = inject("email");
    const password = inject("password");
    const verificationKey = inject("verificationKey");

    const testLostPassword = async function (
      login,
      email,
      verificationKey,
      newPassword,
      newPasswordConfirm
    ) {
      password.value =
        newPassword !== null && newPassword === newPasswordConfirm ? newPassword : password.value;
      try {
        await services.loginService.modifAcount({
          login: login,
          email: email,
          verificationKey: verificationKey,
          newPassword: newPassword,
          newPasswordConfirm: newPasswordConfirm,
        });
        if (newPassword !== "" && newPassword === newPasswordConfirm) {
          await props.signIn();
        }
      } catch (errors) {
        if (errors.httpResponseCode === HttpStatusCodes.PRECONDITION_FAILED) {
          services.alertService.toastError(context.$t("alert.user-unknown"), errors);
        } else if (errors.httpResponseCode === HttpStatusCodes.UNAUTHORIZED) {
          services.alertService.toastError(context.$t("alert.user-unknown"), errors);
        } else {
          console.log(errors);
        }
      }
    };
    return {
      //from '@/composition/components/number'
      newPassword,
      newPasswordConfirm,
      verificationKey,
      login,
      email,
      password,
      activeStep,
      testLostPassword,
    };
  },
};
</script>

<template>
  <div class="card">
    <div class="card-content">
      <ValidationObserver ref="observer">
        <b-steps
          v-model="activeStep"
          :animated="true"
          :has-navigation="true"
          :rounded="true"
          label-position="bottom"
        >
          <b-step-item :clickable="false" :label="$t('login.profil')" step="1">
            <h1 class="titleModal has-text-centered">
              {{ $t("login.lost-pwd.title-step-1") }}
            </h1>
            <TextField
              v-model="login"
              rules="min:4"
              label="login.login"
              name="missing-password.login"
              place-holder="login.login-placeholder"
              :mandatory="true"
              @update:value="login = $event"
            />
            <TextField
              v-model="email"
              rules="email"
              label="login.email"
              name="missing-password.email"
              place-holder="login.email-placeholder"
              :mandatory="true"
              @update:value="email = $event"
            />
          </b-step-item>

          <b-step-item :clickable="false" :label="$t('login.pwd')" step="2">
            <h1 class="titleModal has-text-centered">
              {{ $t("login.lost-pwd.title-step-2") }}
            </h1>
            <b-notification
              v-if="email"
              :aria-close-label="$t('message.close')"
              type="is-info is-light"
            >
              <span v-html="$t('login.info-mail-validate-key', { email })" class="column" />
            </b-notification>
            <TextField
              v-model="verificationKey"
              rules="length:10"
              label="login.lost-pwd.temporary-pwd"
              name="missing-password.verificationKey"
              place-holder="login.verificationKey-placeholder"
              :mandatory="true"
              @update:value="verificationKey = $event"
            />
            <TextField
              v-model="newPassword"
              type="password"
              label="login.lost-pwd.new-pwd"
              name="missing-password.new-pwd"
              place-holder="login.pwd-placeholder"
              :mandatory="true"
              @update:value="newPassword = $event"
            />
            <TextField
              :disabled="newPassword === null"
              v-model="newPasswordConfirm"
              rules="confirmed:missing-password.new-pwd"
              type="password"
              label="login.lost-pwd.new-pwd-confirm"
              name="missing-password.new-pwd-confirm"
              place-holder="login.pwd-placeholder"
              :mandatory="true"
              @update:value="newPasswordConfirm = $event"
            />
          </b-step-item>
          <template #navigation="{ previous, next }">
            <b-button
              aria-description="missing-password.toSecondStep"
              :disabled="previous.disabled"
              icon-right="chevron-left"
              outlined
              @click.prevent="previous.action"
            >
            </b-button>
            <b-button
              :disabled="
                activeStep === 1
                  ? verificationKey === '' && newPassword === '' && newPasswordConfirm === ''
                  : login === '' || email === ''
              "
              icon-right="chevron-right"
              outlined
              type="is-primary"
              @click="
                testLostPassword(login, email, verificationKey, newPassword, newPasswordConfirm)
              "
              @click.prevent="next.action"
            >
              {{ activeStep === 1 ? $t("login.finish-step") : $t("login.next-step") }}
            </b-button>
          </template>
        </b-steps>
      </ValidationObserver>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
