<template>
  <div>
    <b-collapse
      v-if="
        localeInitialized &&
        ((localeReferences.withoutScope &&
          Object.keys(localeReferences.withoutScope).length !== 0) ||
          (localeReferences.withScope && Object.keys(localeReferences.withScope).length !== 0))
      "
      :open="false"
      animation="slide"
      aria-id="contentIdForA11y3"
      class="card"
      style="box-shadow: none"
    >
      <template #trigger="detail">
        <div
          :aria-expanded="!detail.open"
          aria-controls="contentIdForA11y3"
          class="card-header"
          role="button"
          style="box-shadow: none; border-bottom: thick double #009d9d"
        >
          <p class="card-header-title">
            {{ $t("applications.references") }}
          </p>
          <a class="card-header-icon">
            <b-icon :icon="detail.open ? 'chevron-up' : 'chevron-down'" type="is-primary"></b-icon>
          </a>
        </div>
      </template>
      <div class="card-content">
        <div class="content">
          <AuthorizationsForNoAuthorization
            v-if="
              localeInitialized &&
              localeReferences.withoutScope &&
              Object.keys(localeReferences.withoutScope).length !== 0
            "
            :application="localeApplication"
            :authorization="
              localeCurrentAuthorization.authorizations
                ? localeCurrentAuthorization.authorizations
                : localeAuthorizations
            "
            :data-types="localeReferences.withoutScope"
            :has-dependencies="localeHasDependencies"
            :list-column-name="localeListColumnName['withoutScope']"
            @update:authorization="$emit('update:authorization', $event)"
          >
          </AuthorizationsForNoAuthorization>
          <AuthorizationForScope
            v-if="
              localeInitialized &&
              localeReferences.withScope &&
              Object.keys(localeReferences.withScope).length !== 0
            "
            :application="localeApplication"
            :authorization="
              localeCurrentAuthorization.authorizations
                ? localeCurrentAuthorization.authorizations
                : localeAuthorizations
            "
            :authorizations-description="localeAuthorizations"
            :data-types="datatypes.withScope"
            :has-dependencies="localeHasDependencies"
            :list-column-name="localeListColumnName['withScope']"
            :references-scopes="localeReferencesScopes"
            @update:authorization="$emit('update:authorization', $event)"
          >
          </AuthorizationForScope>
        </div>
      </div>
    </b-collapse>
    <b-collapse
      v-if="
        localeInitialized &&
        ((localeDatatypes.withoutScope && Object.keys(localeDatatypes.withoutScope).length !== 0) ||
          (localeDatatypes.withScope && Object.keys(localeDatatypes.withScope).length !== 0))
      "
      :open="false"
      animation="slide"
      aria-id="contentIdForA11y3"
      class="card"
      style="box-shadow: none"
    >
      <template #trigger="detail">
        <div
          :aria-expanded="!detail.open"
          aria-controls="contentIdForA11y3"
          class="card-header"
          role="button"
          style="box-shadow: none; border-bottom: thick double #009d9d"
        >
          <p class="card-header-title">
            {{ $t("applications.dataset") }}
          </p>
          <a class="card-header-icon">
            <b-icon :icon="detail.open ? 'chevron-up' : 'chevron-down'" type="is-primary"></b-icon>
          </a>
        </div>
      </template>
      <div class="card-content">
        <div class="content">
          <AuthorizationsForNoAuthorization
            v-if="
              localeInitialized &&
              localeDatatypes.withoutScope &&
              Object.keys(localeDatatypes.withoutScope).length !== 0
            "
            :application="localeApplication"
            :authorization="
              localeCurrentAuthorization.authorizations
                ? localeCurrentAuthorization.authorizations
                : localeAuthorizations
            "
            :data-types="localeDatatypes.withoutScope"
            :has-dependencies="localeHasDependencies"
            :list-column-name="localeListColumnName['withoutScope']"
            @update:authorization="$emit('update:authorization', $event)"
          >
          </AuthorizationsForNoAuthorization>
          <AuthorizationForScope
            v-if="
              localeInitialized &&
              localeDatatypes.withScope &&
              Object.keys(localeDatatypes.withScope).length !== 0
            "
            :application="localeApplication"
            :authorization="
              localeCurrentAuthorization.authorizations
                ? localeCurrentAuthorization.authorizations
                : localeAuthorizations
            "
            :authorizations-description="localeAuthorizations"
            :data-types="localeDatatypes.withScope"
            :has-dependencies="localeHasDependencies"
            :list-column-name="localeListColumnName['withScope']"
            :references-scopes="localeReferencesScopes"
            @update:authorization="$emit('update:authorization', $event)"
          >
          </AuthorizationForScope>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import AuthorizationsForNoAuthorization from "@/components/common/authorization/AuthorizationsForNoAuthorization.vue";
import AuthorizationForScope from "@/components/common/authorization/AuthorizationForScope.vue";
import { ref, watchEffect } from "vue";

export default {
  name: "AuthorizationTableForDatatype",
  emits: ["update:authorization"],
  components: {
    AuthorizationsForNoAuthorization,
    AuthorizationForScope,
  },
  props: {
    applicationName: String,
    authorizationId: {
      type: String,
      default: "new",
    },
    datatypes: {
      type: Object,
      required: true,
    },
    listColumnName: {
      type: Object,
      required: true,
    },
    hasDependencies: {
      type: Array,
      default: () => [],
    },
    initialized: Boolean,
    references: {
      type: Object,
      required: true,
    },
    application: {
      type: Object,
      required: true,
    },
    currentAuthorization: {
      type: Object,
      required: true,
    },
    authorizations: {
      type: Object,
    },
    referencesScopes: {
      type: Object,
    },
  },
  setup(props) {
    const localeApplicationName = ref("");
    const localeAuthorizationId = ref("");
    const localeDatatypes = ref({});
    const localeListColumnName = ref({});
    const localeHasDependencies = ref([]);
    const localeInitialized = ref(false);
    const localeReferences = ref({});
    const localeApplication = ref({});
    const localeCurrentAuthorization = ref({});
    const localeAuthorizations = ref({});
    const localeReferencesScopes = ref({});

    watchEffect(() => {
      localeApplicationName.value = props.applicationName || "";
      localeAuthorizationId.value = props.authorizationId || "";
      localeDatatypes.value = props.datatypes;
      localeListColumnName.value = props.listColumnName;
      localeHasDependencies.value = props.hasDependencies || [];
      localeInitialized.value = props.initialized || false;
      localeReferences.value = props.references;
      localeApplication.value = props.application || {};
      localeCurrentAuthorization.value = props.currentAuthorization || {};
      localeAuthorizations.value = props.authorizations || {};
      localeReferencesScopes.value = props.referencesScopes || {};
    });

    return {
      localeApplicationName,
      localeAuthorizationId,
      localeDatatypes,
      localeListColumnName,
      localeHasDependencies,
      localeInitialized,
      localeReferences,
      localeApplication,
      localeCurrentAuthorization,
      localeAuthorizations,
      localeReferencesScopes,
    };
  },
};
</script>

<style lang="scss" scoped>
.authorizationTable {
  margin-left: 10px;
  padding: 0 0 0 5px;

  button {
    opacity: 0.75;
  }

  .dropdown-menu .dropdown-content .dropDownMenu button {
    opacity: 0.5;
  }

  .dgSelected {
    color: #007f7f;
  }
}

a {
  color: $dark;
  font-weight: bold;
  text-decoration: underline;
}

a:hover {
  color: $primary;
  text-decoration: none;
}

p {
  font-weight: bold;
}

::marker {
  color: transparent;
}

.column {
  padding: 6px;
}

.show-check-details {
  margin-left: 0.6em;
}

.show-detail-for-selected {
  height: 60px;
}
</style>
