<template>
  <ValidationProvider
    v-slot="{ errors, valid }"
    :name="vid"
    :rules="rules"
    :vid="vid"
    class="column is-12"
  >
    <b-field
      :label="label"
      :message="errors"
      :type="{
        'is-danger': errors && errors.length > 0,
        'is-success': valid,
      }"
      class="file is-primary column is-12"
    >
      <template v-slot:label>
        <span v-if="required" class="required">{{ $t("ponctuation.star") }}</span>
        <label>{{ label }}</label>
      </template>
      <b-taginput
        v-if="multiplicity === 'MANY'"
        v-model="dateValue[vid]"
        required
        type="text"
        @blur="updateValue"
        @input="updateValue"
      />
      <InputDate
        v-else
        v-model="dateValue[vid]"
        :format="checker.pattern"
        :from="dateValue[vid]"
        :is-simple-value="true"
        input-type="date"
        @update:dateValue="updateValue"
      />
    </b-field>
  </ValidationProvider>
</template>

<script>
import moment from "moment";
import { extend, ValidationProvider } from "vee-validate";
import { watch, ref, reactive } from "vue";
import InputDate from "@/components/common/provider/InputDate.vue";
import { datePatternLang, parseDate } from "@/composable/application/DatePattern";

export default {
  name: "OreInputDate",
  emits: ["update:value"],
  components: {
    InputDate,
    ValidationProvider,
  },
  props: {
    checker: {
      type: Object,
      required: false,
    },
    value: {
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    vid: {
      type: String,
      required: false,
    },
  },
  setup(props, ctx) {
    const val = ref(props.value);
    watch(
      () => props.value,
      (newValue) => {
        val.value = newValue;
      },
      { immediate: true }
    );

    const dateValue = reactive({});
    watch(
      () => [props.vid, val.value, props.checker.pattern],
      ([vid, value, pattern]) => {
        if (typeof value === "string") {
          dateValue[vid] = parseDate(value, pattern);
        } else {
          dateValue[vid] = value;
        }
      },
      { immediate: true }
    );

    const pattern = {
      "dd/MM/yyyy": {
        pattern: "DD-MM-YYYY",
        regexp: /(\d{2})\/(\d{2})\/(\d{4})/,
        replace: "$1 $2 $3",
      },
      "dd/MM/yy": {
        pattern: "DD-MM-YY",
        regexp: /(\d{2})\/(\d{2})\/(\d{2})/,
        replace: "$1 $2 $3",
      },
      "MM/yyyy": {
        pattern: "MM-YYYY",
        regexp: /(\d{2})\/(\d{4})/,
        replace: "$1 $2",
      },
      "MM/yy": {
        pattern: "MM-YY",
        regexp: /(\d{2})\/(\d{2})/,
        replace: "$1 $2",
      },
      yyyy: {
        pattern: "YYYY",
        regexp: /(\d{4})/,
        replace: "$1",
      },
      "hh:mm": {
        pattern: "hh:mm",
        regexp: /(\d{2}):(\d{2})/,
        replace: "$1 $2",
      },
      "HH:mm:ss": {
        pattern: "HH:mm:ss",
        regexp: /(\d{2}):(\d{2}):(\d{2})/,
        replace: "$1 $2 $3",
      },
      "dd/MM/yyyy HH:mm:ss": {
        pattern: "DD-MM-YYYY HH:mm:ss",
        regexp: /(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2}):(\d{2})/,
        replace: "$1 $2 $3 $4:$5:$6",
      },
      "dd/MM/yy HH:mm:ss": {
        pattern: "DD-MM-YY HH:mm:ss",
        regexp: /(\d{2})\/(\d{2})\/(\d{2}) (\d{2}):(\d{2}):(\d{2})/,
        replace: "$1 $2 $3 $4:$5:$6",
      },
    };

    function validDate(value) {
      let patternElement = pattern[props.checker.pattern];
      let formattedDate = moment(value, patternElement.pattern).format(patternElement.pattern);
      let parsedDate = formattedDate.replaceAll("-", "/");
      return parsedDate === value;
    }

    function validateDate(value) {
      if (Array.isArray(value)) {
        return value.map(validDate).filter((v) => v === false).length === 0;
      } else {
        return validDate(value);
      }
    }

    function validateRequired(value) {
      if (typeof value == "string") {
        return !!value;
      } else {
        return value.length > 0;
      }
    }

    function updateValue(event) {
      if (typeof event == "object") {
        if (event.value instanceof Date) {
          event = datePatternLang(new Date(event.value).toISOString(), props.checker.pattern);
        } else {
          event = event.value;
        }
      }
      ctx.emit("update:value", event);
    }

    return {
      validDate,
      validateDate,
      validateRequired,
      updateValue,
      val,
      pattern,
      dateValue,
    };
  },
  methods: {
    extend,
  },
  computed: {
    required: {
      get() {
        return this.checker && this.checker.required;
      },
    },
    multiplicity: {
      get() {
        return this.checker && this.checker.multiplicity === "MANY";
      },
    },
    isValidDate: {
      get() {
        return this.parsedDate === this.value;
      },
    },
    rules: {
      get() {
        let rules = [];
        if (this.checker) {
          if (this.checker.name === "Date") {
            if (this.checker.pattern) {
              this.extend("date", (value) => {
                return this.validateDate(value) || this.$t("rules.date", this.checker.params);
              });
              rules.push("date");
            }
          }
          if (this.checker.required) {
            this.extend("required", (value) => {
              return !!value || this.$t("rules.required");
            });
            rules.push("required");
          }
        }
        return rules.join("|");
      },
    },
  },
};
</script>

<style scoped>
.required {
  color: rgb(255, 0, 0);
  padding-right: 5px;
  font-size: 150%;
}
</style>
