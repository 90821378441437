import { shallowRef } from "vue";

function useArray(arr = []) {
  const shallowRefArray = shallowRef(arr);
  const doChangeArray = function (newArray) {
    shallowRefArray.value = newArray;
  };
  const doAddToArray = function (value) {
    shallowRefArray.value.push(value);
  };

  return {
    shallowRefArray,
    doChangeArray,
    doAddToArray,
  };
}

export default useArray;
