import { i18n } from "@/main";
//prettier-ignore

const ERRORS = {
    //erreur yaml
    badBooleanRequiredSections: (params) => i18n.t("errors-yaml.badBooleanRequiredSections.message", params),
    invalidIdentificateurs: (params) => i18n.t("errors-yaml.badBooleanRequiredSections.message", params),
    badConstantImportHeaderRowNumber: (params) => i18n.t("errors-yaml.badConstantImportHeaderRowNumber.message", params),
    badDomainTagPattern: (params) => i18n.t("errors-yaml.badDomainTagPattern.message", params),
    badEnumSectionType: (params) => i18n.t("errors-yaml.badEnumSectionType.message", params),
    badFloatRequiredSections: (params) => i18n.t("errors-yaml.badFloatRequiredSections.message", params),
    badIntegerRequiredSections: (params) => i18n.t("errors-yaml.badIntegerRequiredSections.message", params),
    badLocaleLabels: (params) => i18n.t("errors-yaml.badLocaleLabels.message", params),
    badLocaleSectionType: (params) => i18n.t("errors-yaml.badLocaleSectionType.message", params),
    badTagsPatterns: (params) => i18n.t("errors-yaml.badTagsPatterns.message", params),
    badVersionPattern: (params) => i18n.t("errors-yaml.badVersionPattern.message", params),
    illegalDomainTagPattern: (params) => i18n.t("errors-yaml.illegalDomainTagPattern.message", params),
    invalidComponentReferenceForAuthorizationScopeAuthorization: (params) => i18n.t("errors-yaml.invalidComponentReferenceForAuthorizationScopeAuthorization.message", params),
    invalidMinMaxForCheckerDate: (params) => i18n.t("errors-yaml.invalidMinMaxForCheckerDate.message", params),
    invalidNaturalKey: (params) => i18n.t("errors-yaml.invalidNaturalKey.message", params),
    invalidPatternForCheckerDate: (params) => i18n.t("errors-yaml.invalidPatternForCheckerDate.message", params),
    missingAnyMandatoriesSections: (params) => i18n.t("errors-yaml.missingAnyMandatoriesSections.message", params),
    missingCheckerName: (params) => i18n.t("errors-yaml.missingCheckerName.message", params),
    missingColumnNameValidation: (params) => i18n.t("errors-yaml.missingColumnNameValidation.message", params),
    missingComponentForComponentName: (params) => i18n.t("errors-yaml.missingComponentForComponentName.message", params),
    missingConstantImportHeaderColumnOrRowNumber: (params) => i18n.t("errors-yaml.missingConstantImportHeaderColumnOrRowNumber.message", params),
    missingConstantImportHeaderRowNumber: (params) => i18n.t("errors-yaml.missingConstantImportHeaderRowNumber.message", params),
    missingMandatoriesSections: (params) => i18n.t("errors-yaml.missingMandatoriesSections.message", params),
    missingNameApplication: (params) => i18n.t("errors-yaml.missingNameApplication.message", params),
    missingPatternForCheckerDate: (params) => i18n.t("errors-yaml.missingPatternForCheckerDate.message", params),
    missingReferenceAndComponentName: (params) => i18n.t("errors-yaml.missingReferenceAndComponentName.message", params),
    missingReferenceName: (params) => i18n.t("errors-yaml.missingReferenceName.message", params),
    missingRequiredEnumValue: (params) => i18n.t("errors-yaml.missingRequiredEnumValue.message", params),
    missingRequiredValue: (params) => i18n.t("errors-yaml.missingRequiredValue.message", params),
    missingVersionApplication: (params) => i18n.t("errors-yaml.missingVersionApplication.message", params),
    negativeConstantImportHeaderRowNumber: (params) => i18n.t("errors-yaml.negativeConstantImportHeaderRowNumber.message", params),
    negativeConstantImportHeaderColumnNumber: (params) => i18n.t("errors-yaml.negativeConstantImportHeaderColumnNumber.message", params),
    notExpectedDomainTags: (params) => i18n.t("errors-yaml.notExpectedDomainTags.message", params),
    undefinedComponentForAuthorizationScopeAuthorization: (params) => i18n.t("errors-yaml.undefinedComponentForAuthorizationScopeAuthorization.message", params),
    unexpectedSubmission: (params) => i18n.t("errors-yaml.unexpectedSubmission.message", params),
    unexpectedSections: (params) => i18n.t("errors-yaml.unexpectedSections.message", params),
    unknownComponentForComponentName: (params) => i18n.t("errors-yaml.unknownComponentForComponentName.message", params),
    unknownCheckerName: (params) => i18n.t("errors-yaml.unknownCheckerName.message", params),
    unknownNameAuthorizationScope: (params) => i18n.t("errors-yaml.unknownNameAuthorizationScope.message", params),
    unknownReferenceName: (params) => i18n.t("errors-yaml.unknownReferenceName.message", params),
    unknownReferenceColumnToLookForHeader: (params) => i18n.t("errors-yaml.unknownReferenceColumnToLookForHeader.message", params),
    unknownStrategySubmission: (params) => i18n.t("errors-yaml.unknownStrategySubmission.message", params),
    unsuportedI18nKeyLanguage: (params) => i18n.t("errors-yaml.unsuportedI18nKeyLanguage.message", params),
    unsupportedNameApplication: (params) => i18n.t("errors-yaml.unsupportedNameApplication.message", params),
    unsupportedOpenadomVersion: (params) => i18n.t("errors-yaml.unsupportedOpenadomVersion.message", params),
    unsupportedVersionApplication: (params) => i18n.t("errors-yaml.unsupportedVersionApplication.message", params),
    duplicatedComponentName: (params) => i18n.t("errors-yaml.duplicatedComponentName.message", params),
    duplicatedComponentHeader: (params) => i18n.t("errors-yaml.duplicatedComponentHeader.message", params),
    invalidConfigurationFile: (params) => i18n.t("errors-yaml.invalidConfigurationFile.message", params),
    badGroovyExpression:  (params)=> i18n.t("errors-yaml.badGroovyExpression.message", params),

    //erreur csv
    badAuthorizationScopeForRepository: (params) => i18n.t("errors-csv.badAuthorizationScopeForRepository.message", params),
    badMaxIntervalFloatWithComponent: (params) => i18n.t("errors-csv.badMaxIntervalFloatWithComponent.message", params),
    badMinIntervalFloatWithComponent: (params) => i18n.t("errors-csv.badMinIntervalFloatWithComponent.message", params),
    duplicatedHeaders: (params) => i18n.t("errors-csv.duplicatedHeaders.message", params),
    duplicatedLineInDatatype: (params) => i18n.t("errors-csv.duplicatedLineInDatatype.message", params),
    duplicatedLineInReference: (params) => i18n.t("errors-csv.duplicatedLineInReference.message", params),
    emptyFile: (params) => i18n.t("errors-csv.emptyFile.message", params),
    emptyHeader: (params) => i18n.t("errors-csv.emptyHeader.message", params),
    headerColumnPatternNotMatching: (params) => i18n.t("errors-csv.headerColumnPatternNotMatching.message", params),
    invalidHeaders: (params) => i18n.t("errors-csv.invalidHeaders.message", params),
    invalidReferenceWithComponent: (params) => i18n.t("errors-csv.invalidReferenceWithComponent.message", params),
    timeRangeOutOfInterval: (params) => i18n.t("errors-csv.timeRangeOutOfInterval.message", params),
    unexpectedHeaderColumn: (params) => i18n.t("errors-csv.unexpectedHeaderColumn.message", params),
    unexpectedHeaderColumnsInList: (params) => i18n.t("errors-csv.unexpectedHeaderColumnsInList.message", params),
    unexpectedTokenCount: (params) => i18n.t("errors-csv.unexpectedTokenCount.message", params),
    invalidFloatWithComponent: (params) => i18n.t("errors-csv.invalidFloatWithComponent.message", params),
    invalidIntegerWithComponent: (params) => i18n.t("errors-csv.invalidIntegerWithComponent.message", params),
    invalidDateWithComponent: (params) => i18n.t("errors-csv.invalidDateWithComponent.message", params),
    requiredValueWithComponent: (params) => i18n.t("errors-csv.requiredValueWithComponent.message", params),

// erreur login
    EXPIRED_VALIDATION_KEY: (params) => i18n.t("exceptionMessage.EXPIRED_VALIDATION_KEY", params),
    INACTIVE_ACCOUNT: (params) => i18n.t("exceptionMessage.INACTIVE_ACCOUNT", params),
    PENDING_ACCOUNT: (params) => i18n.t("exceptionMessage.PENDING_ACCOUNT", params),
    CLOSED_ACCOUNT: (params) => i18n.t("exceptionMessage.CLOSED_ACCOUNT", params),
    BAD_LOGIN_PASSWORD: (params) => i18n.t("exceptionMessage.BAD_LOGIN_PASSWORD", params),
    BAD_VALIDATION_KEY: (params) => i18n.t("exceptionMessage.BAD_VALIDATION_KEY", params),
    BAD_LOGIN_OR_EMAIL_PASSWORD: (params) => i18n.t("exceptionMessage.BAD_LOGIN_OR_EMAIL_PASSWORD", params),
    BAD_PASSWORDS: (params) => i18n.t("exceptionMessage.BAD_PASSWORDS", params),

    // erreur errors-OA-v1
    patternNotMatchedWithComponent: (params) => i18n.t("errors-OA-v1.patternNotMatchedWithComponent", params),
};

export class ErrorsService {
  static INSTANCE = new ErrorsService();

  getErrorsMessages(errors, params, type) {
    if (typeof errors === "string") {
      switch (type) {
        case "errors-yaml.":
          return i18n.t(type + errors + ".message", this.transformeArrayParams(params));
        case "errors-csv.":
          return i18n.t(type + errors, this.transformeArrayParams(params));
        default:
          return i18n.t("errors." + errors, this.transformeArrayParams(params));
      }
    } else {
      return errors.map((error) => {
        const func = ERRORS[error.message];
        if (!func) {
          //throw new Error("Il manque la chaine de traduction pour l'erreur : " + error.message);
          return i18n.t("errors.exception");
        }
        return func(error.messageParams);
      });
    }
  }

  getErrorsLoginMessages(errors, params) {
    if (typeof errors === "string") {
      return i18n.t("exceptionMessage." + errors, this.transformeArrayParams(params));
    }
  }

  transformeArrayParams(params) {
    if (!params || params.toString() !== "[object Object]") {
      return params;
    }

    return Object.fromEntries(
      Object.entries(params).map(([key, value]) => {
        if (Array.isArray(value)) {
          return [
            key,
            value.map((s) =>
              typeof s === "string" ? `<span class="messageElement">${s}</span>` : s
            ),
          ];
        }
        return [key, value];
      })
    );
  }

  getCsvErrorsMessages(csvErrors) {
    return csvErrors.map((csvError) => {
      const func = ERRORS[csvError.validationCheckResult.message];

      if (csvError.validationCheckResult.messageParams.target != null) {
        if (csvError.validationCheckResult.messageParams.target.column != null) {
          csvError.validationCheckResult.messageParams.target =
            csvError.validationCheckResult.messageParams.target.column;
        }
        if (csvError.validationCheckResult.messageParams.target.id != null) {
          csvError.validationCheckResult.messageParams.target =
            csvError.validationCheckResult.messageParams.target.id;
        }
      }
      if (
        csvError.validationCheckResult.messageParams.expectedValue != null ||
        csvError.validationCheckResult.messageParams.givenValue != null
      ) {
        if (csvError.validationCheckResult.messageParams.expectedValue.sql != null) {
          csvError.validationCheckResult.messageParams.expectedValue =
            csvError.validationCheckResult.messageParams.expectedValue.sql;
        }
        if (csvError.validationCheckResult.messageParams.givenValue.sql != null) {
          csvError.validationCheckResult.messageParams.givenValue =
            csvError.validationCheckResult.messageParams.givenValue.sql;
        }
      }
      if (!func) {
        //throw new Error("Il manque la chaine de traduction pour l'erreur : " + csvError.validationCheckResult.message);
        return Error(i18n.t("errors.exception") + csvError.validationCheckResult.message);
      }
      const params = {
        lineNumber: csvError.lineNumber,
        ...this.transformeArrayParams(csvError.validationCheckResult.messageParams),
      };
      return func(params);
    });
  }
}
