<template>
  <PageView :application="application" class="with-submenu">
    <SubMenu
      :aria-label="$t('menu.aria-sub-menu')"
      :paths="subMenuPaths"
      :root="application.localName"
      role="navigation"
    />
    <h1 class="title main-title">
      {{ $t("titles.references-data", { refName: application.localRefName }) }}
    </h1>
    <div id="tagsCollapse" class="column">
      <TagsCollapse
        v-if="hasTags"
        :tags="tags"
        @change:tag="changeTagSelected($event, 'changeTagSelected')"
        @change:allTags="changeAllValueTags($event)"
      />
    </div>
    <FiltersDataCollapse
      :application="application"
      :application-name="applicationName"
      :columns-to-be-shown="dataColumnsToBeShown"
      :data-id="dataId"
      :is-ref-link-to="referenceTypeForReferencingColumns"
      @view-search="recalculate($event)"
      @download-search="recalculate($event, true)"
      @clear-search="clear($event)"
    />
    <LoadingAnimate v-if="isLoading" :size="'is-large'"></LoadingAnimate>
    <div v-if="!onlyMetadata && data && dataColumnsToBeShown && !isLoading">
      <b-table
        id="filtreTable"
        :current-page="currentPage"
        :data="rows"
        :is-focusable="true"
        :is-hoverable="true"
        :per-page="params.limit"
        :sticky-header="true"
        pagination-position="both"
        :height="tableHeight"
        paginated
        striped
        style="padding-bottom: 20px; position: relative; z-index: 2"
      >
        <template #pagination>
          <b-pagination
            v-model="currentPage"
            :aria-current-label="$t('menu.aria-curent-page')"
            :aria-label="$t('menu.aria-pagination')"
            :aria-next-label="$t('menu.aria-next-page')"
            :aria-previous-label="$t('menu.aria-previous-page')"
            :current-page.sync="currentPage"
            :per-page="params.limit"
            :rounded="true"
            :total="
              totalRows ? totalRows : lineCountSynthesis(application.referenceSynthesis, dataId)
            "
            order="is-centered"
            range-after="3"
            range-before="3"
            role="navigation"
            @change="changePage"
            style="margin: 0; padding-top: 20px; padding-bottom: 20px"
          />
        </template>
        <b-table-column
          v-for="column in dataColumnsToBeShown"
          :key="column.id"
          :field="column.id"
          style="padding-bottom: 20px; position: relative; z-index: 2"
          sortable
        >
          <template v-slot:header>
            <div v-if="column.tags" class="column" style="padding: 0">
              <TagsInfos
                :info-parent="column"
                :tags-column="Object.keys(tags).length !== 0 ? tags : {}"
              >
              </TagsInfos>
              <DatasPatternLink
                v-if="'PatternComponent' === column.type"
                :info="column.type === 'PatternComponent'"
                :application="application"
                :value="column.getColumnQualifiersMap(application, dataId, rows[0])"
                :column-id="column.id"
                :column-title="column.id"
                :info-values="column.getColumnQualifiersMap(application, dataId, rows[0])"
                :loaded-references-by-key="{}"
                :pattern-checker-date-ref="patternCheckerDateRef"
                :data-id="dataId"
              ></DatasPatternLink>
              <div v-else>
                {{ column.getHeader(application, dataId) }}
              </div>
            </div>
            <div v-else class="column">
              {{ column.getHeader(application, dataId) }}
              <DatasDynamicLink
                v-if="'PatternComponent' === column.type"
                :application="application"
                :column-id="column.id"
                :info="column.type === 'PatternComponent'"
                :info-values="column.getColumnQualifiersMap(props)"
                :loaded-references-by-key="{}"
                :reference-type="column.referenceType"
              ></DatasDynamicLink>
            </div>
          </template>
          <template v-slot="props">
            <DatasDynamicLink
              v-if="column.type === 'DynamicComponent'"
              :application="application"
              :column-id="column.id"
              :info="column.type === 'DynamicComponent'"
              :info-values="props.row.values[column.id]"
              :loaded-references-by-key="{}"
              :reference-type="column.referenceType"
            ></DatasDynamicLink>
            <DatasManyLink
              v-else-if="column.multiplicity === MANY"
              :application="application"
              :column="column"
              :column-id="column.id"
              :component="props.row"
              :info-values="props.row.values[column.id]"
              :displays-for-row="props.row.displaysForRow"
              :loaded-references-by-key="{}"
              :multiplicity="multiplicity(column.id, props.row.values[column.id])"
              :reference-type="addRefLinkedTo(column.id, column.linkedTo)"
            ></DatasManyLink>
            <DatasLink
              v-else-if="column.id !== '#'"
              :application="application"
              :column-id="getRefColumnId(props.row, column)"
              :column-title="getColumnNameView(column.id, application, dataId)"
              :component="props.row"
              :display-value="'' + column.getDisplayValue(props.row, column)"
              :loaded-references-by-key="{}"
              :pattern-checker-date="patternCheckerDateRef(application, column.title, dataId)"
              :reference-type="column.refLinkedTo"
              :value="'' + column.getColumnValue(props.row)"
            ></DatasLink>
            <div v-else class="columns">
              <a @click="askDeletionConfirmation(rows[tableValues.indexOf(props.row)]?.naturalKey)">
                <b-icon class="clickable" icon="times-circle" size="is-small" type="is-danger">
                </b-icon>
              </a>
              <b-collapse :open="false" class="column">
                <template #trigger>
                  <b-button
                    :label="'' + (tableValues.indexOf(props.row) + 1 + params.offset)"
                    aria-controls="contentIdForA11y1"
                    type="is-small"
                  />
                </template>
                {{ rows[tableValues.indexOf(props.row)]?.naturalKey }}
              </b-collapse>
            </div>
          </template>
        </b-table-column>
      </b-table>
    </div>

    <div class="buttons" style="margin-top: 16px">
      <!--        <b-button @click="loadExampleData">Afficher un exemple</b-button>-->
      <b-button
        style="margin-bottom: 15px; float: right"
        type="is-primary"
        icon-left="download"
        @click.prevent="downloadResultSearch"
        >{{ $t("dataTable.donwload-view-result") }}
      </b-button>
    </div>
  </PageView>
</template>

<script>
import SubMenu, { SubMenuPath } from "@/components/common/SubMenu.vue";
import { DownloadDatasetQuery } from "@/model/application/DownloadDatasetQuery";
import DatasLink from "@/components/datas/DatasLink.vue";
import DatasManyLink from "@/components/datas/DatasManyLink.vue";
import DatasDynamicLink from "@/components/datas/DatasDynamicLink.vue";
import TagsCollapse from "@/components/common/TagsCollapse.vue";
import LoadingAnimate from "@/components/common/LoadingAnimate.vue";
import useObject from "@/composable/components/object";
import useBoolean from "@/composable/components/boolean";
import useNumber from "@/composable/components/number";
import useArray from "@/composable/components/array";
import services from "@/composable/services";
import { computed, onMounted, provide, ref } from "vue";
import { i18n } from "@/main";
import app from "@/main";
import PageView from "../common/PageView.vue";
import { buildTagsColumns } from "@/composable/application/tags";
import { checkMessageErrors } from "@/composable/application/errors";
import { ApplicationResult } from "@/model/ApplicationResult";
import FiltersDataCollapse from "@/components/common/provider/FiltersDataCollapse.vue";
import TagsInfos from "@/components/common/TagsInfos.vue";
import { Component } from "@/model/application/Component";
import { lineCountSynthesis } from "@/composable/application/synthesis";
import { dataLoader } from "@/composable/data/dataLoader";
import { patternCheckerDateRef } from "@/composable/application/DatePattern";
import DatasPatternLink from "@/components/datas/DatasPatternLink.vue";
import { Tag } from "@/model/application/Tag";

export default {
  name: "DataTableView",
  props: {
    applicationName: String,
    dataId: String,
  },
  components: {
    DatasPatternLink,
    TagsInfos,
    FiltersDataCollapse,
    LoadingAnimate,
    PageView,
    SubMenu,
    TagsCollapse,
    DatasLink,
    DatasManyLink,
    DatasDynamicLink,
  },
  setup(props) {
    const onlyMetadata = ref(true);
    const { reactiveObject: params } = useObject(new DownloadDatasetQuery(0, 10));
    const loader = dataLoader(services);
    provide("reference:dataLoader", loader);
    const { getColumnNameView } = loader;
    const { shallowRefArray: errorsList } = useArray();
    const { shallowRefArray: errorsMessages } = useArray();
    const { refNumber: currentPage, doChangeNumber: changeCurrentPage } = useNumber(1);
    const { refBoolean: isLoading, doChangeBoolean: changeIsLoading } = useBoolean();
    const { reactiveObject: application, doChangeObject: changeApplication } = useObject(
      new ApplicationResult()
    );
    const { shallowRefArray: data, doChangeArray: changeData } = useArray();
    const { shallowRefArray: subMenuPaths, doChangeArray: changeSubMenuPaths } = useArray();
    const { shallowRefArray: columns, doChangeArray: changeColumns } = useArray();
    const { shallowRefArray: rows, doChangeArray: changeRows } = useArray();
    const { shallowRefArray: tableValues, doChangeArray: changeTableValues } = useArray();
    const { shallowRefArray: tags, doChangeArray: _changeTags } = useArray();
    const { shallowRefArray: filters, doAddToArray: pushFilters } = useArray();
    const { shallowRefArray: referenceTypeForReferencingColumns } = useArray();
    const { reactiveObject: referencesDynamic, doChangeObject: changeReferencesDynamic } =
      useObject();
    const tableHeight = computed(() => {
      if (rows.value.length < params.limit) {
        return 66 * (rows.value.length + 1) + "px";
      } else if (params.limit !== null) {
        return 66 * (params.limit + 1) + "px";
      }
      return "72.5vh";
    });

    const dataColumnsToBeShown = computed(() => {
      let navigateurName = navigator.userAgent;
      if (navigateurName.indexOf("Firefox") >= 1) {
        return services.tagService.toBeShownDataColumns(tags.value, realVariables.value);
      } else if (navigateurName.indexOf("Safari") >= 1 && navigateurName.indexOf("Chrome") >= 1) {
        return services.tagService.toBeShown(tags.value, realVariables.value);
      } else {
        console.log(
          "Votre navigateur n'est pas testé l'ordonnance des filtres est par défault",
          navigateurName
        );
        return services.tagService.toBeShown(tags.value, realVariables.value);
      }
    });

    const hasTags = useBoolean(false).refBoolean;
    const changeTags = function (tagsToChange) {
      _changeTags({ ...tagsToChange });
      hasTags.value =
        tags.value &&
        Object.keys(tags.value || {}).length !== 0 &&
        !(
          Object.keys(tags.value || {}).length === 1 &&
          Object.keys(tags.value).includes(Tag.NO_TAG_NAME)
        );
    };
    const totalRows = ref(0);

    function changeAllValueTags(allTags) {
      for (let key in tags.value) {
        tags.value[key].selected = allTags;
      }
      changeTags(tags.value);
    }

    async function changeTagSelected(tagsToChange) {
      changeTags(tagsToChange);
    }

    provide("reference:dataLoader", dataLoader(services));

    function getRefColumnId(row, column) {
      if (column.referenceType === "ReferenceChecker") {
        let refLinkedTo = column.refLinkedTo;
        let refLinkedToColumn = column.refLinkedToColumn;
        let refsLinkedToElementElementElement =
          row?.refsLinkedTo?.[refLinkedTo]?.[refLinkedToColumn]?.[0];
        /*if (!refsLinkedToElementElementElement) {
          console.log("refsLinkedToElementElementElement", column);
        }*/
        return refsLinkedToElementElementElement;
      }
    }

    onMounted(async () => {
      await init();
      await setInitialVariables([]);
      let dataIsType = application.dataTypes[props.dataId] ? "dataTypes" : "references";
      changeSubMenuPaths([
        new SubMenuPath(
          application.dataTypes[props.dataId]
            ? i18n.t("dataTypesManagement.data-types").toLowerCase()
            : i18n.t("referencesManagement.references").toLowerCase(),
          () => app.$router.push(`/applications/${props.applicationName}/${dataIsType}`),
          () => app.$router.push(`/applications`)
        ),
        new SubMenuPath(
          application.localRefName ? application.localRefName : props.dataId,
          () =>
            app.$router.push(
              `/applications/${props.applicationName}/${dataIsType}/${props.dataId}`
            ),
          () => app.$router.push(`/applications/${props.applicationName}/${dataIsType}`)
        ),
      ]);
      changeColumns(services.tagService.toBeShown(tags.value, columns.value));
      changeTags(buildTagsColumns(application, columns.value, tags.value));
    });

    async function init() {
      changeIsLoading(true);
      try {
        changeApplication(
          await services.applicationService.getApplication(props.applicationName, [
            "CONFIGURATION",
            "REFERENCETYPE",
          ])
        );
        changeApplication({
          ...services.internationalisationService.mergeInternationalization(application),
          localRefName: services.internationalisationService.localeReferenceNames(
            props.dataId,
            application
          ),
        });
        const data = await services.dataService.getData(
          props.applicationName,
          props.dataId,
          {
            offset: params.offset,
            limit: params.limit,
          },
          onlyMetadata.value
        );
        if (data) {
          let dataValues = data.rows;
          totalRows.value = data.totalRows;
          changeRows(dataValues);
          if (Object.keys(data?.referenceTypeForReferencingColumns).length > 0) {
            for (let key in data.referenceTypeForReferencingColumns) {
              referenceTypeForReferencingColumns.value[key] =
                data.referenceTypeForReferencingColumns[key];
            }
          }
        }
      } catch (error) {
        services.alertService.toastServerError();
      }
      changeIsLoading(false);
    }

    const realVariables = computed(() => {
      return columns.value.reduce((acc, column) => {
        if (column.type === "PatternComponent") {
          let row = rows.value[0];
          row.values[column.id]
            .map((value) => column.componentsForValue(value, row))
            .forEach((col) => {
              col.forEach((col2) => acc.push(col2));
            });
        } else if (column.type !== "PatternAdjacentComponent") {
          acc.push(column);
        }
        return acc;
      }, []);
    });

    async function setInitialVariables(variables) {
      changeData(application?.configuration?.dataDescription?.[props.dataId]);
      if (!data) {
        return;
      }
      let dynamicComponents = {};
      let components = {};
      let componentDescriptions = data?.value?.componentDescriptions;
      for (let component in componentDescriptions) {
        if (!variables || !variables.includes(component)) {
          continue;
        }
        let currentComponent = componentDescriptions[component];
        if (currentComponent.type === "DynamicComponent") {
          dynamicComponents[component] = Component.build(
            currentComponent,
            props.dataId,
            application,
            services.internationalisationService.localeReferenceColumnsNames,
            (tagName) =>
              services.internationalisationService.getLocaleforPath(
                application,
                "tags." + tagName,
                tagName
              )
          );
        } else if (
          currentComponent.type !== "AuthorizationScopeComponent" &&
          currentComponent.type !== "PatternComponentComponent"
        ) {
          components[component] = Component.build(
            currentComponent,
            props.dataId,
            application,
            services.internationalisationService.localeReferenceColumnsNames,
            (tagName) =>
              services.internationalisationService.getLocaleforPath(
                application,
                "tags." + tagName,
                tagName
              )
          );
        }
      }
      let localColumns = [
        ...Object.values(components || {})
          .sort(Component.compare)
          .reduce((accumulator, component) => {
            accumulator.push(component);
            return accumulator;
          }, []),
        ...Object.values(dynamicComponents || {})
          .sort(Component.compare)
          .reduce((accumulator, component) => {
            accumulator.push(component);
            return accumulator;
          }, []),
      ];
      changeColumns(localColumns);
      if (rows.value) {
        changeTableValues(Object.values(rows.value).map((refValue) => refValue.values));
      }
      for (let i = 0; i < columns.value.length; i++) {
        //TODO
        if (dynamicComponents[columns?.value[i]?.id] && application.data[props.dataId]) {
          changeReferencesDynamic(
            await services.dataService.getData(
              props.applicationName,
              application.data[props.dataId].componentDescriptions[columns.value[i].id].reference
            )
          );
          dynamicComponents[columns.value[i].id].referenceType =
            application.data[props.dataId].componentDescriptions[columns.value[i].id].reference;
        }
      }
    }

    async function clear() {
      changeIsLoading(false);
      window.location.reload();
    }

    async function recalculate(event, shouldDownload) {
      onlyMetadata.value = false;
      changeIsLoading(true);
      changeCurrentPage(1);
      let limit = params.limit;
      params.limit = null;
      params.componentFilters = event.filters.filter((v) => v !== undefined);
      let tableValue = await services.dataService.getData(
        props.applicationName,
        props.dataId,
        params.valueOf()
      );
      changeRows(tableValue.rows);
      let variables = tableValue.variables;
      totalRows.value = tableValue.totalRows;
      pushFilters(event.filters.filter((v) => v !== undefined));
      await setInitialVariables(variables);
      changeIsLoading(false);
      this.$forceUpdate();
      params.limit = limit;
      if (shouldDownload) {
        downloadResultSearch();
      }
    }

    function addRefLinkedTo(idColumn, linkedTo) {
      if (linkedTo === null) {
        for (let key in referenceTypeForReferencingColumns.value) {
          if (key === idColumn && referenceTypeForReferencingColumns.value[key]) {
            return referenceTypeForReferencingColumns.value[key];
          }
        }
      } else return linkedTo;
    }

    function askDeletionConfirmation(rowId) {
      services.alertService.dialog(
        i18n.t("alert.warning"),
        i18n.t("alert.reference-deletion-msg", { label: rowId }),
        i18n.t("alert.delete"),
        "is-danger",
        i18n.t("alert.cancel"),
        () => deleteRowReference(rowId)
      );
    }

    async function deleteRowReference(rowId) {
      try {
        await services.dataService.deleteReferenceValuesByKey(
          props.applicationName,
          data.value.label,
          rowId
        );
        services.alertService.toastSuccess(i18n.t("alert.reference-updated"));
      } catch (errors) {
        await checkMessageErrors(errors, errorsList.value, errorsMessages);
      }
    }

    async function changePage(value) {
      changeIsLoading(true);
      let datas;
      if (filters.value.length === 0) {
        params.offset = (value - 1) * params.limit;
        datas = await services.dataService.getData(props.applicationName, props.dataId, params);
        totalRows.value = data.totalRows;
        if (datas) {
          changeRows(datas.rows);
        }
      }
      if (rows.value) {
        changeTableValues(Object.values(rows.value).map((refValue) => refValue.values));
      }
      changeIsLoading(false);
    }

    function info(refType) {
      for (let i = 0; i < this.referencesDynamic.length; i++) {
        if (referencesDynamic[i][0] === refType) return true;
      }
      return false;
    }

    function multiplicity(column, arrayValues) {
      for (let i = 0; i < tableValues.value.length; i++) {
        let showModal = Object.entries(tableValues.value[i]).filter((a) => a[1]);
        for (let j = 0; j < showModal.length; j++) {
          if (
            showModal[j][0] === column &&
            showModal[j][1] === arrayValues &&
            Array.isArray(showModal[j][1])
          ) {
            return true;
          }
        }
      }
      return false;
    }

    async function downloadResultSearch() {
      onlyMetadata.value = false;
      let limit = params.limit;
      params.limit = null;
      let zipFileUrl = await services.dataService.getDataZip(
        props.applicationName,
        props.dataId,
        params
      );
      const hiddenElement = document.createElement("a");

      hiddenElement.href = zipFileUrl;
      hiddenElement.download = "export.zip";
      hiddenElement.click();
      params.limit = limit;
    }

    const MANY = Component.MULTIPLICITY_MANY;
    const ONE = Component.MULTIPLICITY_ONE;

    async function showModal() {
      console.log()("coucu");
    }

    return {
      showModal,
      realVariables,
      onlyMetadata,
      addRefLinkedTo,
      recalculate,
      askDeletionConfirmation,
      changePage,
      info,
      multiplicity,
      downloadResultSearch,
      changeTagSelected,
      clear,
      changeAllValueTags,
      referenceTypeForReferencingColumns,
      tags,
      subMenuPaths,
      params,
      currentPage,
      errorsMessages,
      tableValues,
      referencesDynamic,
      isLoading,
      columns,
      rows,
      application,
      data,
      dataColumnsToBeShown,
      filters,
      MANY,
      ONE,
      hasTags,
      getRefColumnId,
      lineCountSynthesis,
      getColumnNameView,
      patternCheckerDateRef,
      totalRows,
      tableHeight,
    };
  },
};
</script>
<style lang="scss" scoped>
.b-table .table th.is-sortable {
  width: max-content;
  position: sticky;
}
</style>
