<script>
import { watch } from "vue";
import useNumber from "@/composable/components/number";

export default {
  name: "InputNumberInterval",
  components: {},
  props: {
    inputType: String,
    format: String,
    from: Number,
    to: Number,
    min: Number,
    max: Number,
    modelValue: Object,
  },
  setup(props, ctx) {
    const { refNumber: fromValueNumber } = useNumber();
    const { refNumber: toValueNumber } = useNumber();
    watch(
      () => props.from,
      (value) => {
        fromValueNumber.value = value;
      }
    );
    watch(
      () => props.to,
      (value) => {
        toValueNumber.value = value;
      }
    );
    function updateValueNumber() {
      if (fromValueNumber.value < toValueNumber.value) {
        ctx.emit("update:numberValueInterval", {
          interval: {
            from: fromValueNumber.value,
            to: toValueNumber.value,
          },
        });
      }
    }
    return {
      fromValueNumber,
      toValueNumber,
      updateValueNumber,
    };
  },
};
</script>

<template>
  <div class="rows">
    <div class="row">
      <label class="label">{{ $t("dataTypeAuthorizations.from") }}</label>
    </div>
    <b-numberinput
      v-model="fromValueNumber"
      :max="max"
      :min="min"
      :placeholder="format"
      controls-position="compact"
      controls-rounded
      :step="format === 'float' ? 0.1 : 1"
      :min-step="format === 'float' ? 0.00001 : 1"
      type="is-light"
      @blur="updateValueNumber"
    >
    </b-numberinput>
    <div class="row">
      <label class="label">{{ $t("dataTypeAuthorizations.to") }}</label>
    </div>
    <b-numberinput
      v-model="toValueNumber"
      :max="max"
      :min="min"
      :placeholder="format"
      controls-position="compact"
      controls-rounded
      :step="format === 'float' ? 0.1 : 1"
      :min-step="format === 'float' ? 0.00001 : 1"
      type="is-light"
      @blur="updateValueNumber"
    >
    </b-numberinput>
  </div>
</template>

<style scoped lang="scss"></style>
