<template>
  <PageView class="with-submenu">
    <SubMenu
      :aria-label="$t('menu.aria-sub-menu')"
      :paths="subMenuPaths"
      :root="application.localName || application.title"
      role="navigation"
    />
    <h1 class="title main-title">
      {{
        additionalFileName
          ? $t("titles.additionalFileWithType", {
              localName:
                services.internationalisationService.getLocaleforPath(
                  application,
                  "additionalFiles." + additionalFileName + ".internationalizationName"
                ) || additionalFileName,
            })
          : $t("titles.additionalFile")
      }}
    </h1>
    <div>
      <div class="columns">
        <b-select
          :placeholder="$t('additionalFiles.menu')"
          :value="additionalFileName"
          style="margin: 10px"
          @input="changeFileType"
        >
          <option v-for="(option, id) in additionalFileNames" :key="id" :value="option">
            {{
              services.internationalisationService.getLocaleforPath(
                application,
                "additionalFiles." + option + ".internationalizationName"
              ) || option
            }}
          </option>
        </b-select>
        <div v-if="additionalFileName" class="column is-offset-9 is-2">
          <b-button icon-left="plus" type="is-primary is-right" @click="addAdditionalFile">
            {{
              $t("additionalFilesManagement.addAdditionalFile", {
                localName:
                  services.internationalisationService.getLocaleforPath(
                    application,
                    "additionalFiles." + additionalFileName + ".internationalizationName"
                  ) || additionalFileName,
              })
            }}
          </b-button>
        </div>
      </div>
      <div class="columns">
        <caption v-if="!additionalFileName">
          <p>{{ $t("additionalFilesManagement.selectAdditionalFilesType") }}</p>
        </caption>
      </div>
      <div v-for="(additionalFile, i) in additionalFiles" :key="i">
        <div v-if="additionalFile.fileType === additionalFileName">
          <CollapsibleTree
            v-if="true || isVisibleRequest(additionalFile.setted)"
            :id="additionalFile.id"
            :application-title="$t('titles.references-page')"
            :buttons="getButtons(additionalFile.user)"
            :level="0"
            :line-count="12"
            :on-click-label-cb="() => ''"
            :option="additionalFile"
            :repository-redirect="(label) => manageRequest(label)"
            class="liste"
          >
            <template v-slot:secondaryMenu> &nbsp;</template>
            <template v-slot:upload> &nbsp;</template>
            <template v-slot:label="{ option }">
              <div class="column">
                <b-icon :icon="'check'" :type="'is-primary'" class="column">
                  {{ $t("dataTypeAuthorizations.scopes.close") }}
                </b-icon>
              </div>
              <div v-if="option.forApplication" class="column">
                <b-tooltip
                  :label="$t('additionalFiles.file.forApplication')"
                  class="is-primary"
                  multilined
                  position="is-top"
                  size="is-large"
                >
                  <b-tag icon="link" icon-type="is-primary">{{ option.fileName }}</b-tag>
                </b-tooltip>
              </div>
              <div
                v-else-if="option.associates && Object.keys(option.associates).length"
                class="column"
              >
                <b-tooltip
                  :label="$t('additionalFiles.file.linked')"
                  class="is-warning"
                  multilined
                  position="is-top"
                  size="is-large"
                >
                  <b-tag icon="link" icon-type="is-warning">{{ option.fileName }}</b-tag>
                </b-tooltip>
              </div>
              <div v-else class="column">
                <b-tooltip
                  :label="$t('additionalFiles.file.notLinked')"
                  class="is-danger"
                  multilined
                  position="is-top"
                  size="is-large"
                >
                  <b-tag icon="link" icon-type="is-danger">{{ option.fileName }}</b-tag>
                </b-tooltip>
              </div>
              <div class="column">{{ humanFileSize(option.size) }}</div>
              <div class="column">
                <b-tooltip :label="option.comment" multilined position="is-top" size="is-large">
                  <a>{{ $t("dataTypesRepository.comment") }}</a>
                </b-tooltip>
              </div>
            </template>
            <template v-slot:synthesisDetail="{ option }">
              <div class="column is-one-fifth">
                {{ option.updateDate && getDate(option.updateDate) }}
              </div>
              <div class="column is-one-fifth">
                {{ users.find((user) => user.id === option.user).label }}
              </div>
            </template>
            <template v-slot:default="{ option, displayChildren }">
              <div v-if="displayChildren" class="rows">
                <div style="padding-left: 1rem">
                  <p style="text-decoration: underline; font-size: large">
                    {{ $t("additionalFilesManagement.recap") }}{{ $t("ponctuation.colon") }}
                  </p>
                </div>
                <div
                  v-for="(value, name) in option.additionalBinaryFileForm"
                  :key="name"
                  class="row"
                >
                  <div class="columns">
                    <div v-if="value !== ''" class="column is-offset-1 is-primary">
                      <p>
                        {{
                          internationalisationService.getLocaleforPath(
                            application,
                            "rightsRequest.format." + name,
                            name
                          )
                        }}{{ $t("ponctuation.colon") }} {{ value }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </CollapsibleTree>
        </div>
        <div v-else class="columns">
          <caption class="column is-one-quarter">
            <p style="color: #ffaa00">
              {{ $t("additionalFilesManagement.emptyAdditionalFilesList") }}
            </p>
          </caption>
        </div>
      </div>
    </div>
  </PageView>
</template>

<script>
import moment from "moment";
import SubMenu, { SubMenuPath } from "@/components/common/SubMenu.vue";
import PageView from "../common/PageView.vue";
import { ApplicationResult } from "@/model/ApplicationResult";
import { Button } from "@/model/Button";
import CollapsibleTree from "@/components/common/CollapsibleTree.vue";
import { AdditionalFilesInfos } from "@/model/additionalFiles/AdditionalFilesInfos";
import services from "@/composable/services";
import { onMounted, onUpdated } from "vue";
import app, { i18n } from "@/main";
import useArray from "@/composable/components/array";
import useObject from "@/composable/components/object";
import useBoolean from "@/composable/components/boolean";
import { User } from "@/model/User";

export default {
  name: "AdditionalFilesManagementView",
  computed: {
    services() {
      return services;
    },
  },
  components: { PageView, SubMenu, CollapsibleTree },
  props: {
    applicationName: {
      type: String,
    },
    additionalFileName: {
      type: String,
    },
  },
  setup(props) {
    let vid = null;
    let toList;
    const { reactiveObject: application, doChangeObject: changeApplication } = useObject(
      new ApplicationResult()
    );
    // pagination
    let offset = 0;
    let currentPage = 1;
    let perPage = 10;
    let isSelectedName = "";
    let isSelectedAuthorization = "";
    let isCardModalActive = false;
    let isCardModalActive2 = false;
    let localizations = {};
    let additionalFiles = {};
    let users = [];
    let filterState = 0;
    let filterStates = [
      {
        type: "open",
        label: i18n.t("dataTypeAuthorizations.filterScope.open"),
      },
      {
        type: "close",
        label: i18n.t("dataTypeAuthorizations.filterScope.close"),
      },
      {
        type: "all",
        label: i18n.t("dataTypeAuthorizations.filterScope.all"),
      },
    ];
    const { shallowRefArray: additionalFileNames, doChangeArray: changeAdditionalFileNames } =
      useArray();
    const { refBoolean: canManageRights, doChangeBoolean: changeCanManageRights } = useBoolean();
    let getReadableFileSizeStringfunction;
    let currentUserId = User.STORED_AUTHENTICATED_USER()?.id;
    const { shallowRefArray: subMenuPaths, doChangeArray: changeSubMenuPaths } = useArray();

    onUpdated(() => {
      if (document.readyState === "complete") {
        let list = document.getElementsByClassName("CollapsibleTree-header-infos column is-narrow");
        for (let i = 0; i < list.length; i++) {
          list[i].setAttribute("class", "CollapsibleTree-header-infos column is-two-thirds");
        }
      }
    });

    onMounted(async () => {
      await init();
      changeSubMenuPaths([
        new SubMenuPath(
          i18n.t("additionalFilesManagement.additionalFilesManagement").toLowerCase(),
          () => {},
          () => app.$router.push("/applications/" + props.applicationName)
        ),
      ]);
    });

    function getDate(value) {
      return moment(value).format("DD/MM/YYYY");
    }

    async function init() {
      try {
        changeApplication(
          await services.applicationService.getApplication(props.applicationName, [
            "CONFIGURATION",
            "DATATYPE",
            "RIGHTSREQUEST",
          ])
        );
        changeApplication({
          ...services.internationalisationService.mergeInternationalization(application),
          localRefName: services.internationalisationService.localeReferenceNames(
            props.applicationName,
            application
          ),
        });
        changeAdditionalFileNames(Object.keys(application.additionalFiles || []));
        changeCanManageRights(
          application.isAdministrator ||
            Object.values(application.authorizations || []).some((rights) => rights.ADMIN)
        );
      } catch (error) {
        services.alertService.toastServerError(error);
      }
      if (props.additionalFileName) {
        await loadAdditionalFiles();
      }
    }

    function isVisibleRequest(setted) {
      if (filterState === 0) {
        return !setted;
      } else if (filterState === 1) {
        return setted;
      } else {
        return true;
      }
    }

    function consultAdditionalFile(label) {
      const ref = findAdditionalFileByLabel(label);
      if (ref) {
        app.$router.push(
          `/applications/${props.applicationName}/additionalFiles/${props.additionalFileName}/${ref.id}`
        );
      }
    }

    function addAdditionalFile() {
      if (props.additionalFileName) {
        app.$router.push(
          `/applications/${props.applicationName}/additionalFiles/${props.additionalFileName}/new`
        );
      }
    }

    function findAdditionalFileByLabel(label) {
      const ref = additionalFiles.find((dt) => dt.label === label);
      return ref;
    }

    async function downloadAdditionalFile(event) {
      let param = new AdditionalFilesInfos([event]);
      let path = await services.additionalFileService.getAdditionalFileZip(
        props.applicationName,
        param
      );
      let link = document.createElement("a");
      link.href = path;
      link.download = "additionalFile.zip";
      link.click();
      window.URL.revokeObjectURL(link.href);
      return false;
    }

    async function deleteAdditionalFile(event) {
      const fileTodelete = additionalFiles.find((file) => file.id === event) || {};
      app.$buefy.dialog.confirm({
        type: "is-danger",
        title: i18n.t("additionalFiles.alert.alertDeleteTitle"),
        message: i18n.t("additionalFiles.alert.alertDelete", fileTodelete),
        confirmText: i18n.t("additionalFiles.alert.alertDeleteConfirm"),
        cancelText: i18n.t("additionalFiles.alert.alertDeleteCancel"),
        onConfirm: () => {
          app.$buefy.toast.open(i18n.t("additionalFiles.alert.confirmDelete", fileTodelete));
          doDeleteAdditionalFile(fileTodelete);
        },
        hasIcon: true,
      });
    }

    async function doDeleteAdditionalFile(fileTodelete) {
      let param = new AdditionalFilesInfos([fileTodelete.id]);
      let uuid = await services.additionalFileService.deleteAdditionalFile(
        props.applicationName,
        param
      );
      uuid = uuid ? uuid.split(",") : [];
      additionalFiles = additionalFiles.filter((file) => !uuid.includes(file.id));
    }

    function manageRequest(id) {
      app.$router.push(`/applications/${props.applicationName}/authorizationsRequest/${id}`);
    }

    function changeFileType(event) {
      app.$router.push(`/applications/${props.applicationName}/additionalFiles/${event}`);
      loadAdditionalFiles();
    }

    async function loadAdditionalFiles() {
      let additionalFiles = await services.additionalFileService.getAdditionalFiles(
        props.applicationName,
        props.additionalFileName,
        {}
      );
      let users1 = additionalFiles.users || [];
      users1.shift();
      users = users1;
      for (const request of additionalFiles.additionalBinaryFiles) {
        request.children = [{}];
        request.label = request.id;
      }
      additionalFiles = additionalFiles.additionalBinaryFiles;
      //changeValueAttribute();
    }

    function humanFileSize(bytes, si = false, dp = 1) {
      const thresh = si ? 1000 : 1024;

      if (Math.abs(bytes) < thresh) {
        return bytes + " B";
      }

      const units = si
        ? ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"]
        : ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      let u = -1;
      const r = 10 ** dp;

      do {
        bytes /= thresh;
        ++u;
      } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

      return bytes.toFixed(dp) + " " + units[u];
    }

    function getButtons(fileId) {
      let buttons = [
        new Button(
          i18n.t("additionalFiles.buttons.consult"),
          "eye",
          (label) => consultAdditionalFile(label),
          "is-dark"
        ),
        new Button(i18n.t("additionalFiles.buttons.download"), "download", (label) =>
          downloadAdditionalFile(label)
        ),
      ];
      if (application.isAdministrator || fileId === currentUserId) {
        buttons.push(
          new Button(
            i18n.t("additionalFiles.buttons.delete"),
            "times",
            (label) => deleteAdditionalFile(label),
            "is-danger"
          )
        );
      }
      return buttons;
    }

    return {
      getButtons,
      humanFileSize,
      changeFileType,
      manageRequest,
      deleteAdditionalFile,
      addAdditionalFile,
      isVisibleRequest,
      getDate,
      application,
      vid,
      toList,
      offset,
      currentPage,
      perPage,
      isSelectedName,
      isSelectedAuthorization,
      isCardModalActive,
      isCardModalActive2,
      localizations,
      additionalFiles,
      canManageRights,
      users,
      filterState,
      filterStates,
      additionalFileNames,
      getReadableFileSizeStringfunction,
      currentUserId,
      subMenuPaths,
    };
  },
};
</script>
<style lang="scss">
td {
  padding: 6px;

  .columns {
    margin: 0;

    .column.is-half {
      padding: 6px;
    }
  }
}

.listAuthorization {
  border: solid #dbdbdb;
  border-width: 0 0 1px;
  margin: 0 10px 0 10px;
  padding: 15px;
}

.listAuthorization:nth-child(odd) {
  background-color: #f5f5f5;
}
</style>
