<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import loadApplications from "@/composable/applications/applications";
import { provide } from "vue";

export default {
  name: "app",
  setup() {
    provide("application:progress", loadApplications().progress);
    provide("application:applications", loadApplications().applications);
    provide("application:loadApplications", loadApplications().loadApplications);
  },
};
</script>

<style lang="scss">
#app {
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text-default-color;
  display: flex;
  flex-direction: column;
  height: 100%;
}
div.media-content span {
  white-space: pre-line;
  word-break: break-all;
}
</style>
