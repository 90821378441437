import { Authorization } from "@/model/authorization/Authorization";

export class AuthorizationsForRestriction {
  dependantNodes = null;
  dependsOf = null;
  constructor(dependantNodes, dependsOf, authorizations) {
    this.dependantNodes = dependantNodes;
    this.dependsOf = dependsOf;
    this.authorizations = this.buildAuthorizationsByRoleAndPath(authorizations);
  }
  buildAuthorizationsByRoleAndPath(authorizations) {
    let authorizationsByRoleAndPath = {};
    for (const auth in authorizations) {
      for (const authorization in authorizations[auth]) {
        const authorization2 = new Authorization(authorizations[auth][authorization]);
        authorizationsByRoleAndPath[authorization2.getKey()] =
          authorizationsByRoleAndPath[authorization2.getKey()] || {};
        authorizationsByRoleAndPath[authorization2.getKey()][authorization2.getKey()] =
          authorizationsByRoleAndPath[authorization2.getKey()][authorization2.getKey()] || {};
        authorizationsByRoleAndPath[authorization2.getKey()][authorization2.getKey()][auth] =
          authorization2;
      }
    }
    return authorizationsByRoleAndPath;
  }
  findAuthorizationByAuthAndRequiredAuthorization(auth, requiredAutorization) {
    return (this.authorizations[auth] || []).find((ra) =>
      this.requiredAuthorizationCompare(ra, requiredAutorization)
    );
  }
  requiredAuthorizationCompare(requiredAuth1, requiredAuth2) {
    for (const requiredAuth1Key in requiredAuth1) {
      if (requiredAuth1[requiredAuth1Key] != requiredAuth2[requiredAuth1Key]) {
        return false;
      }
    }
    return true;
  }
}
