export function lineCountSynthesis(referenceSynthesis, ref) {
  if (referenceSynthesis) {
    for (let i = 0; i <= referenceSynthesis.length - 1; i++) {
      if (referenceSynthesis[i].referenceType === ref.label) {
        return referenceSynthesis[i].lineCount;
      } else if (referenceSynthesis[i].referenceType === ref) {
        return referenceSynthesis[i].lineCount;
      }
    }
  } else {
    return 0;
  }
}
