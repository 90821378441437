import { i18n } from "@/main";
import services from "@/composable/services";
import { HttpStatusCodes } from "@/utils/HttpUtils";

export async function checkMessageErrors(errors, errorsList, errorsMessages) {
  if (errors.httpResponseCode === HttpStatusCodes.BAD_REQUEST) {
    errors.content.then((value) => {
      for (let i = 0; i < value.length; i++) {
        errorsList.value[i] = value[i];
      }
      if (errorsList.value.length !== 0) {
        errorsMessages = services.errorsService.getCsvErrorsMessages(errorsList);
      } else {
        errorsMessages = services.errorsService.getErrorsMessages(errors);
      }
    });
  } else {
    services.alertService.toastError(i18n.t("alert.delete-reference-error"), errors);
  }
  return errorsMessages;
}
