<template>
  <div
    v-if="!option.children.length"
    :id="option.id"
    @click="select(option, option.i18n[locale] || option.naturalKey)"
    style="padding: 5px 16px"
  >
    <a>{{ option.i18n[locale] || option.naturalKey }}</a>
  </div>
  <b-collapse v-else :open="isOpen" animation="slide" class="card">
    <template #trigger="props">
      <div :aria-expanded="props.open" class="card-header" role="button">
        <p class="card-header-title">
          {{ option.i18n[locale] || option.naturalKey }}
        </p>
        <a class="card-header-icon">
          <b-icon :icon="props.open ? 'chevron-up' : 'chevron-down'"></b-icon>
        </a>
      </div>
    </template>
    <div class="card-content">
      <div class="content">
        <CollapseMenuOneSelect
          v-for="child in option.children"
          :key="child.id"
          :option="child"
          @select-menu-item="select"
        >
        </CollapseMenuOneSelect>
      </div>
    </div>
  </b-collapse>
</template>

<script>
import { ref, computed } from "vue";
import services from "@/composable/services";

export default {
  name: "CollapseMenuOneSelect",
  props: {
    option: {
      type: Object,
      required: true,
    },
  },
  emits: ["select-menu-item"],
  setup(props, { emit }) {
    const isOpen = ref(false);
    const locale = services.internationalisationService.getLocale();
    const displayText = computed(() => props.option.i18n[locale] || props.option.naturalKey);

    function select(option, localName) {
      option.localName = option.localName || localName;
      emit("select-menu-item", option || { ...this.option, localName });
    }
    return {
      select,
      isOpen,
      locale,
      displayText,
    };
  },
};
</script>

<style lang="scss" scoped>
a {
  color: black;
}

.card {
  box-shadow: none;

  .card-header {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: none;

    .card-header-title {
      color: $dark-slightly-transparent-8;
      margin: 0;
    }
  }

  .card-content {
    padding: 5px 0px 5px 20px;
    border-radius: 0px;

    .content {
      border-left: 0.5px solid $primary;
    }

    .collapse {
      padding: 0;

      .card-header {
        .card-header-title {
          color: $dark-slightly-transparent-6;
          margin: 0;
        }
      }
    }
  }
}
</style>
