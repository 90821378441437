import { ref } from "vue";
function useNumber(number = 0) {
  const refNumber = ref(number);
  const doChangeNumber = function (newNumber) {
    refNumber.value = newNumber;
  };
  return {
    refNumber,
    doChangeNumber,
  };
}
export default useNumber;
