import services from "@/composable/services";
import { Tag } from "@/model/application/Tag";

export function buildTagsForVariables(application, variables, components) {
  let tags = {};
  for (let i = 0; i < variables.value.length; i++) {
    let currentTags = variables.value[i].tags;
    if (!currentTags) {
      continue;
    }
    services.tagService.currentTags(tags, currentTags);
    if (variables.value[i].tags !== services.hidden_tag) {
      variables.value[i].localtags = variables.value[i].tags.map(
        (tag) => tags[tag]?.localName || tag
      );
      for (let component in components.value) {
        if (components.value[component].variable === variables.value[i].label) {
          let currentTags = components.value[component].tags;
          if (!currentTags) {
            continue;
          }
          services.tagService.currentTags(tags, currentTags);
        }
        components.value[component].localtags = components.value[component].tags.map(
          (tag) => tags[tag]?.localName || tag
        );
      }
    }
  }
  if (tags) {
    services.tagService.sizeCollapseTags(tags);
  }
  return tags;
}

export function buildTags(application, refsOrDatas) {
  let tags = (refsOrDatas || [])
    .map((r) => r.tags)
    .reduce((acc, tagList) => {
      tagList.forEach((tag) => {
        if (
          (!acc.includes(tag) && tag.type === Tag.DOMAIN_TAG) ||
          tag.tagName === Tag.NO_TAG_NAME
        ) {
          acc.push(tag);
        }
      });
      return acc;
    }, []);
  if (tags) {
    services.tagService.sizeCollapseTags(tags);
  }
  return tags.reduce((acc, tag) => {
    acc[tag.tagName] = tag;
    return acc;
  }, {});
}

export function buildTagsColumns(application, columns, tagsColumn) {
  let tags = {};
  for (let column in columns) {
    if (columns[column]) {
      let currentTags = columns[column].tags;
      currentTags
        .filter((tag) => tag.type === Tag.DOMAIN_TAG || tag.tagName === Tag.NO_TAG_NAME)
        .forEach((tag) => {
          if (tag.tagName === Tag.NO_TAG_NAME) {
            tag = Tag.NO_TAG_INSTANCE();
          }
          if (!tags[tag.tagName]) {
            tags[tag.tagName] = tag;
          }
        });
    }
  }
  if (tags) {
    services.tagService.sizeCollapseTags(tagsColumn);
  }
  return tags;
}
