<template>
  <PageView class="with-submenu">
    <SubMenu
      :aria-label="$t('menu.aria-sub-menu')"
      :paths="subMenuPaths"
      :root="$t('titles.applications-page')"
      role="navigation"
    />
    <h1 class="title main-title">
      {{ $t("titles.authorization-application-creator-management") }}
    </h1>
    <b-notification aria-close-label="Close notification">
      {{ $t("dataTypesManagement.delegate-rights") }}
    </b-notification>
    <div class="rows">
      <LoadingAnimate v-if="isLoading" :size="'is-large'"></LoadingAnimate>
      <b-table
        :current-page="currentPage"
        :data="listUsersAuth"
        class="row"
        paginated
        per-page="15"
      >
        <template #pagination>
          <b-pagination
            v-model="currentPage"
            :aria-current-label="$t('menu.aria-curent-page')"
            :aria-label="$t('menu.aria-pagination')"
            :aria-next-label="$t('menu.aria-next-page')"
            :aria-previous-label="$t('menu.aria-previous-page')"
            :current-page.sync="currentPage"
            :rounded="true"
            :total="listUsersAuth.length"
            order="is-centered"
            per-page="15"
            range-after="3"
            range-before="3"
            role="navigation"
          />
        </template>
        <b-table-column
          :custom-search="search"
          :label="$t('dataTypeAuthorizations.users')"
          field="admin"
          searchable
          sortable
        >
          <template #searchable="props">
            <b-input
              v-model="props.filters[props.column.field]"
              :placeholder="$t('dataTypeAuthorizations.search')"
              icon="search"
              size="is-normal"
            />
          </template>
          <template v-slot="props">
            {{ props.row.login }}
          </template>
        </b-table-column>
        <b-table-column
          v-if="currentUser.openAdomAdmin"
          v-slot="props"
          :label="$t('dataTypeAuthorizations.status-auth-application-creator')"
          field="administrator"
          sortable
        >
          <template>
            <b-checkbox v-model="props.row.openAdomAdmin" @input="selectAdmin($event, props.row)" />
          </template>
        </b-table-column>
        <b-table-column
          v-if="currentUser.authorizedForApplicationCreation || currentUser.openAdomAdmin"
          v-slot="props"
          :label="$t('dataTypeAuthorizations.pattern-application-name')"
          field="applications"
          sortable
        >
          <template>
            <b-taginput
              v-model="props.row.authorizations"
              :before-adding="beforeAdding"
              :close-type="props.row.openAdomAdmin ? 'is-light' : 'is-danger'"
              :disabled="props.row.openAdomAdmin"
              :icon="props.row.openAdomAdmin ? 'user-shield' : 'file'"
              :placeholder="
                props.row.openAdomAdmin
                  ? $t('dataTypeAuthorizations.authorization-user-creator-management')
                  : $t('dataTypeAuthorizations.add-application-name')
              "
              :type="props.row.openAdomAdmin ? 'is-light' : 'is-dark'"
              aria-close-label="Supprimer l'application"
              class="inputAuth"
              closable
              ellipsis
              @add="addApplication($event, props.row)"
              @close="removeApplication($event, props.row)"
              @remove="removeApplication($event, props.row)"
            >
            </b-taginput>
          </template>
        </b-table-column>
      </b-table>
      <div class="row">
        <div class="column is-offset-10 is-2">
          <b-button
            icon-left="floppy-disk"
            icon-pack="far"
            type="is-primary is-right"
            @click="registerChanges"
          >
            {{ $t("dataTypeAuthorizations.save") }}
          </b-button>
        </div>
      </div>
    </div>
  </PageView>
</template>

<script>
import SubMenu from "@/components/common/SubMenu.vue";
import PageView from "../common/PageView.vue";
import { SubMenuPath } from "@/components/common/SubMenu";
import LoadingAnimate from "@/components/common/LoadingAnimate.vue";
import { onMounted, ref } from "vue";
import useArray from "@/composable/components/array";
import useObject from "@/composable/components/object";
import app, { i18n } from "@/main";
import services from "@/composable/services";

export default {
  name: "AuthorizationsManagementForApplicationCreatorView",
  components: {
    LoadingAnimate,
    PageView,
    SubMenu,
  },
  setup() {
    const { shallowRefArray: subMenuPaths, doChangeArray: changeSubMenuPaths } = useArray();
    const { shallowRefArray: listUsersAuth, doChangeArray: changeListUsersAuth } = useArray();
    const { reactiveObject: changes, doChangeObject: changeChanges } = useObject({
      administrator: { add: [], remove: [] },
      applications: {},
    });
    let totalRows = -1;
    let currentPage = 1;
    const isLoading = ref(false);
    const currentUser = services.loginService.getAuthenticatedUser();
    onMounted(async () => {
      await init();
      changeSubMenuPaths([
        new SubMenuPath(
          i18n.t("titles.authorization-application-creator-management").toLowerCase(),
          () => app.$router.push(`/authorizationsManagementForApplicationCreator`),
          () => app.$router.push("/applications")
        ),
      ]);
    });

    function search(user, search) {
      return user.login.match(search);
    }

    async function init() {
      isLoading.value = true;
      changeChanges({
        administrator: { add: [], remove: [] },
        applications: {},
      });
      if (Object.keys(listUsersAuth.value).length === 0) {
        changeListUsersAuth(
          await services.authorizationService.getAdminAuthorizationsForOpenAdom()
        );
      }
      isLoading.value = false;
    }

    async function registerChanges() {
      await makeChanges();
      await init();
      //window.location.reload();
    }

    async function makeChanges() {
      let localeListUsersAuth = listUsersAuth.value;
      if (Object.keys(changes.applications).length !== 0) {
        for (const userId in changes.applications) {
          if (changes.applications[userId].add) {
            services.authorizationService
              .createAuthorizedRole("applicationCreator", userId, changes.applications[userId].add)
              .then((response) => {
                if (response.id) {
                  localeListUsersAuth
                    .filter((user) => user.login === response.login)
                    .forEach((user) => (user.authorizations = response.authorizations));
                }
              })
              .catch((error) => {
                console.error(
                  `Erreur lors de la création du rôle pour ${changes?.applications?.[userId]?.add}:`,
                  error
                );
              });
          }
        }
        for (const userId in changes.applications) {
          if (changes.applications[userId].remove) {
            services.authorizationService
              .revokeAuthorizedRole(
                "applicationCreator",
                userId,
                changes.applications[userId].remove
              )
              .then((response) => {
                if (response.ok) {
                  localeListUsersAuth.filter((user) => user.id === userId)[0].authorizations =
                    localeListUsersAuth
                      .filter((user) => user.id === userId)[0]
                      .authorizations.filter(
                        (pattern) => !changes.applications[userId]?.remove?.includes(pattern)
                      );
                }
              })
              .catch((error) => {
                console.error(
                  `Erreur lors de la création du rôle pour ${changes?.applications?.[userId]?.remove}:`,
                  error
                );
              });
          }
        }
        changeListUsersAuth(localeListUsersAuth);
      } else if (changes.administrator.add.length !== 0) {
        changes.administrator.add.forEach((userId) => {
          localeListUsersAuth.filter((user) => user.id === userId)[0].openAdomAdmin = true;
          localeListUsersAuth.filter((user) => user.id === userId)[0].authorizations = [];
          localeListUsersAuth.filter((user) => user.id === userId)[0].authorizations.push(".*");
          services.authorizationService.createAuthorizedRole("openAdomAdmin", userId, ".*");
        });
      } else if (changes.administrator.remove.length !== 0) {
        changes.administrator.remove.forEach((userId) => {
          localeListUsersAuth.filter((user) => user.id === userId)[0].openAdomAdmin = false;
          localeListUsersAuth.filter((user) => user.id === userId)[0].authorizations = [];
          services.authorizationService.revokeAuthorizedRole("openAdomAdmin", userId, ".*");
        });
      }
    }

    function selectAdmin(isAdmin, user) {
      if (isAdmin) {
        if (changes.administrator.remove.find((v) => v === user.id)) {
          changes.administrator.remove = changes.administrator.remove.filter((v) => v === v.id);
        } else if (!changes.administrator.add.find((v) => v === user.id)) {
          changes.administrator.add.push(user.id);
        }
      } else {
        if (changes.administrator.add.find((v) => v === user.id)) {
          changes.administrator.add = changes.administrator.add.filter((v) => v === v.id);
        } else if (!changes.administrator.remove.find((v) => v === user.id)) {
          changes.administrator.remove.push(user.id);
        }
      }
    }

    function addApplication(value, user) {
      if (changes.applications[user.id]?.remove?.find((v) => v === value)) {
        changes.applications[user.id].remove = changes.applications[user.id].remove.filter(
          (v) => v !== value
        );
      } else {
        changes.applications[user.id] = changes.applications[user.id] || {};
        changes.applications[user.id].add = changes.applications[user.id].add || [];
        changes.applications[user.id].add.push(value);
      }
      console.log("addApplication ", changes);
    }

    function removeApplication(value, user) {
      if (changes.applications[user.id]?.add?.find((v) => v === value)) {
        changes.applications[user.id].add = changes.applications[user.id].add.filter(
          (v) => v !== value
        );
      } else {
        changes.applications[user.id] = changes.applications[user.id] || {};
        changes.applications[user.id].remove = changes.applications[user.id].remove || [];
        changes.applications[user.id].remove.push(value);
      }
    }

    function beforeAdding(value) {
      return value;
    }

    return {
      subMenuPaths,
      listUsersAuth,
      totalRows,
      currentPage,
      isLoading,
      search,
      changes,
      currentUser,
      registerChanges,
      makeChanges,
      selectAdmin,
      addApplication,
      removeApplication,
      beforeAdding,
    };
  },
};
</script>
<style lang="scss" scoped>
.icon {
  font-size: 0.5rem;
}

.control.has-icons-left .icon {
  top: 5px;
}
</style>
