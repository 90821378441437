import { ApplicationService } from "@/services/rest/ApplicationService";
import { DataService } from "@/services/rest/DataService";
import { LoginService } from "@/services/rest/LoginService";
import { AlertService } from "@/services/AlertService";
import { InternationalisationService } from "@/services/InternationalisationService";
import { TagService } from "@/services/TagService";
import { AdditionalFileService } from "@/services/rest/AdditionalFileService";
import { ErrorsService } from "@/services/ErrorsService";
import { UserPreferencesService } from "@/services/UserPreferencesService";
import { FileService } from "@/services/rest/FileService";
import { SynthesisService } from "@/services/rest/SynthesisService";
import { AuthorizationService } from "@/services/rest/AuthorizationService";
import { RequestRightsService } from "@/services/rest/RequestRightsService";

const services = {
  applicationService: ApplicationService.INSTANCE,
  dataService: DataService.INSTANCE,
  alertService: AlertService.INSTANCE,
  errorsService: ErrorsService.INSTANCE,
  internationalisationService: InternationalisationService.INSTANCE,
  tagService: TagService.INSTANCE,
  hidden_tag: TagService.HIDDEN_TAG,
  additionalFileService: AdditionalFileService.INSTANCE,
  loginService: LoginService.INSTANCE,
  userPreferencesService: UserPreferencesService.INSTANCE,
  synthesisService: SynthesisService.INSTANCE,
  fileService: FileService.INSTANCE,
  authorizationService: AuthorizationService.INSTANCE,
  requestRightsService: RequestRightsService.INSTANCE,
};
export default services;
