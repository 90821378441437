<template>
  <div class="modal-dialog-scrollable">
    <h1 class="title main-title">{{ title }}</h1>
    <slot></slot>
  </div>
</template>

<script>
import useBoolean from "@/composable/components/boolean";
import { onMounted, watch } from "vue";

export default {
  name: "ModalCard",
  components: {},
  props: {
    open: {
      type: Boolean,
      default: false,
      required: true,
    },
    closeCb: {
      type: Function,
      required: true,
    },
    title: {
      type: String,
      default: "",
      required: true,
    },
  },
  setup(props) {
    const innerOpen = useBoolean().refBoolean;
    onMounted(() => {
      innerOpen.value = props.open;
    });
    watch(
      () => props.open,
      (newVal) => {
        innerOpen.value = newVal;
      }
    );
    watch(
      () => innerOpen,
      (newVal) => {
        props.closeCb(newVal);
      }
    );
    return {};
  },
};
</script>

<style lang="scss" scoped>
.ModalCard {
  background-color: white;
  z-index: 1;
  position: absolute;
  height: auto;
  top: 0;
  width: 100%;
  transition: transform 250ms;
}

.ModalCard-close-button {
  position: absolute;
  top: 0;
  right: 0;
}

.animation-content.modal-content {
  max-width: 1400px;
}
</style>
