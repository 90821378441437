import { ref } from "vue";
function useText(text = "") {
  const refText = ref(text);
  const doChangeText = function (newText) {
    refText.value = newText;
  };
  return {
    refText,
    doChangeText,
  };
}
export default useText;
