const SERVER =
  window.location.origin === "http://localhost:8080"
    ? "http://localhost:8081"
    : window.location.origin;

console.log("********************" + SERVER + "***************************");

const constants = {
  BASE: `${SERVER}/api/v1/`,
  API_URL: `${SERVER}/api/v1/`,
  WS_URL: `${SERVER.replace("http", "ws")}/api/V1/`,
  SWAGGER: `${SERVER}/swagger-ui.html`,
};
export default constants;
