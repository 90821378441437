import { ref } from "vue";
function useRegex(regex = /.*/) {
  const refRegex = ref(regex);
  const doChangeRegex = function (newRegex) {
    refRegex.value = newRegex;
  };
  return {
    refRegex,
    doChangeRegex,
  };
}
export default useRegex;
