import { User } from "@/model/User";

export class ApplicationResult {
  id;
  localName;
  localDescription;
  creationDate;
  updateDate;
  name;
  title;
  comment;
  version;
  references = {
    idRef: {
      id: "",
      label: "",
      children: [],
      columns: {
        id: "",
        title: "",
        key: false,
        linkedTo: "",
      },
    },
  };
  dataTypes = {
    idRef: {
      id: "",
      label: "",
      children: [],
      columns: {
        id: "",
        title: "",
        key: false,
        linkedTo: "",
      },
    },
  };
  data = {};
  additionalFile = {};
  configuration = {};
  configFile;
  currentApplicationUserRolesResult = {};
  hasSignedCharte = false;
  hasSignedLastCharte = false;
  authorizations = {};
  dependantNodesByDataName = {};
  internationalization = {};
  orderedReferences = {};
  referenceSynthesis = {};

  isApplicationUser() {
    return (
      this.currentApplicationUserRolesResult?.applicationRoles?.includes("applicationManager") ||
      this.currentApplicationUserRolesResult?.applicationRoles?.includes("userManager") ||
      this.hasSignedCharte
    );
  }

  mustSignCharte() {
    return !(
      this.currentApplicationUserRolesResult?.applicationRoles?.includes("applicationManager") ||
      this.currentApplicationUserRolesResult?.applicationRoles?.includes("userManager") ||
      this.hasSignedLastCharte
    );
  }

  constructor(application) {
    if (!application) {
      return;
    }
    this.id = application.id;
    this.creationDate = application.creationDate;
    this.updateDate = application.updateDate;
    this.name = application.name;
    this.title = application.title;
    this.localName = application.localName;
    this.localDescription = application.localDescription;
    this.comment = application.comment;
    this.version = application.version;
    this.references = application.references || {};
    this.dataTypes = application.dataTypes || {};
    this.data = application.data || {};
    this.additionalFile = application.additionalFiles;
    this.configuration = application.configuration;
    this.configFile = application.configFile;
    this.currentApplicationUserRolesResult = application.currentApplicationUserRolesResult;
    this.internationalization = application.internationalization;
    this.dependantNodesByDataName = application.dependantNodesByDataName;
    this.orderedReferences = application.orderedReferences;
    this.referenceSynthesis = application.referenceSynthesis;
    let charteTimeStamp = User.STORED_AUTHENTICATED_USER()?.chartes?.[application.id]
    this.hasSignedLastCharte =
      new Date().getTime() > charteTimeStamp;
    this.hasSignedCharte = charteTimeStamp;
  }
}
