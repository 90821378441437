<template>
  <div>
    <b-collapse
      v-for="data in dataTypes"
      :key="data.id"
      :aria-id="$t('applications.dataset')"
      :open="false"
      animation="slide"
      class="card"
      style="box-shadow: none"
    >
      <template #trigger="detail">
        <div
          :aria-expanded="!detail.open"
          aria-controls="contentIdForA11y3"
          class="card-header"
          role="button"
          style="box-shadow: none; border-bottom: solid #009d9d"
        >
          <p class="card-header-title" style="margin-bottom: 0">
            {{ data.name }}
          </p>
          <a class="card-header-icon">
            <b-icon :icon="detail.open ? 'chevron-up' : 'chevron-down'" type="is-primary" />
          </a>
        </div>
      </template>
      <div class="card-content" style="padding: 0.6em">
        <div class="content">
          <AuthorizationTableForOneDataType
            :application="application"
            :application-name="application.name"
            :authorization="getAuthorization(data.id)"
            :data="data"
            :data-name="data.id"
            :has-dependency="hasDependencies.includes(data.id)"
            :list-column-name="columnsName"
            :reference-scope="referencesScopes[data.id]"
            :type-time-scope="typeTimeScope(data.id)"
            :with-time-scope="withTimeScope(data.id)"
            @update:propagate-changes="modifyAuthorizations($event)"
            @update:operation-type="modifyOperationTypes(data.id, $event)"
            @update:date-value-interval="modifyTimescope(data.id, $event)"
          />
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { computed } from "vue";
import AuthorizationTableForOneDataType from "@/components/common/authorization/AuthorizationTableForOneDataType.vue";

export default {
  name: "AuthorizationForScope",
  components: { AuthorizationTableForOneDataType },
  props: {
    authorization: {
      type: Object,
      required: true,
    },
    authorizationsDescription: {
      type: Object,
      required: true,
    },
    application: {
      type: Object,
      required: true,
    },
    dataTypes: {
      type: Object,
      required: true,
    },
    listColumnName: {
      type: Object,
      required: true,
    },
    referencesScopes: {
      type: Object,
      required: true,
    },
    hasDependencies: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["update:authorization"],
  setup(props, { emit }) {
    const columnsName = computed(() => props.listColumnName || {});

    const withTimeScope = computed(
      () => (dataId) =>
        Object.keys(props.authorizationsDescription?.[dataId] || {}).includes("timeScope")
    );

    const typeTimeScope = computed(() => (dataId) => {
      if (props.authorizationsDescription[dataId].timeScope) {
        return props.application.configuration.dataDescription[dataId].componentDescriptions[
          props.authorizationsDescription[dataId].timeScope
        ].checker.type === "DateChecker"
          ? "date"
          : "";
      } else {
        return withTimeScope.value(dataId) ? props.authorizationsDescription[dataId].timeScope : "";
      }
    });

    const createDefaultAuthorization = () => ({
      operationTypes: [],
      requiredAuthorizations: {},
      fromDay: [-999999999, 1, 1],
      toDay: [999999999, 12, 31],
      hasExtraction: false,
    });

    const getAuthorization = (dataId) =>
      props.authorization[dataId] || createDefaultAuthorization();

    const updateAuthorization = (datatype, updater) => {
      const authorization = { ...props.authorization };
      authorization[datatype] = authorization[datatype] || createDefaultAuthorization();
      updater(authorization[datatype]);
      emit("update:authorization", authorization);
    };

    const modifyTimescope = (datatype, { from, to }) => {
      updateAuthorization(datatype, (auth) => {
        auth.fromDay = from;
        auth.toDay = to;
      });
    };

    const modifyOperationTypes = (datatype, operationTypes) => {
      updateAuthorization(datatype, (auth) => {
        auth.operationTypes = operationTypes;
      });
    };

    const modifyAuthorizations = ({ changes, datatype, scope }) => {
      updateAuthorization(datatype, (auth) => {
        auth.requiredAuthorizations = auth.requiredAuthorizations || {};
        auth.requiredAuthorizations[scope] = auth.requiredAuthorizations[scope] || [];

        changes.forEach((change) => {
          const index = auth.requiredAuthorizations[scope].indexOf(change.path);
          if (change.remove) {
            if (index !== -1) {
              auth.requiredAuthorizations[scope].splice(index, 1);
            }
          } else if (index === -1) {
            auth.requiredAuthorizations[scope].push(change.path);
          }
        });
      });
    };

    return {
      columnsName,
      withTimeScope,
      typeTimeScope,
      getAuthorization,
      modifyTimescope,
      modifyOperationTypes,
      modifyAuthorizations,
    };
  },
};
</script>

<style lang="scss" scoped>
.card {
  &-header {
    border-bottom: solid #009d9d;
  }

  &-content {
    padding: 0.6em;
  }
}
</style>
