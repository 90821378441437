export class Tag {
  static DOMAIN_TAG = "DOMAIN_TAG";
  static HIDDEN_TAG = "HIDDEN_TAG";
  static ORDER_TAG = "ORDER_TAG";
  static DATA_TAG = "DATA_TAG";
  static REFERENCE_TAG = "REFERENCE_TAG";
  static NO_TAG = "NO_TAG";
  static NO_TAG_NAME = "no_tag";
  static NO_TAG_INSTANCE = () => new NoTag();

  tagName;
  type;
  selected = true;
  localName;

  findTagInTagList(listOfTags) {
    return Object.values(listOfTags || []).find((tag) => {
      return (
        (this.type === tag.type || this.type === tag.tagDefinition) && this.tagName === tag.tagName
      );
    });
  }

  static buildTag(tag, internationalize) {
    if (tag.tagDefinition === Tag.DOMAIN_TAG) {
      return new DomainTag(tag.tagName, internationalize);
    } else if (tag.tagDefinition === Tag.ORDER_TAG) {
      return new OrderTag(tag.tagOrder, internationalize);
    } else if (tag.tagDefinition === Tag.HIDDEN_TAG) {
      return HiddenTag.INSTANCE;
    } else if (tag.tagDefinition === Tag.DATA_TAG) {
      return DataTag.INSTANCE;
    } else if (tag.tagDefinition === Tag.REFERENCE_TAG) {
      return ReferenceTag.INSTANCE;
    } else if (tag.tagDefinition === Tag.NO_TAG) {
      return NoTag.INSTANCE;
    }
    return NoTag.INSTANCE;
  }

  constructor(tagName, type, internationalize) {
    this.tagName = tagName;
    this.type = type;
    this.localName = internationalize ? internationalize(tagName) : tagName;
  }
}

class DomainTag extends Tag {
  constructor(tagName, internationalize) {
    super(tagName, Tag.DOMAIN_TAG, internationalize);
  }
}

class OrderTag extends Tag {
  order;

  constructor(order, internationalize) {
    super(Tag.ORDER_TAG, Tag.ORDER_TAG, internationalize);
    this.order = order;
  }
}

class HiddenTag extends Tag {
  static INSTANCE = new HiddenTag();

  constructor() {
    super(Tag.HIDDEN_TAG, Tag.HIDDEN_TAG);
  }
}

class DataTag extends Tag {
  static INSTANCE = new DataTag();

  constructor() {
    super(Tag.DATA_TAG, Tag.DATA_TAG);
  }
}

class ReferenceTag extends Tag {
  static INSTANCE = new ReferenceTag();

  constructor() {
    super(Tag.REFERENCE_TAG, Tag.REFERENCE_TAG);
  }
}

class NoTag extends Tag {
  static INSTANCE = new NoTag();

  constructor() {
    super(Tag.NO_TAG_NAME, Tag.NO_TAG);
  }
}
