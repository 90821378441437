import { Tag } from "@/model/application/Tag";

export class Component {
  static BASIC_COMPONENT = "BasicComponent";
  static COMPUTED_COMPONENT = "ComputedComponent";
  static DYNAMIC_COMPONENT = "DynamicComponent";
  static PATTERN_COMPONENT = "PatternComponent";
  static CONSTANT_COMPONENT = "ConstantComponent";
  static UNKNWON_COMPONENT = "ErrorComponent";
  static MULTIPLICITY_ONE = "ONE";
  static MULTIPLICITY_MANY = "MANY";
  type;
  id;
  title = "#id";
  key = false;
  linkedTo = null;
  tags = [Tag.NO_TAG_INSTANCE()];
  componentKey;
  exportHeader;
  checker;
  multiplicity;
  refLinkedTo;
  refLinkedToColumn;
  referenceType;
  patternColumnComponents;
  displaysForRow;
  _getInternationalizedColumn;
  _internationalize;

  static compare(a, b) {
    if (a.order() === b.order()) return a.componentKey.localeCompare(b.componentKey);
    if (a.order() > b.order()) return 1;
    return -1;
  }

  getHeader(application, dataId) {
    if (application.data[dataId].componentDescriptions[this.id]) {
      let columnName;
      let importHeader = application.data[dataId].componentDescriptions[this.id].importHeader;
      let exportHeader = this.exportHeader;
      if (exportHeader !== this.id) {
        columnName = exportHeader || this.id;
      } else if (importHeader !== undefined && importHeader !== this.id) {
        columnName = importHeader;
      } else {
        columnName = this.componentKey;
      }
      return columnName;
    }
    return this.componentKey;
  }

  getColumnValue(row) {
    return row.values[this.id];
  }

  getDisplayValue = function (row) {
    let columnValue = this.getColumnValue(row);
    let displaysForRow = row.displaysForRow?.[this.refLinkedTo]?.[row.values[this.id]];
    if (displaysForRow && typeof columnValue !== "number") {
      return displaysForRow;
    } else {
      return "" + columnValue;
    }
  };

  order() {
    let order = (this.tags || []).find(
      (tag) => tag.tagDefinition === "ORDER_TAG" || tag.type === "ORDER_TAG"
    );
    if (order && order.tagOrder) {
      return order ? order.tagOrder : 99999;
    }
    return order ? order.order : 99999;
  }

  constructor(type, componentKey, exportHeader, checker, displaysForRow, tags) {
    this.type = type;
    this.id = componentKey;
    this.exportHeader = exportHeader || componentKey;
    this.title = componentKey;
    this.componentKey = componentKey;
    this.checker = checker;
    this.multiplicity = checker ? checker.multiplicity : Component.MULTIPLICITY_ONE;
    this.referenceType = checker ? checker.type : null;
    this.refLinkedTo = checker ? checker.refType : null;
    this.refLinkedToColumn = checker ? checker.componentKey : null;
    this.displaysForRow = displaysForRow;
    this.tags = tags && tags.length ? tags : [Tag.NO_TAG_INSTANCE()];
  }

  static build(
    component,
    referenceName,
    application,
    getInternationalizedColumn,
    internationalize
  ) {
    let localName = getInternationalizedColumn(
      referenceName,
      component.exportHeader || component.exportHeaderName || component.componentKey,
      application
    );
    if (component.patternColumnComponents) {
      for (let patternColumnComponent in component.patternColumnComponents) {
        component.patternColumnComponents[patternColumnComponent].exportHeaderName =
          getInternationalizedColumn(
            referenceName,
            component.patternColumnComponents[patternColumnComponent].exportHeader ||
              component.patternColumnComponents[patternColumnComponent].exportHeaderName ||
              component.patternColumnComponents[patternColumnComponent].componentKey,
            application
          );
      }
    }
    let displaysForRow = component.displaysForRow;
    let tags = (component.tags || []).map((tag) => Tag.buildTag(tag, internationalize));
    if (!tags.length) {
      tags.push(Tag.NO_TAG_INSTANCE());
    }
    let componentToReturn;
    switch (component?.type) {
      case Component.BASIC_COMPONENT: {
        componentToReturn = new BasicComponent(
          Component.BASIC_COMPONENT,
          component.componentKey,
          localName,
          component.checker,
          displaysForRow,
          tags
        );
        break;
      }
      case Component.COMPUTED_COMPONENT: {
        componentToReturn = new ComputedComponent(
          Component.COMPUTED_COMPONENT,
          component.componentKey,
          localName,
          component.checker,
          displaysForRow,
          tags
        );
        break;
      }
      case Component.DYNAMIC_COMPONENT: {
        componentToReturn = new ComputedComponent(
          Component.DYNAMIC_COMPONENT,
          component.componentKey,
          localName,
          component.checker,
          displaysForRow,
          tags
        );
        break;
      }
      case Component.PATTERN_COMPONENT: {
        componentToReturn = new PatternComponent(
          Component.PATTERN_COMPONENT,
          component.componentKey,
          localName,
          component.checker,
          displaysForRow,
          tags,
          component.patternForComponents,
          component.patternComponentQualifiers,
          component.patternComponentAdjacents,
          getInternationalizedColumn,
          internationalize
        );
        break;
      }
      case Component.CONSTANT_COMPONENT: {
        componentToReturn = new ComputedComponent(
          Component.CONSTANT_COMPONENT,
          component.componentKey,
          localName,
          component.checker,
          displaysForRow,
          tags
        );
        break;
      }
      default: {
        componentToReturn = new Component(
          Component.UNKNWON_COMPONENT,
          component.componentKey,
          localName,
          component.checker,
          displaysForRow,
          tags
        );
      }
    }
    componentToReturn._getInternationalizedColumn = getInternationalizedColumn;
    componentToReturn._internationalize = internationalize;
    return componentToReturn;
  }
}

class BasicComponent extends Component {
  constructor(type, componentKey, exportHeader, checker, displaysForRow, tags) {
    super(type, componentKey, exportHeader, checker, displaysForRow, tags);
  }
}

class ComputedComponent extends Component {
  constructor(type, componentKey, exportHeader, checker, displaysForRow, tags) {
    super(type, componentKey, exportHeader, checker, displaysForRow, tags);
  }
}

class PatternComponent extends Component {
  patternAdjacentComponents;
  patternQualifierComponents;
  originalName;
  rowId;

  constructor(
    type,
    componentKey,
    exportHeader,
    checker,
    displaysForRow,
    tags,
    patternColumnComponents,
    patternQualifierComponents,
    patternAdjacentComponents,
    getInternationalizedColumn,
    internationalize
  ) {
    super(type, componentKey, exportHeader, checker, displaysForRow, tags);
    this._getInternationalizedColumn = getInternationalizedColumn
      ? getInternationalizedColumn
      : this._getInternationalizedColumn;
    this._internationalize = internationalize ? internationalize : this._internationalize;
    this.patternColumnComponents = patternColumnComponents;
    patternQualifierComponents = Object.values(patternQualifierComponents || []).map(
      (patternQualifierComponent) => {
        let qualifierToAdd = new PatternQualifierComponent(componentKey, patternQualifierComponent);
        qualifierToAdd._internationalize = this._internationalize;
        qualifierToAdd._getInternationalizedColumn = this._getInternationalizedColumn;
        return qualifierToAdd;
      }
    );
    this.patternQualifierComponents = patternQualifierComponents;
    patternAdjacentComponents = Object.values(patternAdjacentComponents || []).map(
      (patternAdjacentComponent) => {
        let qualifierToAdd = new PatternAdjacentComponent(componentKey, patternAdjacentComponent);
        qualifierToAdd._internationalize = this._internationalize;
        qualifierToAdd._getInternationalizedColumn = this._getInternationalizedColumn;
        return qualifierToAdd;
      }
    );
    this.patternAdjacentComponents = patternAdjacentComponents;
  }

  getHeader() {
    return this.originalName;
  }

  getColumnValue(row) {
    let pattern = this.id.match("(.*?)::(.*)");
    return row.values[pattern[1]]?.find((column) => column.__ORIGINAL_COLUMN_NAME__ == pattern[2])
      ?.__VALUE__;
  }

  componentsForValue(value, row) {
    let componentsForValue = [];
    let id = this.id + "::" + value.__ORIGINAL_COLUMN_NAME__;

    let patterns = row.allPatternColumnName.filter((name) => name.match(this.patternForComponents));
    let newPatternComponent = Object.create(
      Object.getPrototypeOf(this),
      Object.getOwnPropertyDescriptors(this)
    );
    newPatternComponent.patternForComponents = patterns;
    newPatternComponent.rowId = id;
    newPatternComponent.id = id;
    newPatternComponent.originalName = value.__ORIGINAL_COLUMN_NAME__;
    componentsForValue.push(newPatternComponent);
    this.patternAdjacentComponents.forEach((adjacent) => {
      let newAdjacent = Object.create(
        Object.getPrototypeOf(adjacent),
        Object.getOwnPropertyDescriptors(adjacent)
      );
      newAdjacent.id = adjacent.id + "::" + value.__ORIGINAL_COLUMN_NAME__;
      componentsForValue.push(newAdjacent);
    });
    return componentsForValue;
  }

  getColumnQualifiersMap(application, dataId, row) {
    let pattern = this.id.match("(.*)::(.*)");
    let value = row.values[pattern[1]].find(
      (component) => pattern[2] == component.__ORIGINAL_COLUMN_NAME__
    );
    let qualifiers = this.patternQualifierComponents.map((col) => {
      let columnName = col.getHeader(application, dataId, col.id);
      let returnValue = {
        component: col,
        column: col.id,
        columnName,
        value: value[col.id],
        id: col.id,
      };
      returnValue[col.id] = value[col.id];
      return returnValue;
    }, []);

    return qualifiers;
  }
}

class PatternQualifierComponent extends Component {
  parentComponentKey;

  constructor(parentComponentKey, patternQualifierComponent) {
    super(
      patternQualifierComponent.type,
      patternQualifierComponent.componentKey,
      patternQualifierComponent.exportHeader,
      patternQualifierComponent.checker,
      patternQualifierComponent.tags
    );
    this.parentComponentKey = parentComponentKey;
  }

  getHeader(application, dataId, id) {
    if (id) {
      return this._getInternationalizedColumn(dataId, id, application);
    } else if (this.id.match("(.*::.*)::.*")) {
      return this._getInternationalizedColumn(
        dataId,
        this.id.match("(.*::.*)::.*")[1],
        application
      );
    } else if (this.id.match(".*::.*")) {
      return this._getInternationalizedColumn(dataId, this.id, application);
    }
    return "PatternQualifierComponent non trouvé";
  }
}

class PatternAdjacentComponent extends Component {
  parentComponentKey;
  adjacentComponentKey;

  constructor(parentComponentKey, patternAdjacentComponent) {
    let componentKey = parentComponentKey + "::" + patternAdjacentComponent.componentKey;
    super(
      patternAdjacentComponent.type,
      componentKey,
      patternAdjacentComponent.exportHeader,
      patternAdjacentComponent.checker,
      patternAdjacentComponent.tags
    );
    this.parentComponentKey = parentComponentKey;
    this.adjacentComponentKey = patternAdjacentComponent.componentKey;
  }

  getHeader(application, dataId) {
    if (this.id.match("(.*::.*)::.*")) {
      return this._getInternationalizedColumn(
        dataId,
        this.id.match("(.*::.*)::.*")[1],
        application
      );
    } else if (this.id.match(".*::.*")) {
      return this._getInternationalizedColumn(dataId, this.id, application);
    }
    return "PatternAdjacentComponent non trouvé";
  }

  getColumnValue(row) {
    let pattern = this.id.match("(.*)::(.*)::(.*)");
    if (pattern) {
      return row.values[pattern[1]]?.find(
        (column) => column.__ORIGINAL_COLUMN_NAME__ == pattern[3]
      )?.[pattern[2]];
    }
    return "PatternAdjacentComponent not found " + this.id;
  }
}
