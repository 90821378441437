import { ref } from "vue";

function useBoolean(bool = false) {
  const refBoolean = ref(bool);
  const doChangeBoolean = function (newBoolean) {
    refBoolean.value = newBoolean;
  };
  const doSwitchBoolean = function () {
    refBoolean.value = !refBoolean.value;
  };
  return {
    refBoolean,
    doChangeBoolean,
    doSwitchBoolean,
  };
}
export default useBoolean;
