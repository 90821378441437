<template>
  <PageView class="with-submenu">
    <SubMenu
      :aria-label="$t('menu.aria-sub-menu')"
      :paths="subMenuPaths"
      :root="application.localName || application.title"
      role="navigation"
    />
    <h1 class="title main-title">
      {{
        $t("titles.additionalFileWithType", {
          localName:
            internationalisationService.getLocaleforPath(
              application,
              "additionalFiles." + additionalFileName + ".internationalizationName"
            ) || additionalFileName,
        })
      }}
    </h1>
    <LoadingAnimate v-if="!this.columnsVisible" :size="'is-medium'"></LoadingAnimate>
    <section class="section">
      <ValidationObserver ref="observer">
        <article class="fieldsForm">
          <FieldsForm
            :application="application"
            :comment="comment"
            :description="description"
            :fields="fields"
            :format="format"
            :ref-values="references"
            :showComment="true"
            pathForKey="rightsRequest.format"
            @update:fields="updateFields"
            @update:comment="updateComment"
          >
          </FieldsForm>
        </article>
        <article class="additionalFileUpload">
          <b-collapse v-model="isOpenFileUpload" animation="slide" class="panel">
            <template #trigger>
              <div
                :aria-expanded="isOpenFileUpload"
                aria-controls="contentIdForA11y2"
                class="panel-heading"
                role="button"
              >
                <strong>
                  <FontAwesomeIcon
                    :icon="isOpenFileUpload ? 'caret-down' : 'caret-right'"
                    class="clickable mr-3"
                    tabindex="0"
                  />
                  {{ $t("additionalFiles.titles.upload") }}
                </strong>
              </div>
            </template>

            <ValidationProvider
              ref="file"
              v-slot="{ errors, valid }"
              :rules="rules()"
              class="column is-12"
            >
              <b-field
                :label="$t('additionalFiles.additionalFile')"
                :message="errors"
                :type="{
                  'is-danger': errors && errors.length > 0,
                  'is-success': valid,
                }"
                class="file is-primary column is-12"
              >
                <b-upload
                  v-model="file"
                  class="file-label"
                  data-cy="changeFileButton"
                  expanded
                  required
                  style="margin-top: 30px"
                  @input="loadFile"
                >
                  <span class="file-cta">
                    <b-icon class="file-icon" icon="upload"></b-icon>
                    <span class="file-label">{{ $t("dataTypesRepository.choose-file") }}</span>
                  </span>
                </b-upload>
                <span>{{ errors[0] }}</span>
              </b-field>
            </ValidationProvider>

            <ValidationProvider
              ref="fileName"
              v-slot="{ errors, valid }"
              :rules="rules()"
              class="column is-12"
            >
              <b-field
                :label="'nom du fichier'"
                :message="errors"
                :type="{
                  'is-danger': errors && errors.length > 0,
                  'is-success': valid,
                }"
                class="file is-primary column is-12"
              >
                <b-input v-model="fileName" type="text" />
              </b-field>
              <b-field :label="$t('additionalFiles.forApplication')">
                <b-switch v-model="forApplication" />
              </b-field>
            </ValidationProvider>
          </b-collapse>
        </article>
        <article class="additionalFileAssociate">
          <b-collapse v-model="isOpenFileAssociate" animation="slide" class="panel">
            <template #trigger>
              <div
                :aria-expanded="isOpenFileAssociate"
                aria-controls="contentIdForA11y2"
                class="panel-heading"
                role="button"
              >
                <strong>
                  <FontAwesomeIcon
                    :icon="isOpenFileAssociate ? 'caret-down' : 'caret-right'"
                    class="clickable mr-3"
                    tabindex="0"
                  />
                  {{ $t("additionalFiles.titles.associate") }}
                </strong>
              </div>
            </template>
            <div v-for="(datatypeInfos, datatype) in datatypes" :key="datatype">
              <div
                v-if="dataGroups[datatype] && authReferences[datatype] && columnsVisible[datatype]"
              >
                <AuthorizationTableForDatatype
                  :auth-references="authReferences[datatype]"
                  :authorization="authorization.authorizations[datatype]"
                  :authorization-scopes="authorizationScopes[datatype]"
                  :columns-visible="columnsVisible[datatype]"
                  :current-authorization-scope="{}"
                  :data-groups="dataGroups[datatype]"
                  :datatype="{ id: datatype, name: datatypeInfos.name }"
                  :is-root="true"
                  :isApplicationAdmin="canManage"
                  :ownAuthorizations="ownAuthorizations[datatype]"
                  :ownAuthorizationsColumnsByPath="ownAuthorizationsColumnsByPath[datatype]"
                  :publicAuthorizations="publicAuthorizations[datatype] || {}"
                  class="rows"
                  @modifyAuthorization="modifyAuthorization($event, datatype)"
                  @registerCurrentAuthorization="registerCurrentAuthorization($event, datatype)"
                >
                  <div class="row">
                    <div class="columns">
                      <b-field
                        v-for="(column, indexColumn) of columnsVisible[datatype]"
                        :key="indexColumn"
                        :field="indexColumn"
                        :label="getColumnTitle(column)"
                        :style="!column.display ? 'display : contents' : ''"
                        class="column"
                      ></b-field>
                    </div>
                  </div>
                </AuthorizationTableForDatatype>
              </div>
            </div>
          </b-collapse>
        </article>

        <div class="buttons">
          <b-button
            :type="'is-danger'"
            icon-left="times-circle"
            @click="modifyAssociateFile('consult')"
          >
            {{ $t("additionalFiles.buttons.cancel") }}
          </b-button>
          <b-button
            :active="validFile && validForm"
            :disabled="!validFile || !validForm"
            :type="additionalFileId === 'new' ? 'is-primary' : 'is-warning'"
            icon-left="edit"
            @click="changeConfiguration"
          >
            {{ $t("additionalFiles.buttons.submit") }}
          </b-button>
        </div>
      </ValidationObserver>
    </section>
  </PageView>
</template>

<script>
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";

import CollapsibleTree from "@/components/common/CollapsibleTree.vue";
import SubMenu, { SubMenuPath } from "@/components/common/SubMenu.vue";
import { AuthorizationService } from "@/services/rest/AuthorizationService";
import { AdditionalFileService } from "@/services/rest/AdditionalFileService";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import PageView from "../common/PageView.vue";
import { ApplicationResult } from "@/model/ApplicationResult";
import AuthorizationTable from "@/components/common/AuthorizationTable";
import AuthorizationTableForDatatype from "@/components/common/AuthorizationTableForDatatype.vue";
import { Authorization } from "@/model/authorization/Authorization";
import { Authorizations } from "@/model/authorization/Authorizations";
import FieldsForm from "@/components/common/provider/FieldsForm.vue";

import services from "@/composable/services";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import LoadingAnimate from "@/components/common/LoadingAnimate.vue";
import { User } from "@/model/User";

@Component({
  components: {
    LoadingAnimate,
    AuthorizationTable,
    AuthorizationTableForDatatype,
    PageView,
    SubMenu,
    CollapsibleTree,
    ValidationObserver,
    ValidationProvider,
    FontAwesomeIcon,
    FieldsForm,
  },
})
export default class AdditionalFileInfosView extends Vue {
  @Prop() applicationName;
  @Prop() additionalFileName;
  @Prop({ default: "new" }) additionalFileId;
  __DEFAULT__ = "__DEFAULT__";
  dataService = services.dataService;
  references = {};
  authorizationService = AuthorizationService.INSTANCE;
  alertService = services.alertService;
  applicationService = services.applicationService;
  userPreferencesService = services.userPreferencesService;
  internationalisationService = services.internationalisationService;
  isOpenFileUpload = true;
  isOpenFileAssociate = true;
  additionalFileService = AdditionalFileService.INSTANCE;
  authorization = {};
  publicAuthorizations = {};
  ownAuthorizations = [];
  ownAuthorizationsColumnsByPath = {};
  authorizations = [];
  users = [];
  name = null;
  dataGroups = {};
  authorizationScopes = {};
  application = new ApplicationResult();
  selectedUsers = [];
  isApplicationAdmin = false;
  canManage = false;
  isLoading;
  datatypes = [];

  fields = {};
  file = null;
  validForm = false;
  validFile = false;
  periods = {
    FROM_DATE: this.$t("dataTypeAuthorizations.from-date"),
    TO_DATE: this.$t("dataTypeAuthorizations.to-date"),
    FROM_DATE_TO_DATE: this.$t("dataTypeAuthorizations.from-date-to-date"),
    ALWAYS: this.$t("dataTypeAuthorizations.always"),
  };
  COLUMNS_VISIBLE = {
    label: {
      title: "Label",
      display: true,
      internationalizationName: { fr: "Domaine", en: "Domain" },
    },
  };
  columnsVisible = false;
  period = this.periods.FROM_DATE_TO_DATE;
  startDate = null;
  endDate = null;
  configuration = {};
  authReferences = {};
  subMenuPaths = [];
  repository = null;
  filteredTags = [];
  format = {};
  description = "";

  currentUser = {};
  comment = null;
  additionalFile = {};
  forApplication = false;
  fileNames = [];
  fileName = null;
  extend = extend;

  @Watch("authReferences")
  onExternalOpenStateChanged(newVal) {
    this.authReferences = newVal;
  }

  getColumnTitle(column) {
    if (column.display) {
      return (
        (column.internationalizationName && column.internationalizationName[this.$i18n.locale]) ||
        column.title
      );
    }
  }

  modifyAuthorization(event) {
    let datatype = event.datatype;
    const authorization = this.authorization.authorizations[datatype];
    let authorizations = authorization.authorizations[event.indexColumn] || [];
    for (const authorizationKeytoAdd in event.authorizations.toAdd) {
      authorizations.push(event.authorizations.toAdd[authorizationKeytoAdd]);
    }
    for (const authorizationKeytoDelete in event.authorizations.toDelete) {
      const toDeleteElement = event.authorizations.toDelete[authorizationKeytoDelete];
      authorizations = authorizations.filter((auth) => {
        return !new Authorization(auth).equals(
          toDeleteElement,
          this.authorizationScopes[datatype].map((scope) => scope.id)
        );
      });
    }
    authorization.authorizations[event.indexColumn] = authorizations;
    this.$set(
      this.authorization.authorizations,
      datatype,
      new Authorizations(
        authorization,
        this.authorizationScopes[datatype].map((as) => as.id)
      )
    );
  }

  registerCurrentAuthorization(event) {
    let datatype = event.datatype;
    const authorization = this.authorization.authorizations[event.datatype];
    let authorizations = authorization.authorizations[event.indexColumn] || [];
    const authorizationToReplace = event.authorizations;
    authorizationToReplace.fromDay = authorizationToReplace.from && [
      authorizationToReplace.from.getFullYear(),
      authorizationToReplace.from.getMonth() + 1,
      authorizationToReplace.from.getDate(),
    ];
    authorizationToReplace.toDay = authorizationToReplace.to && [
      authorizationToReplace.to.getFullYear(),
      authorizationToReplace.to.getMonth() + 1,
      authorizationToReplace.to.getDate(),
    ];
    authorizations = authorizations.map((auth) => {
      if (
        !new Authorization(auth).equals(
          authorizationToReplace,
          this.authorizationScopes[datatype].map((scope) => scope.id)
        )
      ) {
        return auth;
      } else {
        return authorizationToReplace;
      }
    });
    authorization.authorizations[event.indexColumn] = authorizations;
    this.$set(
      this.authorization.authorizations,
      event.datatype,
      new Authorizations(
        authorization,
        this.authorizationScopes.map((as) => as.id)
      )
    );
  }

  async created() {
    await this.init();
    this.chosenLocale = this.userPreferencesService.getUserPrefLocale();
    this.subMenuPaths = [
      new SubMenuPath(
        this.$t("additionalFilesManagement.additionalFiles").toLowerCase(),
        () => this.$router.push(`/applications/${this.applicationName}/additionalFiles`),
        () => this.$router.push("/applications")
      ),
      new SubMenuPath(
        this.$t(
          this.additionalFileId === "new"
            ? `referencesAuthorizations.sub-menu-new-authorization`
            : "referencesAuthorizations.sub-menu-modify-authorization",
          { additionalFileId: this.additionalFileId }
        ),
        () => {},
        () => {
          this.$router.push(`/applications/${this.applicationName}/additionalFiles`);
        }
      ),
    ];
    this.isLoading = false;
  }

  mounted() {}

  async init() {
    this.isLoading = true;
    try {
      this.application = await this.applicationService.getApplication(this.applicationName, [
        "CONFIGURATION",
        "DATATYPE",
        "RIGHTSREQUEST",
      ]);
      this.datatypes = (Object.keys(this.application.configuration.dataTypes) || []).reduce(
        (acc, datatype) => {
          acc[datatype] = {
            name:
              this.internationalisationService.localeDataTypeIdName(
                this.application,
                this.application.dataTypes[datatype]
              ) || datatype,
          };
          return acc;
        },
        {}
      );
      this.format =
        ((this.application?.configuration?.additionalFiles || {})[this.additionalFileName] || {})
          .format || {};
      this.description = this.$t("titles.additionalFileDescription");
      this.fields = (Object.keys(this.format) || []).reduce((acc, field) => {
        acc[field] = "";
        return acc;
      }, {});
      this.configuration = (Object.keys(this.datatypes) || []).reduce((acc, datatype) => {
        acc[datatype] = this.application.configuration.dataTypes[datatype];
        return acc;
      }, {});
      this.application = this.internationalisationService.mergeInternationalization(
        this.application
      );
      this.authorizations = (Object.keys(this.datatypes) || []).reduce((acc, datatype) => {
        acc[datatype] = this.configuration[datatype]?.authorization?.authorizationScopes || [];
        return acc;
      }, {});
      this.repository = (Object.keys(this.datatypes) || []).reduce((acc, datatype) => {
        acc[datatype] = this.application.dataTypes[datatype].repository;
        return acc;
      }, {});
      const grantableInfos = await this.authorizationService.getAuthorizationGrantableInfos(
        this.applicationName
      );
      ({
        authorizationScopes: this.authorizationScopes,
        dataGroups: this.dataGroups,
        users: this.users,
        publicAuthorizations: this.publicAuthorizations,
        isApplicationAdmin: this.isApplicationAdmin,
        ownAuthorizations: this.ownAuthorizations,
        ownAuthorizationsColumnsByPath: this.ownAuthorizationsColumnsByPath,
        columnsVisible: this.columnsVisible,
      } = Authorizations.parseGrantableInfos(grantableInfos, this.datatypes, this.repository));

      let additionalFiles = await this.additionalFileService.getAdditionalFiles(
        this.applicationName,
        this.additionalFileName,
        {}
      );
      this.fileNames = additionalFiles.fileNames.filter(
        (file) => this.additionalFile && this.additionalFile.fileName != file.fileName
      );
      let additionalFile = additionalFiles.additionalBinaryFiles.find(
        (file) => file.id == this.additionalFileId
      );
      this.fileName = additionalFile && additionalFile.fileName;
      if (this.additionalFileId != "new") {
        this.validForm = true;
        this.validFile = true;
        this.forApplication = additionalFile.forApplication;
        this.currentUser = additionalFiles.users.find(
          (user) =>
            user.id == (additionalFile.user || User.STORED_AUTHENTICATED_USER()?.id)
        );
        this.comment = additionalFile.comment;
        this.fields = Object.keys(this.format || {} || []).reduce((acc, field) => {
          acc[field] = (additionalFile.additionalBinaryFileForm || {})[field];
          return acc;
        }, {});

        let authorizations = (additionalFile && additionalFile.associates) || [];
        let initialValue = new Authorizations(
          {
            authorizations: {},
            applicationNameOrId: this.applicationName,
            users: [this.users],
            name: additionalFile.comment,
            uuid: additionalFile.id,
          },
          []
        );
        this.authorization = (Object.keys(this.datatypes) || []).reduce((auth, datatype) => {
          auth.authorizations[datatype] = new Authorizations(
            { authorizations: authorizations[datatype] },
            (this.authorizationScopes[datatype] || []).map((as) => as.id)
          );
          return auth;
        }, initialValue);
        this.canManage =
          this.isApplicationAdmin ||
          (authorizations.users &&
            authorizations.users[0].login ===
              User.STORED_AUTHENTICATED_USER()?.login);
      } else {
        this.fileNames = additionalFiles.fileNames;
        let initialValue = new Authorizations(
          {
            authorizations: {},
            applicationNameOrId: this.applicationName,
            users: [],
            name: "",
            uuid: null,
          },
          []
        );
        this.authorization = (Object.keys(this.datatypes) || []).reduce((acc, datatype) => {
          acc.authorizations[datatype] = new Authorizations(
            { dataType: datatype, applicationNameOrId: this.applicationName },
            (this.authorizationScopes[datatype] || []).map((as) => as.id)
          );
          return acc;
        }, initialValue);
        this.canManage = true;
      }
      let currentAuthorizationUsers = this.authorization.users || [];
      this.selectedUsers = (this.users || []).filter((user) => {
        return currentAuthorizationUsers.find((u) => {
          return u.id === user.id;
        });
      });
      this.selectedUsers.sort();
      this.authReferences = await Authorizations.initAuthReferences(
        this.configuration,
        this.authorizations,
        this.authorizationScopes,
        this.getOrLoadReferences
      );

      let columnsVisible = {};
      for (const datatype in this.columnsVisible) {
        columnsVisible[datatype] = {
          ...Authorizations.COLUMNS_VISIBLE,
          associate: {
            title: "associate",
            display: true,
            forPublic: true,
            forRequest: true,
            internationalizationName: { fr: "Associer", en: "Associate" },
          },
        };
      }
      this.columnsVisible = columnsVisible;
    } catch (error) {
      this.alertService.toastServerError(error);
    }
  }

  async getOrLoadReferences(reference) {
    if (this.references[reference]) {
      return this.references[reference];
    }
    let ref = await this.dataService.getData(this.applicationName, reference);
    this.$set(this.references, reference, ref);
    return ref;
  }

  updateFields(event) {
    this.fields = event.fields;
    this.validForm = event.valid;
    this.loadFile(event);
  }

  updateComment(event) {
    this.comment = event.comment;
    this.validForm = event.valid;
    this.loadFile(event);
  }

  @Watch("period")
  onPeriodChanged() {
    this.endDate = null;
    this.startDate = null;
  }

  async changeConfiguration() {
    if (!this.validFile || !this.validForm) {
      return;
    }
    try {
      let authorizationToSend = {
        uuid: this.authorization.uuid,
        name: this.authorization.name,
        applicationNameOrId: this.applicationName,
        authorizations: {},
      };
      authorizationToSend.usersId = this.selectedUsers.map((user) => user.id);
      for (const datatype in this.authorization.authorizations) {
        let authorizationForDatatype = this.authorization.authorizations[datatype].authorizations;
        for (const scope in authorizationForDatatype) {
          authorizationForDatatype[scope] = authorizationForDatatype[scope].map((auth) => {
            const returnedAuth = new Authorization(auth);
            returnedAuth.intervalDates = {
              fromDay: returnedAuth.fromDay,
              toDay: returnedAuth.toDay,
            };
            delete returnedAuth.fromDay;
            delete returnedAuth.toDay;
            delete returnedAuth.from;
            delete returnedAuth.to;
            delete returnedAuth.path;
            returnedAuth.dataGroups = returnedAuth.dataGroups.map((dg) => dg.id || dg);
            return returnedAuth;
          });
          authorizationToSend.authorizations[datatype] = authorizationForDatatype;
        }
      }
      if (!(this.comment && this.comment.length)) {
        this.$buefy.dialog.prompt({
          message: this.$t("dataTypeAuthorizations.addComment"),
          inputAttrs: {
            placeholder: this.$t("dataTypeAuthorizations.commentExample"),
            maxlength: 255,
            minLength: 3,
            canCancel: false,
            confirmText: this.$t("dataTypeAuthorizations.grantRequestConfirm"),
          },
          trapFocus: true,
          onConfirm: (value) => (this.comment = value),
        });
      }
      /*while (this.file && this.fileNames.includes(this.file.name)) {
                const {result, dialog} = await this.$buefy.dialog.prompt({
                        message: 'Le nom est déjà utilisé',
                        inputAttrs: {
                            placeholder: 'nouveau nom',
                            pattern: '^[A-Za-z0-9-_,\\s]+[.]{1}[A-Za-z]{3}$'
                        },
                        trapFocus: true,
                        onConfirm: (value) => {
                            this.file.name = value
                        }
                    });
                dialog.openNode()
                if (result){
                    this.file = new File([this.file], result, {
                        type: this.file.type,
                        lastModified: this.file.lastModified,
                    });
                }
            }*/
      await this.additionalFileService.saveAdditionalFile(
        this.additionalFileId === "new" ? "" : this.additionalFileId,
        this.additionalFileName,
        this.applicationName,
        this.additionalFileName,
        this.file,
        { ...this.fields },
        authorizationToSend,
        this.forApplication
      );
      if ("new" === this.additionalFileId) {
        this.alertService.toastSuccess(this.$t("alert.create-request"));
      } else {
        this.alertService.toastSuccess(this.$t("alert.modified-request"));
      }
      await this.$router.push(
        `/applications/${this.applicationName}/additionalFiles/${this.additionalFileName}`
      );
    } catch (error) {
      this.alertService.toastServerError(error);
    }
  }

  rules() {
    this.extend("extended", this.testFileAndFileName);
    return "extended";
  }

  testFileAndFileName(value) {
    this.validFile = false;
    if (!value && !this.file) {
      if (this.additionalFileId === "new") {
        return "Vous devez fournir un fichier.";
      } else {
        this.fileName = this.file.name;
        this.validFile = true;
        return true;
      }
    }
    let fileName;
    if (typeof value === "object") {
      this.fileName = value.name;
      fileName = this.fileName;
    } else if (typeof value === "string") {
      if (this.file) {
        this.file = new File([this.file], value);
      }
      fileName = value;
    } else if (this.additionalFileId === "new") {
      return "Vous devez fournir un fichier.";
    }
    if (!/([A-Z]|[a-z]|[0-9]|_|-|\.|\s)+/.test(fileName)) {
      return "Vous devez fournir un nom de fichier valide.";
    }
    if (this.fileNames.includes(fileName)) {
      return 'Il existe déjà un fichier additionnel "' + fileName + '"';
    }
    this.validFile = true;
    return true;
  }

  modifyAssociateFile(id) {
    this.$router.push(
      `/applications/${this.applicationName}/additionalFiles/${this.additionalFileName}/${id}`
    );
  }

  loadFile() {
    if (this.file) {
      if (!this.fileName) {
        this.fileName = this.file.name;
      }
    }
  }
}
</script>

<style lang="scss">
.DataTypeAuthorizationInfoView-periods-container {
  .field-body .field.has-addons {
    display: flex;
    flex-direction: column;
  }
}

.DataTypeAuthorizationInfoView-radio-field {
  height: 40px;

  &.b-radio {
    .control-label {
      display: flex;
      align-items: center;
      width: 100%;
    }
  }
}

.DataTypeAuthorizationInfoView-radio-label {
  width: 200px;
}

.collapse-content .card-content .content .CollapsibleTree-header .CollapsibleTree-buttons {
  visibility: hidden;
  display: none;
}

.leaf label {
  font-weight: lighter;
  font-style: italic;
  color: #2c3e50;
}

.folder label {
  font-weight: bolder;
  color: $dark;
}

.rows .card-content .row.label .columns .column {
  padding: 0 0 0 10px;
  border-bottom: 2px solid;
  border-color: $dark;
  margin-bottom: 12px;
}

ul li.card-content {
  background-color: rgba(0, 0, 0, 0.05);
}

a {
  color: $dark;
}
</style>
s
