<template>
  <div v-if="!option.children.length" :id="option.id" style="padding: 5px 16px">
    <b-checkbox
      :value="nodeState.checked"
      :indeterminate="nodeState.indeterminate"
      @input="handleDirectSelect($event, referenceScope.findNodeById(option.id))"
    >
    </b-checkbox>
    <a>{{ option.i18n[locale] || option.naturalKey }}</a>
  </div>
  <b-collapse v-else :open="isOpen" animation="slide" class="card">
    <template #trigger="props">
      <div :aria-expanded="props.open" class="card-header" role="button">
        <p class="card-header-title">
          <b-checkbox
            :value="nodeState.checked"
            :indeterminate="nodeState.indeterminate"
            @input="handleDirectSelect($event, referenceScope.findNodeById(option.id))"
          ></b-checkbox>
          {{ option.i18n[locale] || option.naturalKey }}
        </p>
        <a class="card-header-icon">
          <b-icon :icon="props.open ? 'chevron-up' : 'chevron-down'"></b-icon>
        </a>
      </div>
    </template>
    <div class="card-content">
      <div class="content">
        <CollapseMenuMultiselect
          v-for="child in option.children"
          :key="child.id"
          :option="child"
          :reference-scope="referenceScope"
          :datatype="datatype"
          @propagate-changes="handlePropagatedChanges"
        >
        </CollapseMenuMultiselect>
      </div>
    </div>
  </b-collapse>
</template>

<script>
import { ref, computed } from "vue";
import services from "@/composable/services";
import { ReferenceScope } from "@/model/authorization/ReferenceScope";

export default {
  name: "CollapseMenuMultiselect",
  props: {
    option: {
      type: Object,
      required: true,
    },
    datatype: {
      type: String,
      required: true,
    },
    referenceScope: {
      type: Object,
      required: false,
      validator: (value) => value instanceof ReferenceScope,
    },
  },
  emits: ["propagate-changes"],
  setup(props, { emit }) {
    const isOpen = ref(false);
    const locale = services.internationalisationService.getLocale();
    const displayText = computed(() => props.option.i18n[locale] || props.option.naturalKey);

    const nodeState = computed(() => {
      const node = props.referenceScope.findNodeById(props.option.id);
      return {
        checked: node?.checked ?? false,
        indeterminate: node?.indeterminate ?? false,
      };
    });

    const isNodeSelectable = (node) => {
      return !node.checked && node.children && node.children.length > 0;
    };

    // Simplification de findAllSelectedDescendants
    const findAllSelectedDescendants = (node) => {
      if (!isNodeSelectable(node)) return [];
      return node.children.flatMap((childNode) =>
        childNode.checked
          ? [{ path: childNode.id, remove: true }, ...findAllSelectedDescendants(childNode)]
          : findAllSelectedDescendants(childNode)
      );
    };

    const handleDirectSelect = (newState, node) => {
      const changes = newState
        ? [{ path: node.id, remove: false }, ...findAllSelectedDescendants(node)]
        : [{ path: node.id, remove: true }];

      emit("propagate-changes", changes);
    };

    const handlePropagatedChanges = (changes) => {
      const currentNode = props.referenceScope.findNodeById(props.option.id);

      const isCurrentNodeDeselected = changes.some(
        (change) => change.path === currentNode.id && change.remove
      );
      const isAnyChildDeselected = currentNode.children.some((child) =>
        changes.some((change) => change.path === child.id && change.remove)
      );

      // Cas 1: Le nœud actuel est sélectionné mais un enfant est désélectionné
      if (currentNode.checked && isAnyChildDeselected && !isCurrentNodeDeselected) {
        // Désélectionner le nœud actuel et sélectionner tous les enfants non désélectionnés
        changes = [
          { path: currentNode.id, remove: true },
          ...currentNode.children
            .filter((child) => !changes.some((change) => change.path === child.id && change.remove))
            .map((child) => ({ path: child.id, remove: false })),
        ];
      }
      // Cas 2: Le nœud actuel n'est pas sélectionné mais tous les enfants sont sélectionnés
      else if (!currentNode.checked && !isCurrentNodeDeselected) {
        const allChildrenSelected = currentNode.children.every(
          (child) =>
            child.checked || changes.some((change) => change.path === child.id && !change.remove)
        );

        if (allChildrenSelected) {
          changes = [{ path: currentNode.id, remove: false }];
        }
      }
      // Cas 3: Autres cas - filtrer les changements pour ce nœud et ses descendants
      else {
        changes = changes.filter(
          (change) =>
            change.path === currentNode.id ||
            currentNode.children.some((child) =>
              props.referenceScope.isDescendantOf(change.path, child.id)
            )
        );
      }

      emit("propagate-changes", changes);
    };

    return {
      isOpen,
      handleDirectSelect,
      handlePropagatedChanges,
      locale,
      nodeState,
      displayText,
    };
  },
};
</script>

<style lang="scss" scoped>
a {
  color: black;
}

.card {
  box-shadow: none;

  .card-header {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: none;

    .card-header-title {
      color: $dark-slightly-transparent-8;
      margin: 0;
    }
  }

  .card-content {
    padding: 5px 0px 5px 20px;
    border-radius: 0px;

    .content {
      border-left: 0.5px solid $primary;
    }

    .collapse {
      padding: 0;

      .card-header {
        .card-header-title {
          color: $dark-slightly-transparent-6;
          margin: 0;
        }
      }
    }
  }
}
</style>
