<template>
  <div :class="`SidePanel ${leftAlign ? 'left-align' : 'right-align'} ${innerOpen ? 'open' : ''}`">
    <h1 class="title main-title">{{ title }}</h1>
    <b-button class="SidePanel-close-button" icon-left="times" @click="innerOpen = false" />
    <slot></slot>
  </div>
</template>

<script>
import { onMounted, watch } from "vue";
import useBoolean from "@/composable/components/boolean";

export default {
  name: "SidePanel",
  props: {
    leftAlign: {
      type: Boolean,
      default: false,
    },
    open: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    closeCb: Function,
  },

  setup(props) {
    const { refBoolean: innerOpen, doChangeBoolean: changeInnerOpen } = useBoolean();

    watch(
      () => props.open,
      (newVal) => {
        changeInnerOpen(newVal);
      }
    );
    watch(innerOpen, (newVal) => {
      props.closeCb(newVal);
    });

    onMounted(created);
    function created() {
      changeInnerOpen(props.open);
    }

    return {
      innerOpen,
    };
  },
};
</script>

<style lang="scss" scoped>
.SidePanel {
  background-color: white;
  z-index: 1;
  position: fixed;
  height: 100%;
  top: 0;
  width: 33%;
  padding: 2rem;
  transition: transform 250ms;

  .title {
    color: $dark;
  }

  &.right-align {
    right: 0;
    transform: translateX(100%);

    &.open {
      transform: translateX(0);
    }
  }

  &.left-align {
    left: 0;
    transform: translateX(-100%);

    &.open {
      transform: translateX(0);
    }
  }
}

.SidePanel-close-button {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
