export class DownloadDatasetQuery {
  offset = 0;
  limit = 10;
  rowIds; //Array search by ids
  naturalKey; //Array search by naturalKey or hierarchicalKey
  componentSelects = [];
  componentFilters = [];
  componentOrderBy = [];
  authorizationDescriptions = [];
  constructor(offset = 0, limit = 10) {
    this.offset = offset;
    this.limit = limit;
  }
}
