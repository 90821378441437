<template>
  <div>
    <!-- section pour visualisation une Dynamique colonne-->
    <span v-if="info && showBtnTableDynamicColumn(infoValues)">
      <b-button
        icon-left="eye"
        rounded
        size="is-small"
        style="height: inherit"
        type="is-dark"
        @click="showModal()"
      >
        <LoadingAnimate v-if="isLoading" :size="'is-small'"></LoadingAnimate>
      </b-button>
    </span>
    <!-- modal de multiplicity et dynamique colonne -->
    <b-modal v-model="isCardModalActive" class="modalCardRef" width="70%">
      <div class="card">
        <div class="card-header">
          <div class="title card-header-title">
            <p field="name" style="font-size: 1.5rem">
              {{ columnTitle.label ? columnTitle.label : columnTitle }}
            </p>
          </div>
        </div>
        <div class="card-content">
          <div v-for="key in modalArrayObj" :key="key.id" class="columns modalArrayObj">
            <DatasLink
              v-if="key.columnName !== null"
              :application="application"
              :column-id="key.column"
              :display-value="key.columnName"
              :loaded-references-by-key="{}"
              :reference-type="referenceType"
              :value="key.column"
            ></DatasLink>
            <DatasManyLink
              v-else-if="Array.isArray(key.column)"
              :application="application"
              :column-id="key.columnName"
              :info-values="key.column"
              :loaded-references-by-key="{}"
              :multiplicity="true"
              :reference-type="referenceType"
            ></DatasManyLink>
            <div v-else>
              <p class="column">{{ key.column }} {{ $t("ponctuation.colon") }}</p>
            </div>

            <p v-if="key.value" class="column">
              {{ key.value }}
            </p>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import DatasLink from "@/components/datas/DatasLink.vue";
import DatasManyLink from "@/components/datas/DatasManyLink.vue";
import LoadingAnimate from "@/components/common/LoadingAnimate.vue";
import services from "@/composable/services";
import useObject from "@/composable/components/object";
import { computed, inject } from "vue";
import useBoolean from "@/composable/components/boolean";
import app from "@/main";

export default {
  name: "DatasDynamicLink",
  emits: ["changedRefValues"],
  components: { LoadingAnimate, DatasManyLink, DatasLink },
  props: {
    application: Object,
    referenceType: String,
    value: String,
    infoValues: {},
    referenceValue: {},
    loadedReferencesByKey: {
      type: Object,
    },
    info: Boolean,
    columnId: String,
  },
  beforeCreate() {
    this.$options.components.DatasLink = require("./DatasLink.vue").default;
    this.$options.components.DatasManyLink = require("./DatasManyLink.vue").default;
  },
  setup(props) {
    const { getOrLoadDataByNaturalKey } = inject("reference:dataLoader");
    const { reactiveObject: refValues } = useObject({ active: false });
    const isCardModalActive = useBoolean().refBoolean;
    const isLoading = useBoolean().refBoolean;
    const { reactiveObject: modalArrayObj } = useObject();
    const applicationName = computed(() => props.application.name);
    const dataLoader = inject("reference:dataLoader");
    const columnTitle = computed(() => {
      let displayRef = services.internationalisationService.localeReferenceColumnsNamesForReference(
        { label: props.referenceType },
        props.application
      );
      if (
        props.application.internationalization?.data[props.referenceType]?.i18n[
          window.localStorage.lang
        ]
      ) {
        displayRef.label =
          props.application.internationalization.data[props.referenceType].i18n[
            window.localStorage.lang
          ];
      }
      return displayRef;
    });

    function showBtnTableDynamicColumn(tableDynamicColumn) {
      let showModal = Object.entries(tableDynamicColumn)
        .filter((a) => a[1])
        .map(function (a) {
          let obj = {};
          obj[a[0]] = a[1];
          return obj;
        });
      return showModal.length !== 0;
    }
    async function showModal() {
      isLoading.value = true;
      let columnName;
      let modalRefArrayObj = Object.entries(props.infoValues)
        .filter((a) => a[1])
        .map(function (a) {
          let obj = {};
          obj[a[0]] = a[1];
          return obj;
        });
      const reference = await services.dataService.getData(
        applicationName.value,
        props.referenceType
      );
      for (let i = 0; i < reference.rows.length; i++) {
        columnName = dataLoader.getValueDisplay(reference.rows[i], props.application);
        for (let j = 0; j < modalRefArrayObj.length; j++) {
          if (Object.keys(modalRefArrayObj[j])[0] === reference.rows[i].naturalKey) {
            let column = Object.keys(modalRefArrayObj[j])[0];
            let value = Object.values(modalRefArrayObj[j])[0];
            modalRefArrayObj[j] = {
              ...modalRefArrayObj[j],
              column: column,
              value: value,
              columnName: columnName,
            };
            modalArrayObj[j] = modalRefArrayObj[j];
          }
        }
      }
      app.$emit("changedRefValues", {
        key: props.value,
        refType: props.referenceType,
        // hierarchicalKey,
        modalArrayObj: modalArrayObj.value,
      });
      isCardModalActive.value = true;
      isLoading.value = false;
      return modalArrayObj.value;
    }
    return {
      refValues,
      isCardModalActive,
      isLoading,
      modalArrayObj,
      columnTitle,
      showBtnTableDynamicColumn,
      showModal,
      getOrLoadDataByNaturalKey,
    };
  },
};
</script>

<style scoped></style>
