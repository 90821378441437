<template>
  <ModalCard
    v-show="open || url"
    :close-cb="closeCb"
    :open="open || !!url"
    :title="application && (application.localName || application.name)"
  >
    <div v-if="getUrl" id="charteValidate" class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">
          {{ $t("applications.charte_header", application) }}
        </p>
      </header>
      <div class="modal-card-body">
        <CharteValidator :href="getUrl" />
      </div>
      <footer class="modal-card-foot">
        <form id="validateForm">
          <b-checkbox v-model="validated" class="validatesCheckbox" required type="primary">
            {{ $t("applications.charte_message") }}
          </b-checkbox>
        </form>
      </footer>
    </div>
  </ModalCard>
</template>

<script>
import ModalCard from "@/components/charts/ModalCard";
import { useRedirections } from "@/composable/applications/useFunction";
import useText from "@/composable/components/text";
import CharteValidator from "@/components/application/CharteValidator.vue";
import { computed } from "vue";
import services from "@/composable/services";
import { User } from "@/model/User";

export default {
  name: "DetailApplicationModalCard",
  components: { CharteValidator, ModalCard },
  emits: ["setValidatedCharte"],
  props: {
    open: {
      type: Boolean,
      required: true,
      default: false,
    },
    application: {
      type: Object,
      required: true,
    },
    closeCb: {
      type: Function,
      required: false,
      default: () => {
        this.charteHREF.value = null;
      },
    },
    url: {
      required: true,
      type: String,
    },
    redirection: {
      required: true,
      type: Function,
    },
  },
  setup(props, ctx) {
    const charteHREF = useText().refText;
    const getUrl = computed({
      get() {
        return charteHREF.value ? charteHREF.value : props.url;
      },
    });
    const validated = computed({
      get() {
        let charteTimeStamp = User.STORED_AUTHENTICATED_USER()?.chartes?.[props.application.id]
        if (charteTimeStamp) {
          return (
            new Date().getTime() > charteTimeStamp
          );
        }
        return false;
      },
      set(bool) {
        let storedAuthenticatedUser = User.STORED_AUTHENTICATED_USER();
        bool &&
          services.loginService.modifAcount({
            login: storedAuthenticatedUser?.login,
            email: storedAuthenticatedUser?.email,
            charte: props.application.id,
          });
        bool &&
          ctx.emit("setValidatedCharte", {
            applicationId: props.application.id,
            validated: bool,
          });
        return bool && props.redirection();
      },
    });
    const { tmpCharte } = useRedirections(props.application);

    return {
      charteHREF,
      getUrl,
      tmpCharte,
      validated,
    };
  },
};
</script>

<style lang="scss" scoped>
.infos {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-content: space-between;
}

.comment {
  //float: right;
}

.modal-card {
  width: auto;
  height: auto;
}

.card {
  width: 100%;
  padding: 2em;
}

div.buttonPanel {
  display: flex;
  flex: fit-content;
  flex-direction: row;
  align-content: space-evenly;
  justify-content: space-evenly;
}

div.buttonRubriquePanel {
  flex-wrap: nowrap;
  display: flex;
  flex-direction: column;
  align-content: start;
  justify-content: start;
}

div.buttonRubriquePanel .rubriqueTitle {
  width: 100%;
  text-align: center;
  font-size: 150%;
}

div.buttonWarper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: space-between;
  justify-content: space-between;
  padding: 1em;
}

div.buttonWarper button {
  align-content: center;
  width: 100%;
  margin-inline: 0.5em;
}

.animation-content.modal-content {
  max-width: 1400px;
}

.validatesCheckbox {
  padding: 5px;
  color: #007f7f;
  font-size: 2rem;
}

.validatesCheckbox:hover {
  background-color: white;
}

.card-header-title {
  color: white;
  font-size: 200%;
  padding: 1em;
}
</style>
