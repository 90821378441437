<template>
  <PageView>
    <h1 class="title main-title">
      {{
        showOwnApplications ? $t("titles.applications-page") : $t("titles.applications-page-other")
      }}
    </h1>

    <div class="columns columnPrincipale">
      <div class="column is-3-widescreen is-12-desktop">
        <section>
          <div v-if="canCreateApplication" class="card is-clickable">
            <div
              class="card-header createApplication"
              role="button"
              style="margin-bottom: 50px"
              tabindex="0"
              @click="createApplication"
            >
              <a class="card-header-icon createApplication">
                <b-icon icon="plus"></b-icon>
              </a>
              <p class="card-header-title createApplication">
                {{ $t("applications.create") }}
              </p>
            </div>
          </div>
          <div class="card">
            <div class="card-header">
              <p class="card-header-title">
                {{ $t("applications.trier") }}
              </p>
            </div>
            <div class="card-content">
              <div class="content">
                <b-field class="columns">
                  <b-checkbox
                    v-model="checkboxDate"
                    class="column"
                    false-value="false"
                    field="name"
                    true-value="true"
                    @input="alphabeticalSort = ''"
                    >{{ $t("applications.trierRecent") }}
                  </b-checkbox>
                </b-field>
              </div>
              <div class="content">
                <b-field class="columns">
                  <b-radio
                    v-model="alphabeticalSort"
                    name="alphabeticalSort"
                    native-value="Az"
                    @input="checkboxDate = false"
                  >
                    {{ $t("applications.trierA_z") }}
                  </b-radio>
                  <b-radio
                    v-model="alphabeticalSort"
                    name="alphabeticalSort"
                    native-value="Za"
                    @input="checkboxDate = false"
                  >
                    {{ $t("applications.trierZ_a") }}
                  </b-radio>
                </b-field>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header">
              <p class="card-header-title">{{ $t("applications.filter") }}</p>
            </div>
            <div class="card-content">
              <div class="content">
                <b-field>
                  {{ $t("applications.name") }}
                  <b-autocomplete
                    v-model="filterName"
                    :data="selectedApplications"
                    field="localName"
                    placeholder="olac"
                  >
                  </b-autocomplete>
                </b-field>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div class="column is-9-widescreen is-12-desktop">
        <b-progress
          v-if="progress < 1"
          :value="progress * 100"
          show-value
          size="is-medium"
          type="is-primary"
        >
          <span v-if="progress === 0">
            <LoadingAnimate :size="'is-small'"></LoadingAnimate>
          </span>
          <span v-else> {{ progress.toFixed(2) * 100 }}% </span>
        </b-progress>
        <div class="columns">
          <ApplicationCard
            v-for="(application, index) in selectedApplications"
            :key="application.name"
            :application="application"
            :current="current"
            :index="index"
            :per-page="perPage"
          />
        </div>
        <hr />
        <b-pagination
          v-if="perPage <= applications.length"
          :aria-current-label="$t('menu.aria-curent-page')"
          :aria-label="$t('menu.aria-pagination')"
          :aria-next-label="$t('menu.aria-next-page')"
          :aria-previous-label="$t('menu.aria-previous-page')"
          :current.sync="current"
          :per-page="perPage"
          :range-after="2"
          :range-before="2"
          :rounded="true"
          :total="applications.length"
          order="is-centered"
          role="navigation"
        >
        </b-pagination>
      </div>
    </div>
  </PageView>
</template>

<script>
import PageView from "@/views/common/PageView.vue";
import LoadingAnimate from "@/components/common/LoadingAnimate.vue";
import services from "@/composable/services";
import useBoolean from "@/composable/components/boolean";
import useText from "@/composable/components/text";
import { computed, onMounted, inject, ref } from "vue";
import { useRedirections } from "@/composable/applications/useFunction";
import ApplicationCard from "@/components/application/ApplicationCard.vue";
import useNumber from "@/composable/components/number";

export default {
  name: "ApplicationsView",
  components: { ApplicationCard, LoadingAnimate, PageView },
  setup() {
    const { createApplication, updateApplication, displayAdditionalFilesManagement } =
      useRedirections();
    const applications = inject("application:applications");
    const progress = inject("application:progress");
    const loadApplications = inject("application:loadApplications");
    const checkboxDate = useBoolean().refBoolean;
    const currentUser = computed(() => services.loginService.getAuthenticatedUser());
    const canManageCreateApplication =
      currentUser.value.authorizedForApplicationCreation || currentUser.value.openAdomAdmin;
    const canCreateApplication =
      currentUser.value.authorizedForApplicationCreation && currentUser.value.authorizations.length;
    const loading = computed(() => progress.value < 1);
    const isCardModalActive = useBoolean().refBoolean;
    // show modal and cards
    const selectedName = useText().refText;
    // pagination variable
    const { refNumber: current } = useNumber(1);
    const perPage = 9;
    // filtre variable
    const filterName = useText().refText;
    const selected = useBoolean().refBoolean;
    const alphabeticalSort = useText().refText;
    const selectedApplications = computed(() => {
      let sa = applications && applications.value;
      if (filterName.value.length > 2)
        sa = sa.filter(
          (a) => a.localName.toString().toLowerCase().indexOf(filterName.value.toLowerCase()) >= 0
        );
      if (checkboxDate.value === "true") sa = sa.sort((a, b) => b.creationDate - a.creationDate);
      else sa = sa.sort((a, b) => b.creationDate - a.creationDate).reverse();
      if (alphabeticalSort.value === "Az") {
        sa = sa.sort((a, b) => a.localName.localeCompare(b.localName));
      } else if (alphabeticalSort.value === "Za") {
        sa = sa.sort((a, b) => a.localName.localeCompare(b.localName)).reverse();
      }
      return sa;
    });

    onMounted(async () => {
      loadApplications(["DATATYPE", "REFERENCETYPE", "CONFIGURATION", "ADDITIONALFILE"]);
    });

    const ownApplications = computed(() => {
      return applications.value.filter((application) => application.isApplicationUser());
    });

    const otherApplications = computed(() => {
      return applications.value.filter((application) => !application.isApplicationUser());
    });
    const hasOwnApplications = computed(() => {
      return ownApplications?.value.length;
    });

    const hasOtherApplications = computed(() => {
      return otherApplications?.value.length;
    });

    const showOwnApplications = ref(hasOwnApplications);

    return {
      hasOwnApplications,
      hasOtherApplications,
      ownApplications,
      otherApplications,
      showOwnApplications,
      applications,
      progress,
      loadApplications,
      createApplication,
      updateApplication,
      canManageCreateApplication,
      displayAdditionalFilesManagement,
      current,
      perPage,
      filterName,
      selected,
      selectedApplications,
      currentUser,
      loading,
      selectedName,
      checkboxDate,
      alphabeticalSort,
      isCardModalActive,
      canCreateApplication,
    };
  },
};
</script>

<style lang="scss" scoped>
// card & modal style
.columns {
  flex-wrap: wrap;
  margin: 0;

  &.columnPrincipale {
    margin-left: 50px;
    margin-top: 50px;
  }
}

.column {
  display: grid;

  .comment {
    display: flex;
    align-items: center;
    align-content: start;
  }

  .card {
    &.applicationCard {
      width: 300px;

      .card-footer {
        border: none;

        .card-footer-item {
          padding-right: 0;

          .button {
            padding-right: 10px;
            padding-left: 10px;
          }
        }
      }
    }

    .btnModal {
      margin: 5px;
      opacity: 50%;
      color: $primary;
      background-color: transparent;
    }

    .card-footer-item {
      border-right: none;
      padding: 0.5rem;
    }
  }
}

.createApplication {
  background-color: $dark;
  color: white;
}

.card-header-title {
  &.title {
    margin-top: 0;
    text-transform: uppercase;
    margin-bottom: 0;
  }
}

.control.has-icons-left .icon,
.control.has-icons-right .icon {
  top: 5px;
  left: 5px;
}

div.PageView-container {
  width: max-content;
}
</style>
