<template>
  <div>
    <span v-if="info && showBtnTablePatternColumn(infoValues)">
      <b-button
        size="is-small"
        type="is-transparent"
        style="height: 15px; width: fit-content; border: none; display: inline"
        @click="showModal()"
      >
        <span style="font-size: large; font-weight: bold; line-height: 0%">{{ columnName }}</span>
        <b-icon icon="eye" size="is-small" style="height: inherit" type="is-dark"></b-icon>
        <LoadingAnimate v-if="isLoading" :size="'is-small'"></LoadingAnimate>
      </b-button>
    </span>
    <!-- modal de multiplicity et dynamique colonne -->
    <b-modal
      v-model="isCardModalActive"
      class="modalCardRef"
      width="70%"
      body-class="modal-high-z-index"
    >
      <div class="card">
        <div class="card-header">
          <div class="title card-header-title">
            <p field="name" style="font-size: 1.5rem">
              {{ columnTitle.match(".*::(.*)")[1] }}
            </p>
          </div>
        </div>
        <div class="card-content">
          <b-table
            id="patternFilterTable"
            :data="[0]"
            :is-focusable="true"
            :is-hoverable="true"
            :sticky-header="true"
            striped
            style="padding-bottom: 20px; position: relative; z-index: 1"
          >
            <b-table-column v-for="column in value" :key="column.id" :field="column.id" sortable>
              <template v-slot:header>
                <div style="padding: 0">
                  {{ column.columnName }}
                </div>
              </template>
              <template>
                <DatasLink
                  :application="application"
                  :column-id="column.component.id"
                  :column-title="column.component.title"
                  :component="column.component"
                  :display-value="'' + column.value"
                  :loaded-references-by-key="{}"
                  :pattern-checker-date="
                    patternCheckerDateRef(
                      application,
                      column.component.parentComponentKey + '::' + column.component.id,
                      dataId
                    )
                  "
                  :reference-type="column.component.refLinkedTo"
                  :value="'' + column.value"
                ></DatasLink>
              </template>
            </b-table-column>
          </b-table>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import useBoolean from "@/composable/components/boolean";
import DatasLink from "@/components/datas/DatasLink.vue";
import { computed } from "vue";

export default {
  name: "DatasPatternLink",
  components: { /*LoadingAnimate, DatasManyLink, */ DatasLink },
  props: {
    application: Object,
    columnTitle: String,
    value: Array,
    infoValues: {},
    loadedReferencesByKey: {
      type: Object,
    },
    dataId: String,
    info: Boolean,
    columnId: String,
    patternCheckerDateRef: Function,
  },
  beforeCreate() {
    this.$options.components.DatasLink = require("./DatasLink.vue").default;
    //this.$options.components.DatasManyLink = require("./DatasManyLink.vue").default;
  },
  setup(props) {
    const isCardModalActive = useBoolean().refBoolean;
    const isLoading = useBoolean().refBoolean;

    function showBtnTablePatternColumn(tableDynamicColumn) {
      let showModal = Object.entries(tableDynamicColumn)
        .filter((a) => a[1])
        .map(function (a) {
          let obj = {};
          obj[a[0]] = a[1];
          return obj;
        });
      return showModal.length !== 0;
    }
    const columnName = computed(() => props.columnTitle.match(".*::(.*)")[1]);

    async function showModal() {
      isLoading.value = true;
      isCardModalActive.value = true;
      isLoading.value = false;
      return props.value;
    }

    return {
      columnName,
      isLoading,
      isCardModalActive,
      showBtnTablePatternColumn,
      showModal,
    };
  },
};
</script>
.modal-high-z-index { z-index: 9999 !important; position: absolute; }
<style scoped></style>
