export class Application {
  id;
  localName = "toto";
  creationDate;
  updateDate;
  name;
  comment;
  version;
  references = [];
  dataTypes = [];
  data = [];
  additionalFile = [];
  configuration = {};
  configFile;
  currentApplicationUserRolesResult = {};
  hasSignedCharte = false;
  hasSignedLastCharte = false;

  static of(result) {
    const application = new Application(result.application);
    application.currentApplicationUserRolesResult = result.currentApplicationUserRolesResult;
    application.hasSignedCharte = result.hasSignedCharte;
    application.hasSignedLastCharte = result.hasSignedLastCharte;
    return application;
  }

  isApplicationUser() {
    return (
      this.currentApplicationUserRolesResult?.applicationRoles?.includes("applicationManager") ||
      this.currentApplicationUserRolesResult?.applicationRoles?.includes("userManager") ||
      this.hasSignedCharte
    );
  }

  mustSignCharte() {
    return !(
      this.currentApplicationUserRolesResult?.applicationRoles?.includes("applicationManager") ||
      this.currentApplicationUserRolesResult?.applicationRoles?.includes("userManager") ||
      this.hasSignedLastCharte
    );
  }

  constructor(application) {
    if (!application) {
      return;
    }
    this.id = application.id;
    this.creationDate = application.creationDate;
    this.updateDate = application.updateDate;
    this.name = application.name;
    this.comment = application.comment;
    this.version = application.version;
    this.references = [];
    this.dataTypes = [];
    this.data = [];
    this.additionalFile = application.additionalFiles;
    this.configuration = application.configuration;
    this.configFile = application.configFile;

    const configuration = application.configuration;
    for (const dataName in configuration?.dataDescription || {}) {
      const data = configuration.dataDescription[dataName];
      if (data.tags && data.tags.find((tag) => tag.tagDefinition == "DATA_TAG")) {
        this.dataTypes.push(dataName);
      } else {
        this.references.push(dataName);
      }
      this.data.push(dataName);
    }
  }
}
