<script>
import useText from "@/composable/components/text";
import useBoolean from "@/composable/components/boolean";
import { useRedirections } from "@/composable/applications/useFunction";
import { formatedDateArray } from "@/composable/application/DatePattern";
import { onMounted, ref } from "vue";
import app from "@/main";
import DetailApplicationModalCard from "@/components/application/DetailApplicationModalCard.vue";

export default {
  components: { DetailApplicationModalCard },
  methods: { formatedDateArray },
  name: "ApplicationCard",
  props: {
    application: {
      type: Object,
      required: true,
    },
    index: {
      required: true,
      type: Number,
    },
    current: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const selectedName = useText().refText;
    const isCardModalActive = useBoolean().refBoolean;
    const {
      displayAdditionalFilesManagement,
      displayDataSetManagement,
      displayReferencesManagement,
    } = useRedirections(props.application);
    const { landingPageApplication } = useRedirections(props.application);

    const localLang = localStorage.getItem("lang");
    const url = useText().refText;
    const redirection = ref(() => {
      app.$router.push(`/applications/${props.application.name}`);
    });
    const showCharte = function (objectShowCharte) {
      if (!objectShowCharte) {
        return;
      } else {
        isCardModalActive.value = true;
        url.value = objectShowCharte.url;
        redirection.value = objectShowCharte.redirection;
      }
    };
    onMounted(() => {
      //console.log(props.application);
    });
    return {
      selectedName,
      isCardModalActive,
      localLang,
      showCharte,
      url,
      redirection,
      displayDataSetManagement,
      displayReferencesManagement,
      displayAdditionalFilesManagement,
      landingPageApplication,
    };
  },
};
</script>
<template>
  <div
    style="margin-left: 30px"
    v-if="index >= (current - 1) * perPage && index < current * perPage"
  >
    <b-modal
      v-if="isCardModalActive"
      v-model="isCardModalActive"
      class="modalByAgrégation"
      full-screen
    >
      <DetailApplicationModalCard
        :id="application.name"
        v-model="isCardModalActive"
        :application="application"
        :open="isCardModalActive"
        :url="url"
        :redirection="redirection"
        :close-cb="redirection"
      >
      </DetailApplicationModalCard>
    </b-modal>
    <div class="column is-3-widescreen is-6-desktop is-12-tablet">
      <div class="applicationCard card" style="padding-bottom: 10px">
        <div class="card-header">
          <div class="title card-header-title">
            <p field="name" style="font-size: 1.5rem">{{ application.localName }}</p>
          </div>
          <b-button
            class="btnModal"
            icon-left="home"
            size="is-medium"
            type="is-primary"
            @click="showCharte(landingPageApplication(application))"
          />
        </div>
        <div class="card-content">
          <div class="content">
            <p field="creationDate" v-if="application.updateDate || application.creationDate">
              {{
                application.updateDate
                  ? formatedDateArray(application.updateDate)
                  : formatedDateArray(application.creationDate)
              }}
            </p>
          </div>
        </div>
        <div class="card-footer">
          <div class="card-footer-item">
            <b-button
              v-if="application.references.length !== 0"
              :disabled="!application.isApplicationUser()"
              icon-left="drafting-compass"
              @click="showCharte(displayReferencesManagement(application))"
            >
              {{ $t("applications.references") }}
            </b-button>
          </div>
          <div class="card-footer-item">
            <b-button
              v-if="application.dataTypes.length !== 0"
              :disabled="!application.isApplicationUser()"
              icon-left="poll"
              @click="showCharte(displayDataSetManagement(application))"
            >
              {{ $t("applications.dataset") }}
            </b-button>
          </div>
        </div>
        <div class="card-footer">
          <div
            v-if="application.additionalFile && application.additionalFile.length !== 0"
            :disabled="!application.isApplicationUser()"
            class="card-footer-item"
          >
            <b-button
              disabled
              icon-left="file"
              @click="showCharte(displayAdditionalFilesManagement(application))"
            >
              {{ $t("applications.additionalFile") }}
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
// card & modal style
.columns {
  flex-wrap: wrap;
  margin: 0;

  &.columnPrincipale {
    margin-left: 50px;
    margin-top: 50px;
  }
}

.column {
  display: grid;

  .comment {
    display: flex;
    align-items: center;
    align-content: start;
  }

  .card {
    &.applicationCard {
      width: 300px;

      .card-footer {
        border: none;

        .card-footer-item {
          padding-right: 0;

          .button {
            padding-right: 10px;
            padding-left: 10px;
          }
        }
      }
    }

    .btnModal {
      margin: 5px;
      opacity: 50%;
      color: $primary;
      background-color: transparent;
    }

    .card-footer-item {
      border-right: none;
      padding: 0.5rem;
    }
  }
}

.createApplication {
  background-color: $dark;
  color: white;
}

.card-header-title {
  &.title {
    margin-top: 0;
    text-transform: uppercase;
    margin-bottom: 0;
  }
}

.control.has-icons-left .icon,
.control.has-icons-right .icon {
  top: 5px;
  left: 5px;
}

div.PageView-container {
  width: max-content;
}
</style>
