import services from "@/composable/services";
import useArray from "@/composable/components/array";
import { Application } from "@/model/application/Application";
import useNumber from "@/composable/components/number";

const { shallowRefArray: applications } = useArray();
const { refNumber: progress } = useNumber(0);

function addApplication(result) {
  if (result.type === "REACTIVE_PROGRESS") {
    progress.value = result.result;
  } else if (result.type === "REACTIVE_RESULT") {
    let application = Application.of(result.result);
    const authenticatedUser = services.loginService.getAuthenticatedUser();
    authenticatedUser.invalidateCharte(application.id);
    application = services.internationalisationService.mergeInternationalization(application);
    let id = applications.value
      .map((a, i) => (a.name === application.name ? i : -1))
      .find((i) => i >= 0);
    let localApplication = applications.value;
    console.log("localApplication", localApplication);
    if (id >= 0) {
      localApplication[id] = application;
    } else {
      localApplication.push(application);
    }
    application.value = localApplication;
  }
}

function loadApplications(filter) {
  services.applicationService.getApplications(addApplication, filter);
}

function useApplication() {
  return {
    progress,
    applications,
    loadApplications,
  };
}

export default useApplication;
