<template>
  <caption class="columns" style="margin-top: -0.4em">
    <div class="column loader-wrapper">
      <b-icon custom-class="fa-spin" icon="spinner" pack="fas" :size="size"> </b-icon>
    </div>
  </caption>
</template>

<script>
export default {
  name: "LoadingAnimate",
  emits: ["changedStatusLoading"],
  props: {
    size: String,
  },
};
</script>

<style scoped>
caption {
  color: rgb(0, 0, 0);
}
</style>
