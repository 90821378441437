import { Fetcher, LOCAL_STORAGE_LANG } from "./Fetcher";
import { Node } from "@/model/application/Node";
import { ApplicationResult } from "@/model/ApplicationResult";

export class InternationalisationService extends Fetcher {
  static INSTANCE = new InternationalisationService();

  getApplicationPath() {
    return `application.internationalization`;
  }

  getDataTypeDisplay(reference, localThis) {
    localThis = localThis || this;
    return `dataTypes.${localThis.dataTypeId}.internationalizationDisplay.${reference}.pattern`;
  }

  getDataTypeName(localThis) {
    localThis = localThis || this;
    return `dataTypes.${localThis.dataTypeId}.internationalizationName`;
  }

  // getDataTypeColumns(column) {
  //     return `dataTypes.${this.dataTypeId}.internationalizationColumns.${column}.pattern`;
  // }
  getReferenceDisplay(localThis) {
    localThis = localThis || this;
    return `references.${localThis.refId}.internationalizationDisplay.pattern`;
  }

  getReferenceName(localThis) {
    localThis = localThis || this;
    return `references.${localThis.refId}.internationalizationName`;
  }

  getReferenceeColumns(column, localThis) {
    localThis = localThis || this;
    return `references.${localThis.refId}.internationalizationColumns.${column}`;
  }

  getAuthorizationScopePath(authorizationScopes, localThis) {
    localThis = localThis || this;
    return `data.${localThis.dataId}.authorization.authorizationScopes.${authorizationScopes}`;
  }

  getSubmissionReferenceScopePath(authorizationScopes, localThis) {
    localThis = localThis || this;
    return `data.${localThis.dataId}.submissions.referenceScopes.${authorizationScopes}`;
  }

  getDataGroupsPath(dataGroups, localThis) {
    localThis = localThis || this;
    return `dataTypes.${localThis.dataTypeId}.authorization.dataGroups.${dataGroups}.internationalizationName`;
  }

  getLocaleforPath(application, path, defaultValue, forDescription) {
    if (!path || !path.length) {
      return defaultValue;
    }
    if (application.internationalization) {
      /* TODO application.internationalization ne se remplis pas comme il faut pour submission car referenceScopes renvois
          {
            2: {fr:"bonjour je suis un site", en:" hello i'm site"},
            3: {fr:"bonjour je suis un projet", en:" hello i'm project"}
          }
          à la place de 2 et 3 on veux le nom des references (ex: projet et sites)
          {
            projet: {fr:"bonjour je suis un site", en:" hello i'm site"},
            sites: {fr:"bonjour je suis un projet", en:" hello i'm project"}
          }
      */
      let navigateConfiguration = application.internationalization;
      let pathArray = path.split(".");
      let pathItem = pathArray.shift();
      while (pathItem) {
        navigateConfiguration = navigateConfiguration[pathItem];
        if (!navigateConfiguration) {
          return defaultValue;
        }
        pathItem = pathArray.shift();
      }
      return (
        (navigateConfiguration.internationalisationName ||
          (forDescription ? navigateConfiguration.description : navigateConfiguration.title) ||
          navigateConfiguration)[localStorage.getItem(LOCAL_STORAGE_LANG)] ||
        (forDescription ? null : defaultValue)
      );
    }
    return defaultValue;
  }

  getLocale() {
    return localStorage.getItem(LOCAL_STORAGE_LANG);
  }

  mergeInternationalization(application) {
    let internationalization;

    if (application?.configuration?.i18n) internationalization = application?.configuration?.i18n;
    else if (application?.i18n) internationalization = application?.i18n;

    if (!internationalization) {
      application.localName = application.name;
      return application;
    }
    application.localName = this.localeApplicationNameTitle(
      internationalization?.application,
      application.name
    );
    application.localDescription = this.localeApplicationNameDescription(
      internationalization?.application,
      application.name
    );
    return new ApplicationResult(application);
  }

  localeApplicationNameTitle(applicationInternationalization, defautName) {
    return (
      applicationInternationalization?.title?.[localStorage.getItem(LOCAL_STORAGE_LANG)] ??
      defautName
    );
  }

  localeApplicationNameDescription(applicationInternationalization) {
    return applicationInternationalization?.description?.[localStorage.getItem(LOCAL_STORAGE_LANG)];
  }

  localeDataTypeIdName(application, datatype, forDescription) {
    if (application?.configuration?.i18n?.data?.[datatype.id]?.internationalizationName != null) {
      return application.internationalization.dataTypes[datatype.id].internationalizationName?.[
        forDescription ? "description" : "title"
      ]?.[localStorage.getItem(LOCAL_STORAGE_LANG)];
    } else {
      return datatype.name;
    }
  }

  localeAdditionalFilesName(application, forDescription) {
    if (application.internationalization != null) {
      let additionalFiles = application.internationalization.additionalFiles;
      for (let additionalFilesKey in additionalFiles) {
        let lang = localStorage.getItem(LOCAL_STORAGE_LANG);
        let defaultValue = forDescription ? null : additionalFilesKey;
        application.additionalFiles[additionalFilesKey] = {
          ...application.additionalFiles[additionalFilesKey],
          localName:
            additionalFiles[additionalFilesKey].internationalizationName?.[
              forDescription ? "description" : "title"
            ]?.[lang] || defaultValue,
        };
      }
    } else {
      let additionalFiles = application.additionalFiles;
      for (let additionalFilesKey in additionalFiles) {
        application.additionalFiles[additionalFilesKey] = {
          ...application.additionalFiles[additionalFilesKey],
          localName: application.additionalFiles[additionalFilesKey].name,
        };
      }
    }
    return application.additionalFiles;
  }

  localeReferenceNames(referenceName, application, forDescription) {
    if (application.internationalization) {
      let applicationReferences = application.internationalization.data;
      if (referenceName) {
        let defaultValue = forDescription ? null : referenceName;
        return (
          applicationReferences?.[referenceName]?.i18n?.[
            forDescription ? "description" : "title"
          ]?.[localStorage.getItem(LOCAL_STORAGE_LANG)] ?? defaultValue
        );
      }
    }
  }

  localeReferenceColumnsNamesForReference(referenceColumnName, application, forDescription) {
    if (application.internationalization) {
      let applicationReferences = application.internationalization.data;
      if (referenceColumnName) {
        for (let applicationReference in applicationReferences) {
          if (applicationReference === referenceColumnName) {
            let defaultValue = forDescription ? null : referenceColumnName;
            referenceColumnName++;
            return (
              applicationReferences[applicationReference].i18n?.[
                forDescription ? "description" : "title"
              ]?.[localStorage.getItem(LOCAL_STORAGE_LANG)] ?? defaultValue
            );
          }
        }
        return referenceColumnName;
      }
    }
    return referenceColumnName;
  }

  localeReferenceColumnsNames(referenceName, columnName, application, forDescription) {
    let localColumnName;
    const exportHeader =
      application?.internationalization?.data?.[referenceName]?.components?.[columnName]
        ?.exportHeader;
    if (exportHeader) {
      let defaultValue = forDescription ? null : columnName;
      columnName++;
      localColumnName =
        exportHeader?.[forDescription ? "description" : "title"]?.[
          localStorage.getItem(LOCAL_STORAGE_LANG)
        ] ?? defaultValue;
    } else {
      localColumnName = columnName;
    }
    return localColumnName;
  }

  additionalFilesNames(refs, forDescription) {
    if (refs.internationalization) {
      let additionalFiles = refs.internationalization.additionalFiles;
      for (let additionalFilesKey in additionalFiles) {
        let fields = additionalFiles[additionalFilesKey].fields;
        let localFields = {};
        for (const formatKey in fields) {
          localFields[formatKey] =
            fields[formatKey]?.[forDescription ? "description" : "title"]?.[
              localStorage.getItem(LOCAL_STORAGE_LANG)
            ];
        }
        refs.additionalFiles[additionalFilesKey] = {
          ...refs.additionalFiles[additionalFilesKey],
          localFields,
          refNameLocal:
            additionalFiles[additionalFilesKey]?.[forDescription ? "description" : "title"]?.[
              localStorage.getItem(LOCAL_STORAGE_LANG)
            ],
          name: additionalFilesKey,
        };
      }
    } else {
      let additionalFiles = refs.additionalFiles;
      for (let additionalFilesKey in additionalFiles) {
        refs.additionalFiles[additionalFilesKey] = {
          ...refs.additionalFiles[additionalFilesKey],
          refNameLocal: refs.additionalFiles[additionalFilesKey].name,
          name: refs.additionalFiles[additionalFilesKey].name,
        };
      }
    }
    return refs.additionalFiles;
  }

  treeReferenceName(application) {
    if (application.references) {
      for (let referenceName in application.references) {
        application.references[referenceName] = new Node(
          application.references[referenceName],
          (name) => this.localeDataNames(name, application),
          (name) => this.localeDataDescription(name, application),
          (name) => application?.configuration?.dataDescription?.[name]?.tags || [],
          (tagName) => this.getLocaleforPath(application, "tags." + tagName, tagName)
        );
      }
    }
    return application.references;
  }

  treeDataTypesName(application) {
    if (application.dataTypes) {
      for (let referenceName in application.dataTypes) {
        application.dataTypes[referenceName] = new Node(
          application.dataTypes[referenceName],
          (name) => this.localeDataNames(name, application),
          (name) => this.localeDataDescription(name, application),
          (name) => application?.configuration?.dataDescription?.[name]?.tags || [],
          (tagName) => this.getLocaleforPath(application, "tags." + tagName, tagName)
        );
      }
    }
    return application.dataTypes;
  }

  localeDataNames(dataName, application) {
    if (application.internationalization) {
      let applicationDatas = application.internationalization.data;
      if (dataName) {
        for (let applicationData in applicationDatas) {
          if (applicationData === dataName) {
            let defaultValue = dataName;
            dataName++;
            return (
              applicationDatas[applicationData].i18n?.title?.[
                localStorage.getItem(LOCAL_STORAGE_LANG)
              ] ?? defaultValue
            );
          }
        }
        return dataName;
      }
    }
    return dataName;
  }

  localeDataDescription(dataName, application) {
    if (application.internationalization) {
      let applicationDatas = application.internationalization.data;
      if (dataName) {
        for (let applicationData in applicationDatas) {
          if (applicationData === dataName) {
            let defaultValue = dataName;
            dataName++;
            return (
              applicationDatas[applicationData].i18n?.description?.[
                localStorage.getItem(LOCAL_STORAGE_LANG)
              ] ?? defaultValue
            );
          }
        }
        return dataName;
      }
    }
    return dataName;
  }
}
