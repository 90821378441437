import { Fetcher } from "../Fetcher";

export class AuthorizationService extends Fetcher {
  static INSTANCE = new AuthorizationService();

  constructor() {
    super();
  }

  async getAdminAuthorizationsForOpenAdom(applicationName, authorizationId) {
    return applicationName
      ? this.get(`applications/${applicationName}/authorization/${authorizationId}`)
      : this.get("authorizationForAdmin");
  }

  async getAdminAuthorizationsForApplication(applicationName) {
    return this.get(`${applicationName}/authorizationAdminForApplication`);
  }

  async getDataAuthorizations(applicationName) {
    return this.get(`applications/${applicationName}/authorization`);
  }

  async getReferencesAuthorizations(applicationName, params) {
    return this.get(`applications/${applicationName}/references/authorization`, params);
  }

  async getadditionalFilesAuthorizations(applicationName, params) {
    return this.get(`applications/${applicationName}/additionalfiles/authorization`, params);
  }

  async getReferencesAuthorizationsForUser(applicationName, userId) {
    return this.get(
      `applications/${applicationName}/references/authorization/${userId ? userId : "null"}`
    );
  }

  async revokeReferenceAuthorization(applicationName, id) {
    return this.delete(`applications/${applicationName}/references/authorization/${id}`);
  }

  async revokeAdditionalFileAuthorization(applicationName, id) {
    return this.delete(`applications/${applicationName}/additionalFiles/authorization/${id}`);
  }

  async getAuthorizationGrantableInfos(applicationName) {
    return this.get(`applications/${applicationName}/grantable`);
  }

  async createAuthorization(applicationName, authorizationModel) {
    return this.post(`applications/${applicationName}/authorization`, authorizationModel, false);
  }

  async createOrUpdateAdditionalFilesAuthorization(authorization) {
    return this.post(
      `applications/${authorization.applicationNameOrId}/additionalFiles/authorization`,
      authorization,
      false
    );
  }

  async createOrUpdateReferencesAuthorization(authorization) {
    return this.post(
      `applications/${authorization.applicationNameOrId}/references/authorization`,
      authorization,
      false
    );
  }

  async createAuthorizedRole(roleName, userIdOrLogin, applicationPattern, applicationNameOrId) {
    let urlSearchParams = new URLSearchParams();
    urlSearchParams.append("userIdOrLogin", userIdOrLogin);
    if (applicationPattern) {
      urlSearchParams.append("applicationPattern", applicationPattern);
    }
    if (applicationNameOrId) {
      urlSearchParams.append("applicationNameOrId", applicationNameOrId);
    }
    return this.put(`authorization/${roleName}?${urlSearchParams.toString()}`);
  }

  async revokeAuthorizedRole(roleName, userIdOrLogin, applicationPattern, applicationNameOrId) {
    let urlSearchParams = new URLSearchParams();
    urlSearchParams.append("userIdOrLogin", userIdOrLogin);
    if (applicationPattern) {
      urlSearchParams.append("applicationPattern", applicationPattern);
    }
    if (applicationNameOrId) {
      urlSearchParams.append("applicationNameOrId", applicationNameOrId);
    }
    return this.delete(`authorization/${roleName}?${urlSearchParams.toString()}`);
  }

  async revokeAuthorization(applicationName, authorizationId) {
    return this.delete(`applications/${applicationName}/authorization/${authorizationId}`);
  }
}
