<script>
export default {
  name: "ShowErrors",
  props: {
    title: {
      required: false,
      type: String,
    },
    errorsMessages: {
      type: Array,
      required: false,
    },
    errorsMessagesLength: {
      type: Number,
      default: 0,
    },
    warningMessages: {
      type: Array,
      required: false,
    },
    warningMessagesLength: {
      type: Number,
      default: 0,
    },
    password: {
      type: String,
    },
  },
};
</script>

<template>
  <div v-if="warningMessagesLength !== 0 && password" style="margin: 10px">
    <div v-for="msg in warningMessages" :key="msg">
      <b-message
        :aria-close-label="$t('message.close')"
        :title="$t('message.login-warning')"
        has-icon
        icon="exclamation-triangle"
        type="is-warning"
      >
        <span class="column" v-html="msg" />
      </b-message>
    </div>
  </div>
  <div v-else>
    <b-collapse :open="false" aria-id="contentIdForA11y1">
      <template #trigger="props">
        <b-tooltip :label="$t('applications.show_errors')" position="is-right" type="is-danger">
          <b-button
            v-if="errorsMessagesLength !== 0"
            :aria-expanded="props.open"
            aria-controls="contentIdForA11y1"
            class="btnErrors"
            icon-right="exclamation-circle"
            outlined
            type="is-danger"
          >
            {{ $t("applications.number_errors", { numberErrors: errorsMessagesLength }) }}
          </b-button>
        </b-tooltip>
      </template>
      <div class="notification">
        <div class="content">
          <div v-for="(msg, keys) in errorsMessages" :key="keys" style="margin: 10px">
            <b-message
              :aria-close-label="$t('message.close')"
              :title="title ? title : $t('message.app-config-error') + ' : ' + msg.title"
              class="mt-4"
              has-icon
              type="is-danger"
            >
              <span v-if="msg?.params">
                <span v-if="msg.params.path" class="columns" style="margin: 0"
                  ><p style="width: 1650px">
                    {{ $t("errors-yaml.path") }}
                    <code
                      ><B>{{ msg.params.path }}</B></code
                    >
                  </p></span
                >
                <span
                  v-if="msg.message"
                  class="columns"
                  style="margin: 10px"
                  v-html="msg.message"
                />
              </span>
              <span v-else v-html="msg" />
            </b-message>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<style lang="scss" scoped>
span.columns {
  display: block;
}
</style>
