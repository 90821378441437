var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.referenceType)?_c('a',{staticClass:"button inTable",on:{"click":function($event){return _vm.openReferenceDetail()}}},[_vm._v(" "+_vm._s(_vm.displayValue ? _vm.displayValue : _vm.value)+" "),(_vm.isLoading)?_c('LoadingAnimate',{attrs:{"size":'is-small'}}):_vm._e()],1):(_vm.patternCheckerDate)?_c('p',[_vm._v(_vm._s(_vm.datePatternLang(_vm.value, _vm.patternCheckerDate)))]):(typeof _vm.value === 'string' && _vm.value.indexOf('date') === 0)?_c('p',[_vm._v(" "+_vm._s(_vm.datePatternLang(_vm.value))+" ")]):_c('p',{staticClass:"column"},[_vm._v(_vm._s(_vm.value))]),_c('b-modal',{attrs:{"custom-class":"referenceDetails"},model:{value:(_vm.isCardModalActive),callback:function ($$v) {_vm.isCardModalActive=$$v},expression:"isCardModalActive"}},[_c('div',{staticClass:"card"},[_c('header',{staticClass:"card-header is-align-content-center"},[_c('p',{staticClass:"card-header-title",staticStyle:{"font-size":"1.5rem","color":"rgb(0, 127, 127)"},attrs:{"field":"name"}},[_vm._v(" "+_vm._s(_vm.reference ? _vm.reference.localName !== _vm.value ? _vm.getValueDisplay(_vm.reference.rows[0], _vm.application) : _vm.reference.localName : _vm.value)+" ")])]),_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"content is-align-content-center"},[_c('b-table',{attrs:{"data":_vm.refValuesObj.data}},_vm._l((_vm.refValuesObj.columns),function(column){return _c('b-table-column',{key:column.id,attrs:{"label":column.label},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',[(!props.row[column.field].length && props.row[column.field].length !== 0)?_c('DatasDynamicLink',{attrs:{"application":_vm.application,"column-id":column.id,"info":!props.row[column.field].length && props.row[column.field].length !== 0,"info-values":props.row[column.field],"loaded-references-by-key":{},"reference-type":_vm.dynamicColumnReferences(props.row.colonne)}}):(
                    Array.isArray(props.row[column.field]) && props.row[column.field].length !== 0
                  )?_c('DatasManyLink',{attrs:{"application":_vm.application,"column-id":column.id,"info-values":props.row[column.field],"loaded-references-by-key":{},"multiplicity":true,"reference-type":_vm.searchRefLinkedTo(props.row['colonne'])}}):(
                    column.field === 'valeur' &&
                    _vm.refValuesObj.referenceTypeForReferencingColumns[props.row.colonne]
                  )?_c('DatasLink',{attrs:{"application":_vm.application,"loaded-references-by-key":{},"display-value":_vm.reference.values.displaysForRow[
                      _vm.refValuesObj.referenceTypeForReferencingColumns[props.row.colonne]
                    ][props.row.valeur],"reference-type":_vm.refValuesObj.referenceTypeForReferencingColumns[props.row.colonne],"value":props.row.valeur}}):(column.field === 'colonne')?_c('p',[_vm._v(" "+_vm._s(_vm.getColumnNameView(props.row[column.field], _vm.application, _vm.referenceType))+" ")]):_c('p',[_vm._v(_vm._s(props.row[column.field]))])],1)]}}],null,true)})}),1)],1)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }