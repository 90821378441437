<script>
import { Tag } from "@/model/application/Tag";
import { computed } from "vue";

export default {
  name: "TagsInfos",
  props: {
    tagsColumn: {
      type: Object,
    },
    infoParent: {
      type: Object,
    },
    tagsMenu: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    function getLocale(tag) {
      if (
        tag.tagName !== "ORDER_TAG" &&
        tag.tagName !== "DATA_TAG" &&
        tag.tagName !== "REFERENCE_TAG"
      ) {
        return props.tagsColumn[tag.tagName]?.localName
          ? props.tagsColumn[tag.tagName].localName
          : tag.tagName;
      }
    }

    function isPresent(tag) {
      if (tag.type) {
        return tag && tag.type !== Tag.NO_TAG && tag.findTagInTagList(props.tagsColumn);
      } else
        return (
          tag &&
          tag.tagDefinition !== Tag.ORDER_TAG &&
          tag.tagDefinition !== Tag.NO_TAG &&
          tag.findTagInTagList(props.tagsColumn)
        );
    }

    function isSelected(tag) {
      return isPresent(tag) && tag.findTagInTagList(props.tagsColumn).selected;
    }

    function presenceTag(tag) {
      return isPresent(tag) ? "is-primary is-light" : "is-warning is-light";
    }
    const tagsListParent = computed(() =>
      props.infoParent
        ? props.infoParent.localtags
          ? props.infoParent.localtags
          : props.infoParent.tags
        : []
    );

    const tagInListParent = computed(
      () => props.tagsMenu && tagsListParent.value && tagsListParent.value.filter(isPresent)
    );
    const tagInInfoParent = computed(
      () => props.tagsMenu && props?.infoParent && props?.infoParent?.tags
    );
    const noMenuTag = computed(
      () =>
        !props.tagsMenu &&
        Object.keys(props.tagsColumn).length !== 0 &&
        props.infoParent &&
        props.infoParent.tags
    );
    const infoParentDisplay = computed(() =>
      props.infoParent
        ? props.infoParent.label
          ? props.infoParent.label
          : props.infoParent.title
        : ""
    );

    return {
      getLocale,
      presenceTag,
      tagInListParent,
      tagInInfoParent,
      noMenuTag,
      infoParentDisplay,
      isSelected,
      isPresent,
    };
  },
};
</script>

<template>
  <div>
    <div v-if="tagInListParent && tagInListParent.length">
      <span v-for="tag in tagInListParent" :key="tag.tagName" style="margin: 5px">
        <b-tag :class="presenceTag(tag)">
          <span>
            {{ getLocale(tag) }}
          </span>
        </b-tag>
      </span>
    </div>
    <div v-else-if="tagInInfoParent && tagInInfoParent.length">
      <div v-for="tag in tagInInfoParent" :key="tag.tagName" style="margin: 5px">
        <b-tag
          v-if="tag !== {} && tag.tagName !== 'ORDER_TAG' && tag.tagName !== 'no_tag'"
          class="is-primary"
        >
          <span>
            {{ getLocale(tag) }}
          </span>
        </b-tag>
      </div>
    </div>
    <div v-else>
      <span v-for="tag in noMenuTag" :key="tag.tagName" style="margin-left: 5px">
        <b-tag v-if="isPresent(tag)" :class="presenceTag(tag)">
          {{ getLocale(tag) }}
        </b-tag>
      </span>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
