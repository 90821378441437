import { Authorization } from "@/model/authorization/Authorization";
import { LOCAL_STORAGE_LANG } from "@/services/Fetcher";
import { InternationalisationService } from "@/services/InternationalisationService";
import { AuthorizationsForAll } from "@/model/authorization/AuthorizationsForAll";
import { AuthorizationsForRestriction } from "@/model/authorization/AuthorizationsForRestriction";

export class Authorizations {
  static ROLES() {
    return {
      suppression: [],
      extraction: [],
      admin: [],
      depot: [],
      publication: [],
    };
  }

  static internationalizationService = InternationalisationService.INSTANCE;
  static COLUMNS_VISIBLEeferenceService = {
    label: {
      title: "Label",
      display: true,
      forPublic: true,
      forRequest: true,
      internationalizationName: { fr: "Domaine", en: "Domain" },
    },
  };

  #scopesId = [];
  authorizationsWithRestriction = {};
  authorizationForAll = {};
  uuid = "";
  applicationNameOrId = "";
  dataType = "";
  name = "";
  usersId = [];
  authorizations = this.ROLES;

  constructor(authorizations, authorizationsScope) {
    this.#scopesId = authorizationsScope;
    this.authorizations = authorizations;
  }

  init(authorizations, authorizationScopes, dependantNodesByDataName) {
    this.parseAuthorizations(authorizations, authorizationScopes, dependantNodesByDataName);
  }

  parseAuthorizations(authorizations, authorizationScopes, dependantNodesByDataName) {
    let authorizationsByDatatype = {};
    for (const datatype in authorizations) {
      let hasAuthorizations = (authorizationScopes[datatype] || []).length;
      let dependantNodes = Object.keys(dependantNodesByDataName)
        .filter((key) => dependantNodesByDataName[key].includes(datatype))
        .filter((key) => authorizations[key]);
      if (hasAuthorizations) {
        authorizationsByDatatype[datatype] = new AuthorizationsForRestriction(
          dependantNodes,
          dependantNodesByDataName[datatype],
          authorizations[datatype]
        );
      } else {
        authorizationsByDatatype[datatype] = new AuthorizationsForAll(
          dependantNodes,
          dependantNodesByDataName[datatype],
          authorizations[datatype]
        );
      }
    }
    this.authorizations = authorizationsByDatatype;
  }

  /* authorizationScopes
    {
      "themes": [],
      "especes": [],
      "variables": [],
      "type_de_sites": [],
      "site_theme_datatype": [],
      "unites": [],
      "projet": [],
      "valeurs_qualitatives": [],
      "type_de_fichiers": [],
      "variables_et_unites_par_types_de_donnees": [],
      "pem": [
          {
              "id": "projet",
              "datatype": "pem",
              "i18n": {
                  "en": "projet",
                  "fr": "projet"
              },
              "nodes": [
                  {
                      "id": "projetKprojet_atlantique",
                      "type": "projet",
                      "naturalKey": "projet_atlantique",
                      "i18n": {
                          "en": "Atlantic project",
                          "fr": "Projet Atlantique"
                      },
                      "children": []
                  },
                  {
                      "id": "projetKprojet_manche",
                      "type": "projet",
                      "naturalKey": "projet_manche",
                      "i18n": {
                          "en": "Channel project",
                          "fr": "Projet manche"
                      },
                      "children": []
                  }
              ]
          },
          {
              "id": "sites",
              "datatype": "pem",
              "i18n": {
                  "en": "Path",
                  "fr": "Chemin"
              },
              "nodes": [
                  {
                      "id": "type_de_sitesKbassin_versant",
                      "type": "type_de_sites",
                      "naturalKey": "bassin_versant",
                      "i18n": {
                          "en": "Watershed",
                          "fr": "Bassin versant"
                      },
                      "children": [
                          {
                              "id": "type_de_sitesKbassin_versant.sitesKnivelle",
                              "type": "sites",
                              "naturalKey": "nivelle",
                              "i18n": {
                                  "en": "Nivelle",
                                  "fr": "Nivelle"
                              },
                              "children": [
                                  {
                                      "id": "type_de_sitesKplateforme.sitesKnivelle.sitesKnivelle__p1",
                                      "type": "sites",
                                      "naturalKey": "nivelle__p1",
                                      "i18n": {
                                          "en": "P1",
                                          "fr": "P1"
                                      },
                                      "children": []
                                  }
                              ]
                          },
                          {
                              "id": "type_de_sitesKbassin_versant.sitesKoir",
                              "type": "sites",
                              "naturalKey": "oir",
                              "i18n": {
                                  "en": "Oir",
                                  "fr": "Oir"
                              },
                              "children": [
                                  {
                                      "id": "type_de_sitesKplateforme.sitesKoir.sitesKoir__p1",
                                      "type": "sites",
                                      "naturalKey": "oir__p1",
                                      "i18n": {
                                          "en": "P1",
                                          "fr": "P1"
                                      },
                                      "children": [
                                          {
                                              "id": "type_de_sitesKplateforme.sitesKoir.sitesKoir__p1.sitesKoir__p1__b",
                                              "type": "sites",
                                              "naturalKey": "oir__p1__b",
                                              "i18n": {
                                                  "en": "B",
                                                  "fr": "B"
                                              },
                                              "children": []
                                          },
                                          {
                                              "id": "type_de_sitesKplateforme.sitesKoir.sitesKoir__p1.sitesKoir__p1__a",
                                              "type": "sites",
                                              "naturalKey": "oir__p1__a",
                                              "i18n": {
                                                  "en": "A",
                                                  "fr": "A"
                                              },
                                              "children": []
                                          }
                                      ]
                                  },
                                  {
                                      "id": "type_de_sitesKplateforme.sitesKoir.sitesKoir__p2",
                                      "type": "sites",
                                      "naturalKey": "oir__p2",
                                      "i18n": {
                                          "en": "P2",
                                          "fr": "P2"
                                      },
                                      "children": []
                                  }
                              ]
                          },
                          {
                              "id": "type_de_sitesKbassin_versant.sitesKscarff",
                              "type": "sites",
                              "naturalKey": "scarff",
                              "i18n": {
                                  "en": "Scarff",
                                  "fr": "Scarff"
                              },
                              "children": [
                                  {
                                      "id": "type_de_sitesKplateforme.sitesKscarff.sitesKscarff__p1",
                                      "type": "sites",
                                      "naturalKey": "scarff__p1",
                                      "i18n": {
                                          "en": "P1",
                                          "fr": "P1"
                                      },
                                      "children": []
                                  }
                              ]
                          }
                      ]
                  }
              ]
          }
      ],
      "sites": []
  }
     */
  /* Authorizations
    {
      "especes": {
          "extraction": [
              {
                  "path": "not setting",
                  "requiredAuthorizations": {},
                  "fromDay": null,
                  "toDay": null
              }
          ]
      },
      "type_de_sites": {
          "extraction": [
              {
                  "path": "not setting",
                  "requiredAuthorizations": {},
                  "fromDay": null,
                  "toDay": null
              }
          ]
      },
      "unites": {
          "extraction": [
              {
                  "path": "not setting",
                  "requiredAuthorizations": {},
                  "fromDay": null,
                  "toDay": null
              }
          ]
      },
      "projet": {
          "extraction": [
              {
                  "path": "not setting",
                  "requiredAuthorizations": {},
                  "fromDay": null,
                  "toDay": null
              }
          ]
      },
      "valeurs_qualitatives": {
          "extraction": [
              {
                  "path": "not setting",
                  "requiredAuthorizations": {},
                  "fromDay": null,
                  "toDay": null
              }
          ]
      },
      "pem": {
          "extraction": [
              {
                  "path": "not setting",
                  "requiredAuthorizations": {
                      "projet": "projetKprojet_manche",
                      "sites": "type_de_sitesKplateforme.sitesKoir.sitesKoir__p1"
                  },
                  "fromDay": [
                      1984,
                      1,
                      2
                  ],
                  "toDay": [
                      1984,
                      1,
                      4
                  ]
              }
          ]
      },
      "sites": {
          "extraction": [
              {
                  "path": "not setting",
                  "requiredAuthorizations": {},
                  "fromDay": null,
                  "toDay": null
              }
          ]
      }
  }
     */

  /* dependantNodesByDataName
    {
      "themes": [],
      "especes": [],
      "variables": [],
      "type_de_sites": [],
      "site_theme_datatype": [
          "themes",
          "type_de_sites",
          "projet",
          "sites"
      ],
      "unites": [],
      "projet": [],
      "valeurs_qualitatives": [],
      "type_de_fichiers": [],
      "variables_et_unites_par_types_de_donnees": [
          "variables",
          "unites"
      ],
      "pem": [
          "especes",
          "type_de_sites",
          "unites",
          "projet",
          "valeurs_qualitatives",
          "sites"
      ],
      "sites": [
          "type_de_sites"
      ]
  }
     */

  initStates(authorizations) {
    this.authorizations = authorizations || {};
    this.scopes = {};
    for (const scope in authorizations) {
      const scopeId = this.#scopesId;
      let scopes = (authorizations[scope] || []).reduce((acc, auth) => {
        auth = new Authorization(auth);
        acc[scope] = acc[scope] || {};
        acc[scope][auth.getPath(scopeId)] = auth;
        return acc;
      }, {});
      this.scopes = { ...this.scopes, ...scopes };
    }
  }

  getDependants(scope, path) {
    if (this.authorizations[scope]) {
      return this.authorizations[scope].filter((auth) => {
        let pathToCompare = new Authorization(auth).getPath(this.#scopesId);
        if (pathToCompare.pathStartsWith(path) && pathToCompare !== path) {
          return true;
        }
        return false;
      });
    }
    return [];
  }

  getState(scope, path, publicState) {
    let state = {
      state: 0,
      fromPath: "",
      dataGroups: [],
      from: null,
      to: null,
      fromAuthorization: null,
      publicState,
    };
    if (this.authorizations[scope]) {
      for (const auth in this.authorizations[scope]) {
        let authorizationElement = new Authorization(this.authorizations[scope][auth]);
        let pathToCompare = authorizationElement.getPath(this.#scopesId);
        if (path.pathStartsWith(pathToCompare)) {
          state.fromPath = pathToCompare;
          state.fromAuthorization = authorizationElement;
          state.fromDay = authorizationElement.fromDay;
          state.toDay = authorizationElement.toDay;
          state.from = authorizationElement.fromDay ? new Date(authorizationElement.fromDay) : null;
          state.to = authorizationElement.toDay ? new Date(authorizationElement.toDay) : null;
          state.dataGroups = authorizationElement.dataGroups;
          state.hasPublicStates = false;
          state.localState = 0;
        }
      }
    }
    for (const scopeKey in this.scopes[scope]) {
      if (path.pathStartsWith(scopeKey)) {
        state.state = 1;
        state.localState = 1;
        return state;
      } else if (scopeKey.pathStartsWith(path)) {
        state.state = -1;
        state.localState = -1;
        return state;
      }
    }
    if (state.state === 0 && state.publicState !== 0) {
      state.hasPublicStates = true;
      state.localState = state.publicState;
    } else if (state.publicState === 1 && state.state === 1) {
      state.hasPublicStates = true;
      state.localState = state.publicState;
    } else {
      state.hasPublicStates = false;
      state.localState = state.state;
    }
    return state;
  }

  getCheckedAuthorization(scope, currentPath) {
    for (const scopeKey in this.scopes[scope]) {
      if (currentPath.pathStartsWith(scopeKey)) {
        return { scopeKey, auth: this.scopes[scope][scopeKey] };
      }
    }
  }

  static getRefForRet(ret) {
    return (Object.values(ret) || [])
      .reduce(
        (acc, k) => [
          ...acc,
          ...(k.references.referenceValues || []).reduce(
            (a, b) => [...a, ...b.hierarchicalReference.split(".")],
            acc
          ),
        ],
        []
      )
      .reduce((a, b) => {
        if (a.indexOf(b) < 0) {
          a.push(b);
        }
        return a;
      }, []);
  }

  static async remainingAuthorizations(ret) {
    let remainingAuthorizations = [];
    for (const key in ret) {
      let partition = await Authorizations.partitionReferencesValues(
        ret[key]?.references,
        ret[key]?.authorizationScope,
        null,
        null
      );
      remainingAuthorizations[key] = partition;
    }
    if (!remainingAuthorizations.length) {
      remainingAuthorizations = [
        {
          __DEFAULT__: {
            authorizationScope: {
              id: "__DEFAULT__",
              localName: "root",
            },
            completeLocalName: "__.__",
            currentPath: "__.__",
            isLeaf: true,
            localName: "__.__fr",
            reference: {},
            referenceValues: {},
          },
        },
      ];
    }
    return remainingAuthorizations;
  }

  static async partitionReferencesValues(
    referencesValues,
    authorizationScope,
    currentPath,
    currentCompleteLocalName
  ) {
    let returnValues = {};

    for (const referenceValue of referencesValues) {
      const previousKeySplit = currentPath ? currentPath.split(".") : [];
      const keys = referenceValue.hierarchicalKey.split(".");
      const references = referenceValue.hierarchicalReference.split(".");

      if (previousKeySplit.length == keys.length) {
        continue;
      }

      for (let i = 0; i < previousKeySplit.length; i++) {
        keys.shift();
        references.shift();
      }
      const key = keys.shift();
      let newCurrentPath = (currentPath ? currentPath + "." : "") + key;
      const reference = references.shift();
      Authorizations.internationalizationService.getUserPrefLocale();
      let lang = localStorage.getItem(LOCAL_STORAGE_LANG);
      let localName; // = refValues.referenceValues.find((r) => r.naturalKey == key);
      if (localName?.values?.["__display_" + lang]) {
        localName = localName?.values?.["__display_" + lang];
      } else {
        localName = key;
      }
      if (!localName) {
        localName = key;
      }
      let completeLocalName =
        typeof currentCompleteLocalName === "undefined" ? "" : currentCompleteLocalName;
      completeLocalName = completeLocalName + (completeLocalName == "" ? "" : ",") + localName;
      let authPartition = returnValues[key] || {
        key,
        reference,
        authorizationScope,
        referenceValues: [],
        localName,
        isLeaf: false,
        currentPath: newCurrentPath,
        completeLocalName,
      };
      authPartition.referenceValues.push(referenceValue);
      returnValues[key] = authPartition;
    }
    for (const returnValuesKey in returnValues) {
      const auth = returnValues[returnValuesKey];
      let referenceValueLeaf = auth.referenceValues?.[0];
      if (
        auth.referenceValues.length <= 1 &&
        referenceValueLeaf.hierarchicalKey == auth.currentPath
      ) {
        returnValues[returnValuesKey] = {
          ...auth,
          authorizationScope,
          isLeaf: true,
          referenceValues: { ...referenceValueLeaf, authorizationScope },
        };
      } else {
        var r = await this.partitionReferencesValues(
          auth.referenceValues,
          authorizationScope,
          auth.currentPath,
          auth.completeLocalName
        );
        returnValues[returnValuesKey] = {
          ...auth,
          isLeaf: false,
          referenceValues: r,
        };
      }
    }
    return returnValues;
  }

  static parseGrantableInfos(grantableInfos, datatypes, isRepository) {
    let parsing = {
      authorizationScopes: {},
      dataGroups: {},
      users: [],
      publicUser: [],
      publicAuthorizations: {},
      isApplicationAdmin: false,
      ownAuthorizations: {},
      ownAuthorizationsColumnsByPath: {},
      columnsVisible: {},
      columnsVisibleForPublic: {},
    };
    let authorizationsForUser;
    ({
      referenceScopes: parsing.authorizationScopes,
      dataGroups: parsing.dataGroups,
      users: parsing.users,
      authorizationsForUser: authorizationsForUser,
      publicAuthorizations: parsing.publicAuthorizations,
    } = grantableInfos);
    parsing.publicUser = parsing.users.shift();
    parsing.isApplicationAdmin = authorizationsForUser.isAdministrator;
    let authorizationsForUserByPath = authorizationsForUser.authorizationByPath;
    let ownAuthorizationsForUser = authorizationsForUser.authorizationResults;
    for (const datatype in datatypes) {
      let ownAuthorizationsforDatatype = [];
      for (const scope in ownAuthorizationsForUser[datatype]) {
        let scopeAuthorizations = ownAuthorizationsForUser[datatype][scope];
        scopeAuthorizations
          .map((auth) => new Authorization(auth))
          .filter((auth) => {
            const path = auth.getKey();
            return (
              ownAuthorizationsforDatatype.indexOf(path) === -1 &&
              !ownAuthorizationsforDatatype.find((pa) => path.pathStartsWith(pa))
            );
          })
          .map((auth) => auth.getKey())
          .forEach((auth) => {
            ownAuthorizationsforDatatype.push(auth);
          });
        parsing.ownAuthorizations[datatype] = ownAuthorizationsforDatatype;
      }
      let ownAuthorizationsColumnsByPathForDatatype = {};
      for (const path of parsing.ownAuthorizations[datatype] || []) {
        for (const scopeId in authorizationsForUserByPath[datatype]) {
          if (authorizationsForUserByPath[datatype][scopeId]) {
            for (const pathKey in authorizationsForUserByPath[datatype][scopeId]) {
              if (pathKey.pathStartsWith(path) || path.pathStartsWith(pathKey)) {
                let autorizedPath = pathKey.pathStartsWith(path) ? path : pathKey;
                ownAuthorizationsColumnsByPathForDatatype[autorizedPath] =
                  ownAuthorizationsColumnsByPathForDatatype[autorizedPath] || [];
                ownAuthorizationsColumnsByPathForDatatype[autorizedPath].push(scopeId);
              }
            }
          }
        }
      }
      parsing.ownAuthorizationsColumnsByPath[datatype] = ownAuthorizationsColumnsByPathForDatatype;
      let columnsVisibleForDatatype = {
        ...(this.COLUMNS_VISIBLE || {}),
        ...grantableInfos.columnsDescription[datatype],
      };
      if (!isRepository) {
        columnsVisibleForDatatype.publication = {
          ...columnsVisibleForDatatype.publication,
          display: false,
        };
      }
      parsing.columnsVisible[datatype] = columnsVisibleForDatatype;
      columnsVisibleForDatatype = {};
      for (const scope in parsing.columnsVisible[datatype]) {
        let columnsVisibleFordatatypeAndScope = parsing.columnsVisible[datatype][scope];
        if (columnsVisibleFordatatypeAndScope.forPublic) {
          columnsVisibleForDatatype[scope] = columnsVisibleFordatatypeAndScope;
        }
      }
      parsing.columnsVisibleForPublic[datatype] = columnsVisibleForDatatype;
    }
    this.extractPublicAuthorizations(parsing.publicAuthorizations, parsing.authorizationScopes);
    return parsing;
  }

  static extractPublicAuthorizations(publicAuthorizations, authorizationScopes) {
    let publicAuthorizationToReturn = {};
    for (const datatype in publicAuthorizations) {
      let auths = publicAuthorizations[datatype];
      for (const scope in auths) {
        publicAuthorizationToReturn[scope] = [];
        let scopeAuthorizations = auths[scope];
        for (const scopeAuthorizationsKey in scopeAuthorizations) {
          let scopeAuthorization = new Authorization(scopeAuthorizations[scopeAuthorizationsKey]);
          let path = scopeAuthorization.getPath2(authorizationScopes[datatype].map((a) => a.id));
          if (publicAuthorizationToReturn[scope].indexOf(path) === -1) {
            if (!publicAuthorizationToReturn[scope].find((pa) => path.pathStartsWith(pa))) {
              publicAuthorizationToReturn[scope] = publicAuthorizationToReturn[scope].filter(
                (pa) => !pa.pathStartsWith(path)
              );
              publicAuthorizationToReturn[scope].push(path);
            }
          }
        }
      }
      publicAuthorizations[datatype] = publicAuthorizationToReturn;
    }
    return publicAuthorizations;
  }

  static async initAuthReferences(configuration, authorizations, authorizationScopes) {
    let authReferences = {};
    for (const datatype in authorizationScopes) {
      let info = authorizationScopes[datatype];
      //info.reverse();
      let ret = {};
      for (let auth in info) {
        let authorizationScope = info[auth];
        const reference = authorizationScope.id;
        //const label = authorizationScope.i18n[localStorage.getItem(LOCAL_STORAGE_LANG)] || reference
        ret[auth] = { references: reference, authorizationScope };
      }
      //let refs = Authorizations.getRefForRet(ret);
      const remainingAuthorizations = await Authorizations.remainingAuthorizations(ret);
      authReferences[datatype] = remainingAuthorizations;
    }
    return authReferences;
  }
}
