import { ref } from "vue";

function grantableInfos(datatype, grantableInfos) {
  const columnsDescription = ref(grantableInfos?.columnsDescription?.[datatype] || {});
  const autorizationByPath = ref(
    grantableInfos?.authorizationsForUser?.authorizationByPath?.[datatype] || {}
  );
  const users = ref(grantableInfos?.users || []);
  const isAdministrator = ref(grantableInfos?.authorizationsForUser?.isAdministrator || false);
  const publicAuthorizations = ref(grantableInfos?.publicAuthorizations || {});
  const referenceScopes = ref(grantableInfos.referenceScopes?.[datatype] || {});
  return {
    columnsDescription,
    autorizationByPath,
    users,
    isAdministrator,
    publicAuthorizations,
    referenceScopes,
  };
}

export function getListColumnName() {
  const listColumnName = ref({});
  listColumnName.value["withoutScope"] = {
    delete: {
      display: true,
      title: "delete",
      withPeriods: false,
      withDataGroups: false,
      forPublic: false,
      forRequest: false,
      internationalizationName: {
        fr: "Suppression",
        en: "Deletion",
      },
    },
    depot: {
      display: true,
      title: "depot",
      withPeriods: false,
      withDataGroups: false,
      forPublic: false,
      forRequest: false,
      internationalizationName: {
        fr: "Dépôt",
        en: "Deposit",
      },
    },
    extraction: {
      display: true,
      title: "extraction",
      withPeriods: true,
      withDataGroups: true,
      forPublic: true,
      forRequest: true,
      internationalizationName: {
        fr: "Extraction",
        en: "Extraction",
      },
    },
  };
  listColumnName.value["withScope"] = {
    delete: {
      display: true,
      title: "delete",
      withPeriods: false,
      withDataGroups: false,
      forPublic: false,
      forRequest: false,
      internationalizationName: {
        fr: "Suppression",
        en: "Deletion",
      },
    },
    depot: {
      display: true,
      title: "depot",
      withPeriods: false,
      withDataGroups: false,
      forPublic: false,
      forRequest: false,
      internationalizationName: {
        fr: "Dépôt",
        en: "Deposit",
      },
    },
    publication: {
      display: true,
      title: "publication",
      withPeriods: false,
      withDataGroups: false,
      forPublic: false,
      forRequest: false,
      internationalizationName: {
        fr: "Publication",
        en: "Publication",
      },
    },
    extraction: {
      display: true,
      title: "extraction",
      withPeriods: true,
      withDataGroups: true,
      forPublic: true,
      forRequest: true,
      internationalizationName: {
        fr: "Extraction",
        en: "Extraction",
      },
    },
  };
  return listColumnName;
}
export default grantableInfos;
