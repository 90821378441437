<script>
import { ValidationProvider } from "vee-validate";
import { computed } from "vue";

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    rules: {
      type: String,
      required: false,
      default: "",
    },
    label: {
      type: String,
      required: true,
    },
    placeHolder: {
      type: String,
      required: true,
    },
    mandatory: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      required: true,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "text",
    },
  },
  components: { ValidationProvider },
  emits: ["update:value", "update:blur"],
  setup(props) {
    const rulesWithMandatory = computed(() => {
      let rulesWithMandatory = props.rules;
      if (props.mandatory) {
        rulesWithMandatory += rulesWithMandatory ? "|required" : "required";
      }
      return rulesWithMandatory;
    });
    return { rulesWithMandatory };
  },
};
</script>

<template>
  <ValidationProvider
    v-slot="{ errors, valid }"
    :name="name"
    :rules="rulesWithMandatory"
    :vid="name"
  >
    <b-field
      :message="errors[0]"
      :type="{
        'is-danger': errors && errors.length > 0,
        'is-success': valid,
      }"
      class="input-field"
    >
      <template v-slot:label>
        {{ $t(label) }}
        <span class="mandatory" v-if="mandatory">
          {{ $t("validation.obligatoire") }}
        </span>
      </template>
      <b-input
        :data-vv-as="name"
        :disabled="disable"
        :type="type"
        :value="value"
        @input="$emit('update:value', $event)"
        @blur="$emit('update:blur', $event.target.value)"
        :placeholder="$t(placeHolder)"
        :title="name"
      >
      </b-input>
    </b-field>
  </ValidationProvider>
</template>

<style scoped lang="scss"></style>
