<template>
  <ValidationObserver ref="form" v-slot="{}" tag="form" @submit.prevent="onSubmit">
    <b-collapse v-model="isOpen" animation="slide" class="card" style="box-shadow: none">
      <template #trigger="detail">
        <div
          :aria-expanded="isOpen"
          aria-controls="contentIdForA11y2"
          class="card-header"
          role="button"
          style="box-shadow: none; border-bottom: thick double #009d9d"
        >
          <p class="card-header-title">
            {{ description }}
          </p>
          <a class="card-header-icon">
            <b-icon :icon="detail.open ? 'chevron-up' : 'chevron-down'" type="is-primary"></b-icon>
          </a>
        </div>
      </template>
      <OreInputText
        v-if="showComment"
        :checker="{ params: { required: true } }"
        :label="$t('applications.comment')"
        :value="comment"
        :vid="comment"
        @update:value="updateComment($event)"
      />
      <div v-if="Object.keys(format).length !== 0">
        <div v-for="(item, key) in format" :key="key">
          <OreInputNumber
            v-if="item.checker.type === 'IntegerChecker' || item.checker.type === 'FloatChecker'"
            :checker="item.checker"
            :label="
              internationalisationService.getLocaleforPath(application, pathForKey + '.' + key, key)
            "
            :value="
              fields[key]
                ? item.checker.type === 'FloatChecker'
                  ? parseFloat(fields[key])
                  : parseInt(fields[key])
                : 0
            "
            :vid="key"
            @update:value="updateValue(key, $event)"
          />
          <OreInputDate
            v-else-if="item.checker.type === 'DateChecker'"
            :checker="item.checker"
            :label="
              internationalisationService.getLocaleforPath(application, pathForKey + '.' + key, key)
            "
            :value="fields[key] ? fields[key] : new Date(null)"
            :vid="key"
            @update:value="updateValue(key, $event)"
          />
          <OreInputReference
            v-else-if="item.checker.type === 'ReferenceChecker' && item.checker.refType"
            :checker="item.checker"
            :label="
              internationalisationService.getLocaleforPath(application, pathForKey + '.' + key, key)
            "
            :references="referencesScopes[key] ? referencesScopes[key].rows : []"
            :value="fields[key]"
            :vid="key"
            @update:value="updateValue(key, $event)"
          />
          <OreInputText
            v-else
            :checker="item.checker"
            :label="
              internationalisationService.getLocaleforPath(application, pathForKey + '.' + key, key)
            "
            :value="fields[key]"
            :vid="key"
            @update:value="updateValue(key, $event)"
          />
        </div>
      </div>
    </b-collapse>
  </ValidationObserver>
</template>

<script>
import OreInputText from "@/components/common/provider/OreInputText";
import OreInputNumber from "@/components/common/provider/OreInputNumber";
import OreInputDate from "@/components/common/provider/OreInputDate";
import OreInputReference from "@/components/common/provider/OreInputReference";
import { ValidationObserver } from "vee-validate";
import { InternationalisationService } from "@/services/InternationalisationService";
import { onMounted, ref, watch } from "vue";
import services from "@/composable/services";

export default {
  name: "FieldsForm",
  emits: ["update:fields", "update:comment"],
  data: () => {
    return {
      isOpen: true,
      internationalisationService: InternationalisationService.INSTANCE,
    };
  },
  components: {
    OreInputDate,
    OreInputReference,
    OreInputNumber,
    OreInputText,
    ValidationObserver,
  },
  props: {
    application: Object,
    pathForKey: String,
    format: Object,
    description: String,
    fields: Object,
    comment: {
      type: String,
      default: "",
    },
    showComment: Boolean,
  },
  setup(props, ctx) {
    const fields = ref(props.fields);
    const referencesScopes = ref({});
    const formRef = ref(null);
    watch(
      () => props.fields,
      (newValue) => {
        if (newValue) {
          fields.value = ref(newValue);
        }
      },
      { immediate: true }
    );
    onMounted(async () => {
      const newReferencesScopes = {};
      for (let item in props.format) {
        if (
          props.format[item].checker &&
          props.format[item].checker.type === "ReferenceChecker" &&
          props.format[item].checker.refType
        ) {
          newReferencesScopes[item] = await services.dataService.getData(
            props.application.name,
            props.format[item].checker.refType
          );
        }
      }
      referencesScopes.value = newReferencesScopes;
      console.log(props.fields);
    });

    function onSubmit(event) {
      console.log("submit", event);
    }
    function updateValue(key, event) {
      fields.value = event;
      let localeFields = fields.value;
      ctx.emit("update:fields", { fields: localeFields, key });
    }

    function updateComment(event) {
      ctx.emit("update:comment", { comment: event, valid: event !== "" });
    }
    return {
      updateComment,
      updateValue,
      onSubmit,
      referencesScopes,
      formRef,
    };
  },
  methods: {},
};
</script>

<style scoped>
.required {
  color: rgb(255, 0, 0);
  padding-right: 5px;
  font-size: 150%;
}
</style>
