import { ref } from "vue";

function useDate(date = new Date()) {
  const refDate = ref(date);
  const doChangeDate = function (newDate) {
    refDate.value = new Date(newDate);
  };
  return {
    refDate,
    doChangeDate,
  };
}
export default useDate;
