<script>
import useDate from "@/composable/components/date";
import { watch } from "vue";

export default {
  name: "InputDate",
  components: {},
  emits: ["update:dateValue", "update:sizeHeight"],
  props: {
    inputType: String,
    name: String,
    format: String,
    from: Date,
    modelValue: Object,
    isSimpleValue: Boolean,
  },
  setup(props, ctx) {
    const { refDate: valueDate } = useDate(null);
    watch(
      () => props.from,
      (value) => {
        valueDate.value = value;
      },
      { immediate: true }
    );
    function updateValueDate(event) {
      if (typeof event === "number") {
        ctx.emit("update:sizeHeight", event);
      }
      ctx.emit("update:dateValue", {
        value: valueDate.value,
        filter: new Date(valueDate.value).getTime(),
        componentKey: props.name,
        isSimpleValue: props.isSimpleValue,
        name: props.name,
      });
    }
    return {
      valueDate,
      updateValueDate,
    };
  },
};
</script>

<template>
  <div v-if="inputType === 'datetime'" class="row">
    <b-datetimepicker
      v-model="valueDate"
      :placeholder="$t('dataTypesRepository.placeholder-datepicker') + format"
      editable
      expanded
      icon="calendar-day"
      :years-range="[-100, 100]"
      @focus="updateValueDate(22.625)"
      @blur="updateValueDate(-22.625)"
    >
    </b-datetimepicker>
  </div>
  <div v-else-if="inputType === 'date'" class="row">
    <b-datepicker
      v-model="valueDate"
      :placeholder="$t('dataTypesRepository.placeholder-datepicker') + format"
      editable
      expanded
      icon="calendar-day"
      :years-range="[-100, 100]"
      @focus="updateValueDate(22.625)"
      @blur="updateValueDate(-22.625)"
    >
    </b-datepicker>
  </div>
  <div v-else-if="inputType === 'time'" class="row">
    <b-timepicker
      v-model="valueDate"
      :placeholder="$t('dataTypesRepository.placeholder-datepicker') + format"
      editable
      expanded
      icon="clock"
      :years-range="[-100, 100]"
      @blur="updateValueDate"
    >
    </b-timepicker>
  </div>
</template>

<style scoped lang="scss"></style>
