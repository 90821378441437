<script>
import { watch } from "vue";
import useNumber from "@/composable/components/number";

export default {
  name: "InputNumber",
  components: {},
  props: {
    inputType: String,
    format: String,
    from: Number,
    min: Number,
    max: Number,
    modelValue: Object,
    isSimpleValue: Boolean,
  },
  setup(props, ctx) {
    const { refNumber: valueNumber } = useNumber();
    watch(
      () => props.from,
      (value) => {
        valueNumber.value = value;
      },
      { immediate: true }
    );
    function updateValueNumber() {
      ctx.emit("update:numberValue", {
        value: valueNumber.value,
        filter: valueNumber.value,
        isSimpleValue: props.isSimpleValue,
      });
    }
    return {
      valueNumber,
      updateValueNumber,
    };
  },
};
</script>

<template>
  <b-numberinput
    v-model="valueNumber"
    :max="max"
    :min="min"
    :placeholder="format"
    controls-position="compact"
    controls-rounded
    :step="format === 'float' ? 0.001 : 1"
    type="is-light"
    @blur="updateValueNumber"
    @input="updateValueNumber"
  >
  </b-numberinput>
</template>

<style scoped lang="scss"></style>
