export class ReferenceScope {
  constructor(data, requiredAuthorizations) {
    this.class = "ReferenceScope";
    this.id = data.id;
    this.datatype = data.datatype;
    this.i18n = data.i18n;
    this.nodes = (data.nodes || []).map((node) => new Node(node, this, requiredAuthorizations));
    this.requiredAuthorizations = requiredAuthorizations;
  }

  findNodeById(id) {
    const findNode = (nodes) => {
      for (const node of nodes) {
        if (node.id === id) return node;
        if (node.children) {
          const found = findNode(node.children);
          if (found) return found;
        }
      }
      return null;
    };
    return findNode(this.nodes);
  }

  isNodeChecked(nodeId) {
    const node = this.findNodeById(nodeId);
    return node ? node.checked : false;
  }

  isDescendantOf(descendantPath, ancestorPath) {
    const descendantNode = this.findNodeById(descendantPath);
    const ancestorNode = this.findNodeById(ancestorPath);

    if (!descendantNode || !ancestorNode) {
      return false;
    }

    let currentNode = descendantNode;
    while (currentNode) {
      if (currentNode === ancestorNode) {
        return true;
      }
      currentNode = currentNode.parent;
    }
    return false;
  }
}

class Node {
  constructor(data, parent, requiredAuthorizations) {
    this.class = "Node";
    this.id = data.id;
    this.type = data.type;
    this.naturalKey = data.naturalKey;
    this.i18n = data.i18n;
    this.requiredAuthorizations = requiredAuthorizations;
    this.children = data.children
      ? data.children.map((child) => new Node(child, this, requiredAuthorizations))
      : [];
    this.matchingPathes = data.matchingPathes;
    this.parent = parent;
    this.checked = this.isSelected();
    this.indeterminate = !this.checked && this.haveSelectedChild();
  }

  isAuthorized() {
    return this.requiredAuthorizations.some(
      (auth) =>
        this.id === auth ||
        auth.startsWith(this.id + ".") ||
        this.matchingPathes.some((path) => path.sql === auth || auth.startsWith(path.sql + "."))
    );
  }

  isSelected() {
    if ((this.requiredAuthorizations || []).includes(this.id)) {
      return true;
    }

    let currentParent = this.parent;
    while (currentParent) {
      if ((this.requiredAuthorizations || []).includes(currentParent.id)) {
        return true;
      }
      currentParent = currentParent.parent;
    }

    return false;
  }

  haveSelectedChild() {
    if (this.isSelected()) {
      return false;
    }
    return this.children.some((child) => child.isSelected() || child.haveSelectedChild());
  }

  getState() {
    if (this.isSelected()) {
      return { checked: true, indeterminate: false };
    }

    if (this.children.length === 0) {
      return { checked: false, indeterminate: false };
    }

    const childrenStates = this.children.map((child) => child.getState());
    const allChecked = childrenStates.every((state) => state.checked);
    const someChecked = childrenStates.some((state) => state.checked || state.indeterminate);

    if (allChecked) {
      return { checked: true, indeterminate: false };
    } else if (someChecked) {
      return { checked: false, indeterminate: true };
    } else {
      return { checked: false, indeterminate: false };
    }
  }
}
