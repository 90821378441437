<template>
  <SidePanel
    :close-cb="closeCb"
    :left-align="leftAlign"
    :open="open"
    :title="refOrData && (refOrData.dataNameLocal || refOrData.localName || refOrData.label)"
  >
    {{ refOrData }}
    <div
      v-if="refOrData.tags && refOrData.tags.length > 0 && !refOrData.tags.includes('no-tag')"
      class="columns"
    >
      <caption>
        {{
          $t("tags.tag")
        }}
        {{
          $t("ponctuation.colon")
        }}
      </caption>
      <TagsInfos :info-parent="refOrData" :tags-column="tags" :tags-menu="true"></TagsInfos>
    </div>
    <div v-if="reference" class="Panel-buttons">
      <b-button
        disabled="true"
        icon-left="trash-alt"
        type="is-danger"
        @click="askDeletionConfirmation"
        >{{ $t("referencesManagement.delete") }}
      </b-button>
    </div>
  </SidePanel>
</template>

<script>
import SidePanel from "../common/SidePanel.vue";
import { HttpStatusCodes } from "@/utils/HttpUtils";
import services from "@/composable/services";
import { i18n } from "@/main";
import useArray from "@/composable/components/array";
import { useRedirections } from "@/composable/applications/useFunction";
import TagsInfos from "@/components/common/TagsInfos.vue";

export default {
  name: "ReferencesDetailsPanel",
  props: {
    leftAlign: {
      type: Boolean,
      default: false,
    },
    open: {
      type: Boolean,
      default: false,
    },
    refOrData: Object,
    reference: {
      type: Boolean,
      default: false,
    },
    dataType: {
      type: Boolean,
      default: false,
    },
    applicationName: String,
    closeCb: Function,
    tags: Object,
    canManageRights: Boolean,
  },
  components: {
    TagsInfos,
    SidePanel,
  },
  setup(props) {
    const { shallowRefArray: errorsMessages, doChangeArray: changeErrorsMessages } = useArray();
    const { shallowRefArray: errorsList } = useArray();
    const { showReferenceRights, showDataRights } = useRedirections();

    function askDeletionConfirmation() {
      services.alertService.dialog(
        i18n.t("alert.warning"),
        i18n.t("alert.purge-reference-msg", {
          label: props.refOrData.localName ? props.refOrData.localName : props.refOrData.label,
        }),
        i18n.t("alert.delete"),
        "is-danger",
        i18n.t("alert.cancel"),
        () => deleteReference()
      );
    }

    async function deleteReference() {
      try {
        await services.dataService.delete(props.applicationName, props.refOrData.label);
        if (props.reference) services.alertService.toastSuccess(i18n.t("alert.reference-updated"));
      } catch (errors) {
        await checkMessageErrors(errors);
      }
    }

    async function checkMessageErrors(errors) {
      if (errors.httpResponseCode === HttpStatusCodes.BAD_REQUEST) {
        errors.content.then((value) => {
          for (let i = 0; i < value.length; i++) {
            errorsList.value[i] = value[i];
          }
          if (errorsList.value.length !== 0) {
            changeErrorsMessages(services.errorsService.getCsvErrorsMessages(errorsList));
          } else {
            changeErrorsMessages(services.errorsService.getErrorsMessages(errors));
          }
        });
      } else {
        services.alertService.toastError(i18n.t("alert.delete-reference-error"), errors);
      }
    }

    function consultAuthorization() {
      if (props.reference) {
        showReferenceRights(props.applicationName);
      }
      if (props.dataType) {
        showDataRights(props.applicationName);
      }
    }

    return {
      showReferenceRights,
      showDataRights,
      askDeletionConfirmation,
      consultAuthorization,
      errorsMessages,
    };
  },
};
</script>

<style lang="scss" scoped>
.Panel-buttons {
  display: flex;
  flex-direction: column;

  .button {
    margin-bottom: 0.5rem;
  }
}
</style>
