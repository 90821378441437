import services from "@/composable/services";
import { Locales } from "@/utils/LocaleUtils.js";
import useText from "@/composable/components/text.js";

const chosenLocale = useText().refText;
const locales = Locales;

const setUserPrefLocale = function () {
  window.location.reload();
  services.userPreferencesService.setUserPrefLocale(chosenLocale.value);
};
export { chosenLocale as chosenLocale, locales as locales, setUserPrefLocale as setUserPrefLocale };
