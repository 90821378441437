<template>
  <div class="PageView" role="main">
    <MenuView :application="application" v-if="hasMenu" />
    <div :class="`column PageView-container ${hasMenu ? '' : 'noMenu'}`">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import services from "@/composable/services";
import MenuView from "@/views/common/MenuView.vue";

export default {
  name: "PageView",
  props: {
    application: {
      type: Object,
    },
    hasMenu: {
      type: Boolean,
      default: true,
    },
  },
  components: { MenuView },
  setup() {
    const doCreated = function () {
      const authenticatedUser = services.loginService.getAuthenticatedUser();
      if (!authenticatedUser?.id) {
        this.$router.push("/login").catch(() => {});
      }
    };
    return { doCreated };
  },
};
</script>

<style lang="scss" scoped>
.PageView {
  height: 100%;

  &.with-submenu {
    .PageView-container {
      padding-top: 0;
    }
  }
}

.PageView-container {
  width: 100%;
  height: calc(100% - #{$menu-height});
  padding: $container-padding-vert $container-padding-hor;
  position: relative;

  &.noMenu {
    height: 100%;
  }
}
</style>
