<template>
  <PageView class="with-submenu">
    <SubMenu
      :aria-label="$t('menu.aria-sub-menu')"
      :paths="subMenuPaths"
      :root="application.localName"
      role="navigation"
    />
    <h1 class="title main-title">
      {{ $t("titles.authorization-application-management", { name: application.localName }) }}
    </h1>
    <div class="rows">
      <LoadingAnimate v-if="isLoading" :size="'is-large'"></LoadingAnimate>
      <b-table
        v-else
        :current-page="currentPage"
        :data="listUsersAuth"
        class="row"
        paginated
        per-page="15"
      >
        <template #pagination>
          <b-pagination
            v-model="currentPage"
            :aria-current-label="$t('menu.aria-curent-page')"
            :aria-label="$t('menu.aria-pagination')"
            :aria-next-label="$t('menu.aria-next-page')"
            :aria-previous-label="$t('menu.aria-previous-page')"
            :current-page.sync="currentPage"
            :rounded="true"
            :total="listUsersAuth.length"
            order="is-centered"
            per-page="15"
            range-after="3"
            range-before="3"
            role="navigation"
          />
        </template>
        <b-table-column
          :custom-search="search"
          :label="$t('dataTypeAuthorizations.users')"
          field="admin"
          searchable
          sortable
        >
          <template #searchable="props">
            <b-input
              v-model="props.filters[props.column.field]"
              :placeholder="$t('dataTypeAuthorizations.search')"
              icon="search"
              size="is-normal"
            />
          </template>
          <template v-slot="props">
            {{ props.row.login }}
          </template>
        </b-table-column>
        <b-table-column
          v-if="currentUser.applicationManager || currentUser.authorizedForApplicationCreation"
          v-slot="props"
          :label="$t('dataTypeAuthorizations.status-auth-application-manager')"
          field="applicationManager"
          sortable
        >
          <template>
            <b-checkbox
              v-model="props.row.applicationManager"
              @input="selectManagementApplication($event, props.row, 'applicationManager')"
            />
          </template>
        </b-table-column>
        <b-table-column
          v-if="currentUser.userManager || currentUser.authorizedForApplicationCreation"
          v-slot="props"
          :label="$t('dataTypeAuthorizations.status-auth-user-manager')"
          field="userManager"
          sortable
        >
          <template>
            <b-checkbox
              v-model="props.row.userManager"
              @input="selectManagementApplication($event, props.row, 'userManager')"
            />
          </template>
        </b-table-column>
      </b-table>
      <div class="row">
        <div class="column is-offset-10 is-2">
          <b-button
            icon-left="floppy-disk"
            icon-pack="far"
            type="is-primary is-right"
            @click="registerChanges"
          >
            {{ $t("dataTypeAuthorizations.save") }}
          </b-button>
        </div>
      </div>
    </div>
  </PageView>
</template>

<script>
import SubMenu from "@/components/common/SubMenu.vue";
import PageView from "../common/PageView.vue";
import { SubMenuPath } from "@/components/common/SubMenu";
import LoadingAnimate from "@/components/common/LoadingAnimate.vue";
import { onMounted, ref } from "vue";
import useArray from "@/composable/components/array";
import useObject from "@/composable/components/object";
import app, { i18n } from "@/main";
import services from "@/composable/services";
import { ApplicationResult } from "@/model/ApplicationResult";

export default {
  name: "AuthorizationsManagementForApplicationUsersView",
  components: {
    LoadingAnimate,
    PageView,
    SubMenu,
  },
  props: {
    applicationName: String,
  },
  setup(props) {
    const { shallowRefArray: subMenuPaths, doChangeArray: changeSubMenuPaths } = useArray();
    const { shallowRefArray: listUsersAuth, doChangeArray: changeListUsersAuth } = useArray();
    const { reactiveObject: application, doChangeObject: changeApplication } = useObject(
      new ApplicationResult()
    );
    const { reactiveObject: changes, doChangeObject: changeChanges } = useObject({
      applicationManager: { add: [], remove: [] },
      userManager: { add: [], remove: [] },
    });
    let totalRows = -1;
    let currentPage = 1;
    const isLoading = ref(false);
    const currentUser = services.loginService.getAuthenticatedUser();
    onMounted(async () => {
      await init();
      changeSubMenuPaths([
        new SubMenuPath(
          i18n
            .t("titles.authorization-application-management", { name: application.localName })
            .toLowerCase(),
          () =>
            app.$router.push(
              `/applications/${props.applicationName}/authorizationsManagementForApplicationUsers`
            ),
          () => app.$router.push(`/applications/${props.applicationName}`)
        ),
      ]);
    });

    function search(user, search) {
      return user.login.match(search);
    }

    async function init() {
      isLoading.value = true;
      changeChanges({
        applicationManager: { add: [], remove: [] },
        userManager: { add: [], remove: [] },
      });
      if (Object.keys(listUsersAuth.value).length === 0) {
        changeListUsersAuth(
          await services.authorizationService.getAdminAuthorizationsForApplication(
            props.applicationName
          )
        );
      }
      changeApplication(
        await services.applicationService.getApplication(props.applicationName, [
          "CONFIGURATION",
          "DATATYPE",
          "REFERENCETYPE",
        ])
      );
      isLoading.value = false;
    }

    async function registerChanges() {
      await makeChanges();
      await init();
      //window.location.reload();
    }

    async function makeChanges() {
      let localeListUsersAuth = listUsersAuth.value;
      if (changes.applicationManager.add.length !== 0) {
        changes.applicationManager.add.forEach((userId) => {
          localeListUsersAuth.filter((user) => user.id === userId)[0].applicationManager = true;
          services.authorizationService.createAuthorizedRole(
            "applicationManager",
            userId,
            null,
            props.applicationName
          );
        });
      } else if (changes.applicationManager.remove.length !== 0) {
        changes.applicationManager.remove.forEach((userId) => {
          localeListUsersAuth.filter((user) => user.id === userId)[0].applicationManager = false;
          services.authorizationService.revokeAuthorizedRole(
            "applicationManager",
            userId,
            null,
            props.applicationName
          );
        });
      }
      if (changes.userManager.add.length !== 0) {
        changes.userManager.add.forEach((userId) => {
          localeListUsersAuth.filter((user) => user.id === userId)[0].userManager = true;
          services.authorizationService.createAuthorizedRole(
            "userManager",
            userId,
            null,
            props.applicationName
          );
        });
      } else if (changes.userManager.remove.length !== 0) {
        changes.userManager.remove.forEach((userId) => {
          localeListUsersAuth.filter((user) => user.id === userId)[0].userManager = false;
          services.authorizationService.revokeAuthorizedRole(
            "userManager",
            userId,
            null,
            props.applicationName
          );
        });
      }
    }

    function selectManagementApplication(value, user, type) {
      if (value && type === "userManager") {
        if (changes.userManager.remove.find((v) => v === user.id)) {
          changes.userManager.remove = changes.userManager.remove.filter((v) => v === v.id);
        } else if (!changes.userManager.add.find((v) => v === user.id)) {
          changes.userManager.add.push(user.id);
        }
      } else if (!value && type === "userManager") {
        if (changes.userManager.add.find((v) => v === user.id)) {
          changes.userManager.add = changes.userManager.add.filter((v) => v === v.id);
        } else if (!changes.userManager.remove.find((v) => v === user.id)) {
          changes.userManager.remove.push(user.id);
        }
      }
      if (value && type === "applicationManager") {
        if (changes.applicationManager.remove.find((v) => v === user.id)) {
          changes.applicationManager.remove = changes.applicationManager.remove.filter(
            (v) => v === v.id
          );
        } else if (!changes.applicationManager.add.find((v) => v === user.id)) {
          changes.applicationManager.add.push(user.id);
        }
      } else if (!value && type === "applicationManager") {
        if (changes.applicationManager.add.find((v) => v === user.id)) {
          changes.applicationManager.add = changes.applicationManager.add.filter((v) => v === v.id);
        } else if (!changes.applicationManager.remove.find((v) => v === user.id)) {
          changes.applicationManager.remove.push(user.id);
        }
      }
    }

    return {
      subMenuPaths,
      application,
      listUsersAuth,
      totalRows,
      currentPage,
      isLoading,
      search,
      changes,
      currentUser,
      registerChanges,
      makeChanges,
      selectManagementApplication,
    };
  },
};
</script>
<style lang="scss" scoped>
.icon {
  font-size: 0.5rem;
}

.control.has-icons-left .icon {
  top: 5px;
}
</style>
