<template>
  <PageView class="with-submenu" :application="application">
    <SubMenu
      :aria-label="$t('menu.aria-sub-menu')"
      :paths="subMenuPaths"
      :root="application.localName || application.title"
      role="navigation"
    />
    <h1 class="title main-title">
      <b-tooltip :label="application.localDescription">
        {{
          type[type.length - 1] === "dataTypes"
            ? $t("titles.data-types-page", {
                applicationName: application.localName || application.title,
              })
            : $t("titles.references-page", {
                applicationName: application.localName || application.title,
              })
        }}
      </b-tooltip>
      <b-tooltip :label="$t('dataTypesManagement.consult-authorization')" position="is-bottom">
        <b-button
          icon-left="key"
          type="is-warning"
          @click="consultAuthorization"
          outlined
          rounded
        />
      </b-tooltip>
    </h1>
    <div v-if="errorsMessages.length !== 0" style="margin: 10px">
      <ShowErrors
        :errors-messages="errorsMessages"
        :errors-messages-length="errorsMessages.length"
        :title="$t('message.data-type-config-error')"
      ></ShowErrors>
    </div>
    <div id="tagsCollapse" class="column">
      <TagsCollapse
        v-if="hasTags"
        :tags="tags"
        @change:tag="changeTagSelected($event, 'changeTagSelected')"
        @change:allTags="changeAllValueTags($event)"
      />
    </div>
    <LoadingAnimate v-if="isLoading" :size="'is-small'"></LoadingAnimate>
    <div class="section">
      <CollapsibleTree
        v-for="(data, i) in datasToBeShown"
        :id="i + 1"
        :key="data.id"
        :application-name="applicationName"
        :application-title="$t('titles.references-page', { applicationName: applicationName })"
        :buttons="buttons(data)"
        :is-loading="isLineCountLoading"
        :is-uploading="isUploading"
        :level="0"
        :line-count="lineCountSynthesis(application.referenceSynthesis, data)"
        :on-click-label-cb="(event, label) => openRefDetails(event, label)"
        :on-upload-cb="
          withVersioning(data.id) ? null : (label, file) => uploadReferenceCsv(label, file)
        "
        :repository="data.submission"
        :repository-redirect="(label) => showVersioning(label)"
        :option="data"
        :reference-synthesis="application.referenceSynthesis"
        :show-empty="showInfoEmpty[data.id]"
        :tags="tags"
        class="liste"
      >
      </CollapsibleTree>
      <DetailsPanel
        :application-name="applicationName"
        :close-cb="(newVal) => (openPanel = newVal)"
        :left-align="false"
        :open="openPanel"
        :ref-or-data="chosenRef"
        :reference="true"
        :tags="tags"
      />
    </div>
  </PageView>
</template>

<script>
import CollapsibleTree from "@/components/common/CollapsibleTree.vue";
import TagsCollapse from "@/components/common/TagsCollapse.vue";
import DetailsPanel from "@/components/common/DetailsPanel.vue";
import PageView from "../common/PageView.vue";
import { ApplicationResult } from "@/model/ApplicationResult";
import SubMenu, { SubMenuPath } from "@/components/common/SubMenu.vue";
import { Button } from "@/model/Button";
import { HttpStatusCodes } from "@/utils/HttpUtils";
import { i18n } from "@/main";
import app from "@/main";
import useArray from "@/composable/components/array";
import useBoolean from "@/composable/components/boolean";
import useNumber from "@/composable/components/number";
import useObject from "@/composable/components/object";
import { onMounted } from "vue";
import services from "@/composable/services";
import { buildTags } from "@/composable/application/tags";
import { lineCountSynthesis } from "@/composable/application/synthesis";
import LoadingAnimate from "@/components/common/LoadingAnimate.vue";
import ShowErrors from "@/components/application/ShowErrors.vue";
import { Tag } from "@/model/application/Tag";

export default {
  name: "DatasManagementView",
  components: {
    ShowErrors,
    LoadingAnimate,
    CollapsibleTree,
    TagsCollapse,
    DetailsPanel,
    PageView,
    SubMenu,
  },
  props: {
    applicationName: {
      type: String,
    },
  },
  setup(props) {
    const { shallowRefArray: datas, doChangeArray: changeDatas } = useArray();
    const { shallowRefArray: subMenuPaths, doChangeArray: changeSubMenuPaths } = useArray();
    const { shallowRefArray: errorsMessages, doChangeArray: changeErrorsMessages } = useArray();
    const { shallowRefArray: errorsList } = useArray();
    const { shallowRefArray: showInfoEmpty } = useArray();
    const { refNumber: currentPage } = useNumber(1);
    const { refBoolean: openPanel, doChangeBoolean: changeOpenPanel } = useBoolean();
    const { refBoolean: isUploading, doChangeBoolean: changeIsUploading } = useBoolean();
    const { refBoolean: isLoading, doChangeBoolean: changeIsLoading } = useBoolean();
    const { refBoolean: isLineCountLoading, doChangeBoolean: changeIsLineCountLoading } =
      useBoolean();
    const { refBoolean: canManageRights } = useBoolean();
    const { reactiveObject: chosenRef, doChangeObject: changeChosenRef } = useObject();
    const { reactiveObject: tags, doChangeObject: _changeTags } = useObject();
    const { shallowRefArray: datasToBeShown, doChangeArray: changeDatasToBeShown } = useArray();
    const { reactiveObject: application, doChangeObject: changeApplication } = useObject(
      new ApplicationResult()
    );
    const type = window.location.href.split("/");
    const hasTags = useBoolean(false).refBoolean;
    const changeTags = function (tagsToChange) {
      _changeTags({ ...tagsToChange });
      hasTags.value =
        tags &&
        Object.keys(tags || {}).length !== 0 &&
        !(Object.keys(tags || {}).length === 1 && Object.keys(tags).includes(Tag.NO_TAG_NAME));
      changeDatasToBeShown(services.tagService.toBeShown(tags, datas.value));
    };

    function changeAllValueTags(allTags) {
      for (let key in tags) {
        tags[key].selected = allTags;
      }
      changeTags(tags);
    }

    async function changeTagSelected(tagsToChange) {
      changeTags(tagsToChange);
    }

    onMounted(async () => {
      await init();
      for (let i = 0; i < datas.value.length; i++) {
        showInfoEmpty.value[datas.value[i].id] = { value: false };
      }
      changeSubMenuPaths([
        new SubMenuPath(
          type[type.length - 1] === "dataTypes"
            ? i18n.t("dataTypesManagement.data-types").toLowerCase()
            : i18n.t("referencesManagement.references").toLowerCase(),
          () => app.$router.push(`/applications/${props.applicationName}/${type[type.length - 1]}`),
          () => app.$router.push(`/applications/${props.applicationName}`)
        ),
      ]);
      changeTags(buildTags(application, datas.value));
      changeDatas(services.tagService.toBeShown(tags, datas.value));
    });

    function addAuthorizationToData(reference) {
      let authorizations = application.authorizations[reference.label] || { ADMIN: true };
      let isAdmin = authorizations.ADMIN;
      let canUpload = isAdmin || authorizations.UPLOAD;
      let canRead = isAdmin || authorizations.DOWNLOAD;
      let canDownload = isAdmin || authorizations.DOWNLOAD;
      let canDelete = isAdmin || authorizations.DELETE;
      let any = isAdmin || authorizations.ANY;
      let children = (reference.children || []).map(addAuthorizationToData);
      return {
        ...reference,
        children,
        autorizations: application.authorizations[reference],
        canUpload: canUpload,
        canRead: canRead,
        canDownload: canDownload,
        canDelete: canDelete,
        isAdmin: isAdmin,
        canShow: any,
      };
    }

    async function init() {
      changeIsLoading(true);
      try {
        if (type[type.length - 1] === "dataTypes") {
          changeApplication(
            await services.applicationService.getApplication(props.applicationName, [
              "CONFIGURATION",
              "DATATYPE",
              "REFERENCETYPE",
            ])
          );
          if (!application?.id) {
            return;
          }
          changeDatas(
            Object.values(services.internationalisationService.treeDataTypesName(application)).map(
              addAuthorizationToData
            )
          );
          datas.value.map(
            (data) =>
              (data.submission = application.configuration.dataDescription[data.id].submission)
          );
          canManageRights.value = datas.value.some((dty) => dty.isAdmin);
        } else {
          changeApplication(
            await services.applicationService.getApplication(props.applicationName, [
              "CONFIGURATION",
              "REFERENCETYPE",
            ])
          );
          if (!application?.id) {
            return;
          }
          changeDatas(
            Object.values(services.internationalisationService.treeReferenceName(application)).map(
              addAuthorizationToData
            )
          );
        }
      } catch (error) {
        services.alertService.toastServerError();
      }
      changeIsLoading(false);
    }

    function buttons(reference) {
      return [
        new Button(
          i18n.t("referencesManagement.consult"),
          "eye",
          (label) => consultReference(label),
          "is-dark",
          null,
          !reference.canRead
        ),
        new Button(
          i18n.t("referencesManagement.download"),
          "download",
          (label) => downloadReference(label),
          null,
          null,
          !reference.canDownload
        ),
      ];
    }

    function openRefDetails(event, label) {
      event.stopPropagation();
      changeOpenPanel(chosenRef && chosenRef.label === label ? !openPanel.value : true);
      changeChosenRef(findReferenceByLabel(label));
    }

    function consultReference(label) {
      const ref = findReferenceByLabel(label);
      if (ref) {
        app.$router.push(
          `/applications/${props.applicationName}/${type[type.length - 1]}/${ref.id}`
        );
      }
    }

    async function downloadReference(label) {
      const reference = findReferenceByLabel(label);
      if (reference) {
        let csv = await services.dataService.getDataCsv(props.applicationName, reference.id);
        const hiddenElement = document.createElement("a");
        hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);

        //provide the name for the CSV file to be downloaded
        hiddenElement.download = "export.csv";
        hiddenElement.click();
        return false;
      }
    }

    async function uploadReferenceCsv(label, refFile) {
      changeIsLineCountLoading(true);
      changeIsUploading(true);
      changeErrorsMessages([]);
      const reference = findReferenceByLabel(label);
      try {
        const referenceType = reference.id;
        const result = await services.dataService.addData(
          props.applicationName,
          referenceType,
          refFile
        );
        let referenceSynthesis = result.referenceSynthesis;
        changeApplication({
          ...application,
          referenceSynthesis,
        });
        services.alertService.toastSuccess(i18n.t("alert.reference-updated"));
        showInfoEmpty.value[referenceType] = { value: true };
      } catch (errors) {
        await checkMessageErrors(errors);
      }
      changeIsUploading(false);
      changeIsLineCountLoading(false);
    }

    async function checkMessageErrors(errors) {
      if (errors.httpResponseCode === HttpStatusCodes.BAD_REQUEST) {
        errors.content.then((value) => {
          for (let i = 0; i < value.length; i++) {
            errorsList.value[i] = value[i];
          }
          if (errorsList.value.length !== 0) {
            //changeTagSelected(errorsList.value);
            changeErrorsMessages(services.errorsService.getCsvErrorsMessages(errorsList.value));
          } else {
            changeErrorsMessages(
              services.errorsService.getErrorsMessages(errors, null, "errors-csv.")
            );
          }
        });
      } else {
        services.alertService.toastError(i18n.t("alert.reference-csv-upload-error"), errors);
      }
    }

    function findReferenceByLabel(label) {
      return findReferenceByLabelOnElement(datasToBeShown.value, label);
    }

    function findReferenceByLabelOnElement(element, label) {
      if (!element) return false;
      return element
        .map((ref) =>
          ref.label === label ? ref : findReferenceByLabelOnElement(ref.children, label)
        )
        .find((t) => t);
    }
    function withVersioning(dataId) {
      return !!(
        application.configuration.dataDescription[dataId]?.submission &&
        application.configuration.dataDescription[dataId].submission.strategy === "OA_VERSIONING"
      );
    }

    function showVersioning(label) {
      const data = datas.value.find((dt) => dt.label === label);
      app.$router.push(`/applications/${props.applicationName}/dataVersioning/${data.id}`);
    }

    function consultAuthorization() {
      app.$router.push(`/applications/${props.applicationName}/authorizations`);
    }

    return {
      openRefDetails,
      uploadReferenceCsv,
      changeTagSelected,
      changeAllValueTags,
      buttons,
      withVersioning,
      showVersioning,
      lineCountSynthesis,
      tags,
      chosenRef,
      application,
      openPanel,
      subMenuPaths,
      datasToBeShown,
      errorsMessages,
      currentPage,
      isUploading,
      isLoading,
      showInfoEmpty,
      isLineCountLoading,
      hasTags,
      type,
      consultAuthorization,
    };
  },
};
</script>
<style lang="scss" scoped>
.liste {
  margin-bottom: 10px;
  border: 1px solid white;
}
</style>
