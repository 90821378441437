<script>
import { computed, onUpdated, ref } from "vue";
import InputDateInterval from "@/components/common/provider/InputDateInterval.vue";
import CollapseMenuMultiselect from "@/components/common/CollapseMenuMultiselect.vue";
import services from "@/composable/services";
import { ReferenceScope } from "@/model/authorization/ReferenceScope";

export default {
  name: "AuthorizationTableForOneDataType",
  computed: {
    ReferenceScope() {
      return ReferenceScope;
    },
  },
  emits: [
    "update:propagate-changes",
    "update:date-value-interval",
    "update:operation-type",
    "update:has-extraction",
  ],
  components: { CollapseMenuMultiselect, InputDateInterval },
  props: {
    authorization: Object,
    listColumnName: Object,
    data: Object,
    dataName: String,
    application: Object,
    applicationName: String,
    withTimeScope: Boolean,
    typeTimeScope: String,
    referenceScope: Array,
    hasDependency: Boolean,
  },
  setup(props, context) {
    const checkboxValues = ref({});
    const selectedValuesReference = computed(() =>
      props.authorization?.requiredAuthorizations ? props.authorization?.requiredAuthorizations : {}
    );
    const locale = services.internationalisationService.getLocale();

    // Fonction pour initialiser checkboxValues
    const initializeCheckboxValues = () => {
      if (props.authorization && props.authorization.operationTypes) {
        props.authorization.operationTypes.forEach((operationType) => {
          checkboxValues.value[`${props.dataName}___${operationType}`] = true;
        });
      }
    };
    const dateFromArray = (arrayDate) => {
      if (arrayDate && (arrayDate[0] === -999999999 || arrayDate[0] === 999999999)) {
        return null;
      }
      if (arrayDate) {
        let date = new Date(arrayDate[0], arrayDate[1] - 1, arrayDate[2]);
        return date;
      }
    };
    const getFromDate = computed(() => dateFromArray(props.authorization?.fromDay));
    const getToDate = computed(() => dateFromArray(props.authorization?.toDay));
    const hasExtraction = computed(
      () =>
        props.authorization.operationTypes &&
        ((props.authorization.operationTypes.has &&
          props.authorization.operationTypes.has("extraction")) ||
          (props.authorization.operationTypes.includes &&
            props.authorization.operationTypes.includes("extraction")))
    );
    const localHasExtraction = ref(false);
    const modifyOperationType = (event, name) => {
      let operationtypes = new Set();
      for (const operationtype of props.authorization?.operationTypes || new Set()) {
        operationtypes.add(operationtype);
      }
      if (!event) {
        operationtypes.delete(name);
      } else {
        operationtypes.add(name);
      }
      context.emit("update:operation-type", operationtypes);
    };
    onUpdated(() => {
      localHasExtraction.value =
        (Array.isArray(props.authorization.operationTypes)
          ? props.authorization.operationTypes.includes("extraction")
          : props.authorization.operationTypes.has("extraction")) &&
        Object.keys(props.authorization).includes("timeScope");
      context.emit("update:has-extraction", localHasExtraction.value);
    });
    const displayNodeTagHierarchical = ref({});

    // Appeler la fonction d'initialisation
    initializeCheckboxValues();

    function displayNodeTag(referenceScopeNode, hierarchicalTagName) {
      if (referenceScopeNode.id === hierarchicalTagName) {
        if (referenceScopeNode.i18n) {
          displayNodeTagHierarchical.value[hierarchicalTagName] = referenceScopeNode.i18n[locale];
          return referenceScopeNode.i18n[locale];
        } else {
          displayNodeTagHierarchical.value[hierarchicalTagName] = referenceScopeNode.naturalKey;
          return referenceScopeNode.naturalKey;
        }
      } else {
        for (let i = 0; referenceScopeNode.children.length > i; i++) {
          displayNodeTag(referenceScopeNode.children[i], hierarchicalTagName);
        }
      }
    }

    function rootReferenceScopeSelected(hierarchicalTagName, referenceScopesByDataId) {
      for (let i = 0; props.referenceScope.length > i; i++) {
        if (
          props.referenceScope[i].datatype === props.dataName &&
          props.referenceScope[i].id === referenceScopesByDataId
        ) {
          for (let j = 0; props.referenceScope[i].nodes.length > j; j++) {
            if (props.referenceScope[i].nodes[j].id === hierarchicalTagName) {
              if (props.referenceScope[i].nodes[j].i18n) {
                return props.referenceScope[i].nodes[j].i18n[locale];
              } else {
                return props.referenceScope[i].nodes[j].naturalKey;
              }
            } else {
              displayNodeTag(props.referenceScope[i].nodes[j], hierarchicalTagName);
            }
          }
        }
      }
      return displayNodeTagHierarchical.value[hierarchicalTagName]
        ? displayNodeTagHierarchical.value[hierarchicalTagName]
        : hierarchicalTagName.split("K").pop();
    }

    return {
      selectedValuesReference,
      checkboxValues,
      locale,
      getFromDate,
      getToDate,
      modifyOperationType,
      hasExtraction,
      localHasExtraction,
      rootReferenceScopeSelected,
      displayNodeTagHierarchical,
    };
  },
};
</script>

<template>
  <div>
    <div class="columns">
      <table class="table is-bordered is-striped is-narrow is-hoverable">
        <thead>
          <tr>
            <th
              v-for="(column, key) in listColumnName"
              :key="dataName + '___' + key"
              :label="column.internationalizationName.fr"
            >
              {{ column.internationalizationName.fr }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td v-for="(column, key) in listColumnName" :key="dataName + '___' + key">
              <b-checkbox
                v-model="checkboxValues[data.id + '___' + key]"
                @input="modifyOperationType($event, key)"
              >
              </b-checkbox>
            </td>
          </tr>
        </tbody>
      </table>
      <InputDateInterval
        v-if="withTimeScope && (hasExtraction || localHasExtraction)"
        :format="'dd/MM/yyyy HH:mm:ss'"
        :from="getFromDate"
        :input-type="typeTimeScope"
        :to="getToDate"
        :has-extraction="hasExtraction || localHasExtraction"
        class="column is-3"
        @update:date-value-interval="
          $emit('update:date-value-interval', $event.intervalAsDatesArray)
        "
      >
      </InputDateInterval>
    </div>
    <div class="columns">
      <b-field
        v-for="referenceScopesByData in referenceScope"
        :key="referenceScopesByData.id"
        :label="referenceScopesByData.i18n[locale] || referenceScopesByData.id"
        class="column is-3"
      >
        <b-dropdown
          :ref="referenceScopesByData.id"
          expanded
          max-height="500"
          scrollable
          width="500"
        >
          <template #trigger="{ active }">
            <b-taginput
              v-model="selectedValuesReference[referenceScopesByData.id]"
              :icon-right="active ? 'angle-up' : 'angle-down'"
              open-on-focus
              placeholder="Select some tags"
            >
              <template #selected="props">
                <b-tag
                  v-for="(tag, index) in props.tags"
                  :id="referenceScopesByData.id + '-_-' + tag"
                  :key="index"
                  rounded
                  type="is-dark"
                >
                  {{ rootReferenceScopeSelected(tag, referenceScopesByData.id) }}
                </b-tag>
              </template>
            </b-taginput>
          </template>
          <CollapseMenuMultiselect
            v-for="option in referenceScopesByData.nodes"
            :id="option.id"
            :key="option.id"
            :datatype="data.id"
            :is-checked="true"
            :option="option"
            :reference-scope="
              new ReferenceScope(
                referenceScopesByData,
                authorization?.requiredAuthorizations?.[referenceScopesByData.id]
              )
            "
            :required-authorizations="
              authorization?.requiredAuthorizations?.[referenceScopesByData.id]
            "
            :scope="referenceScopesByData.id"
            @select-menu-item="
              $emit('update:info-auth-ref-scope', { ...$event, datatype: data.id })
            "
            @propagate-changes="
              $emit('update:propagate-changes', {
                changes: $event,
                datatype: data.id,
                scope: referenceScopesByData.id,
              })
            "
          ></CollapseMenuMultiselect>
        </b-dropdown>
      </b-field>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
