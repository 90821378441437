export class ApplicationConfig {
  file;
  name;
  comment;

  constructor() {
    this.file = null;
    this.name = "";
    this.comment = "";
  }
}
