<template>
  <PageView class="LoginView" :has-menu="false">
    <h1 class="title main-title">{{ $t("titles.login-page") }}</h1>
    <b-image :src="require('@/assets/Logos_OpernADOM.svg')" alt="The Open ADOM Logo"></b-image>
    <div class="card LoginView-card">
      <b-tabs v-model="selectedTab" type="is-boxed" :animated="false">
        <b-tab-item :label="$t('login.signin')" icon="sign-in-alt" tabindex="0">
          <SignIn />
        </b-tab-item>
        <b-tab-item
          :label="$t('login.register')"
          icon="user-plus"
          @keyup.native.enter="changeTabToSignIn"
          tabindex="0"
        >
          <Register @user:registered="changeTabToSignIn()" />
        </b-tab-item>
      </b-tabs>
    </div>
  </PageView>
</template>

<script>
import SignIn from "@/components/login/Signin.vue";
import PageView from "./common/PageView.vue";
import useText from "@/composable/components/text";
import useNumber from "@/composable/components/number";
import { provide } from "vue";

const { refNumber: selectedTab, doChangeNumber } = useNumber();

export default {
  name: "LoginView",
  components: { PageView, SignIn },
  setup() {
    provide("login", useText().refText);
    provide("email", useText().refText);
    provide("changeTabToSignIn", changeTabToSignIn);
    function changeTabToSignIn() {
      doChangeNumber(0);
    }
    return {
      //from "@/composition/components/number"
      selectedTab,
      changeTabToSignIn,
    };
  },
};
</script>

<style lang="scss">
.LoginView {
  display: flex;
  flex-direction: column;
  align-items: center;

  .PageView-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .LoginView-card {
    width: 70%;
  }
}
</style>
