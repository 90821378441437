<template>
  <PageView :application="application" class="with-submenu">
    <SubMenu
      :aria-label="$t('menu.aria-sub-menu')"
      :paths="subMenuPaths"
      :root="application.localName || application.name"
      role="navigation"
    />
    <h1 class="title main-title">
      {{
        $t("titles.data-type-authorizations", {
          dataType: application.localName || application.name,
          type: "autorisation",
        })
      }}
    </h1>

    <div class="rows">
      <div class="row">
        <div class="columns" style="margin: 0">
          <div class="column is-2">
            <b-switch
              v-model="hasAuthorizationsByUsers"
              :disabled="hasPublicAuthorizations"
              passive-type="is-dark"
              type="is-primary"
              @input="updateTableAuthorizations"
            >
              {{
                hasAuthorizationsByUsers
                  ? $t("dataTypeAuthorizations.table-auth-users")
                  : $t("dataTypeAuthorizations.table-auth-name")
              }}
            </b-switch>
          </div>
          <div class="column is-2">
            <b-checkbox
              v-if="listAuthorizationsByPublic.length !== 0"
              v-model="hasPublicAuthorizations"
              :disabled="hasAuthorizationsByUsers"
              @input="updateTableAuthorizations"
            >
              {{ $t("dataTypeAuthorizations.table-auth-public") }}
            </b-checkbox>
          </div>
          <div class="column is-offset-6 is-2" v-if="isApplicationManager">
            <b-button icon-left="plus" type="is-primary is-right" @click="addAuthorization">
              {{ $t("dataTypeAuthorizations.add-auhtorization") }}
            </b-button>
          </div>
        </div>
      </div>
      <div class="row">
        <!-- ajouter le paramettre si l'autorisation public existe ou non -->
        <div class="columns" style="margin: 0">
          <div class="column is-2">
            <b-radio
              v-if="hasAuthorizationsByUsers"
              v-model="filterAuthByUsers"
              name="Afficher les utilisateurs sans autorisation"
              native-value="userWithoutAuth"
              @input="displayUsersBasedOnAuth($event)"
            >
              {{ $t("dataTypeAuthorizations.table-auth-users-without-auth") }}
            </b-radio>
          </div>
          <div class="column is-2">
            <b-radio
              v-if="hasAuthorizationsByUsers"
              v-model="filterAuthByUsers"
              name="Afficher les utilisateurs avec autorisation"
              native-value="userWithAuth"
              @input="displayUsersBasedOnAuth($event)"
            >
              {{ $t("dataTypeAuthorizations.table-auth-users-with-auth") }}
            </b-radio>
          </div>
          <div class="column is-2">
            <b-radio
              v-if="hasAuthorizationsByUsers"
              v-model="filterAuthByUsers"
              name="Afficher tous utilisateurs"
              native-value="allUsers"
              @input="displayUsersBasedOnAuth($event)"
            >
              {{ $t("dataTypeAuthorizations.table-auth-users-all-user") }}
            </b-radio>
          </div>
          <div class="column is-offset-4 is-2"
               v-if="isApplicationManager">
            <b-button
              icon-left="plus"
              outlined
              type="is-primary is-right"
              @click="addPublicAuthorization()"
            >
              {{ $t("dataTypeAuthorizations.add-public-auhtorization") }}
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <LoadingAnimate v-if="isLoading" :size="'is-large'"></LoadingAnimate>
    <div class="columns">
      <!-- table des autorisations pas nom d'utilisateur -->
      <b-table
        class="column"
        v-if="hasAuthorizationsByUsers"
        :data="listAuthorizationsByUsersBasedOnAuth"
        :paginated="true"
        :per-page="perPage"
        :striped="true"
        default-sort="row.name"
        default-sort-direction="asc"
        height="100%"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        sortable
      >
        <template #pagination>
          <b-pagination
            v-model="currentPage"
            :aria-current-label="$t('menu.aria-curent-page')"
            :aria-label="$t('menu.aria-pagination')"
            :aria-next-label="$t('menu.aria-next-page')"
            :aria-previous-label="$t('menu.aria-previous-page')"
            :current-page.sync="currentPage"
            :per-page="perPage"
            :rounded="true"
            :total="authorizationLength"
            order="is-centered"
            range-after="3"
            range-before="3"
            role="navigation"
            @change="changePage"
          />
        </template>
        <b-table-column :searchable="true" field="name" label="Nom de l'utilisateur" sortable>
          <template #searchable="props">
            <b-input
              v-model="props.filters[props.column.field]"
              :placeholder="$t('dataTypeAuthorizations.search')"
              icon="search"
            />
          </template>
          <template v-slot="props">
            <p class="card-header-title">
              {{ props.row.name }}
            </p>
          </template>
        </b-table-column>
        <b-table-column
          :searchable="true"
          field="email"
          label="E-mail de l'utilisateur"
          sortable
          width="30em"
        >
          <template #searchable="props">
            <b-input
              v-model="props.filters[props.column.field]"
              :placeholder="$t('dataTypeAuthorizations.search')"
              icon="search"
            />
          </template>
          <template v-slot="props">
            <p class="card-header-title">
              {{ props.row.email }}
            </p>
          </template>
        </b-table-column>
        <b-table-column
          v-slot="props"
          :label="$t('dataTypeAuthorizations.name')"
          field="authorizations"
          sortable
          width="60em"
        >
          <template>
            <b-collapse
              :open="false"
              animation="slide"
              aria-id="list-auths"
              class="card"
              style="box-shadow: none; background-color: transparent"
            >
              <template #trigger="detail">
                <div
                  :aria-expanded="!detail.open"
                  aria-controls="list-auths"
                  class="card-header"
                  role="button"
                  style="box-shadow: none; background-color: transparent"
                >
                  <p class="card-header-title">
                    {{
                      $t("dataTypeAuthorizations.list-auths", {
                        numberAuths: props.row?.auth
                          ? props.row.auth.map((auth) => auth.id).length
                          : 0,
                      })
                    }}
                  </p>
                  <a class="card-header-icon">
                    <b-tooltip :label="$t('dataTypeAuthorizations.showMore')" position="is-left">
                      <b-icon :icon="detail.open ? 'chevron-up' : 'chevron-down'" type="is-primary">
                      </b-icon>
                    </b-tooltip>
                  </a>
                </div>
              </template>
              <div class="card-content">
                <div class="content">
                  <b-field
                    :label="$t('dataTypeAuthorizations.filter-auth-by-name')"
                    class="columns"
                  >
                    <b-input
                      :placeholder="$t('dataTypeAuthorizations.search')"
                      icon="search"
                      @input="filterListAuthName(listAuthorizations, $event)"
                    ></b-input>
                  </b-field>
                  <div class="columns is-multiline">
                    <b-field
                      v-for="auth in resultFilterAuthByName"
                      :key="auth.id"
                      class="column is-6"
                    >
                      <b-checkbox
                        v-model="checkbox[auth.name + '_' + props.row.name]"
                        :disabled="(isApplicationManager || isUserManager)"
                        @input="updateListUsersInAuthorization(auth, props.row.name, $event)"
                      >
                        {{ auth.name }}
                      </b-checkbox>
                      <b-button
                        icon-left="eye"
                        onmouseout="style.color='';"
                        onmouseover="style.color='rgb(0,157,157)'"
                        outlined
                        size="is-small"
                        style="
                          height: 1.5em;
                          background-color: transparent;
                          font-size: 1.45rem;
                          border-color: transparent;
                        "
                        type="is-dark"
                        @click="openAuthorizationModal(auth.uuid)"
                      >
                      </b-button>
                      <b-modal
                        v-if="isCardModalActive"
                        v-model="isCardModalActive"
                        class="modalByAgrégation"
                      >
                        <DetailAuthorizationModalCard
                          v-model="isCardModalActive"
                          :authorization="currentAuthorizationInModal"
                          :is-loading="isLoadingModal"
                          :open="isCardModalActive"
                          :application="application"
                          :lang="lang"
                          :list-column-name="listColumnName"
                        >
                        </DetailAuthorizationModalCard>
                      </b-modal>
                    </b-field>
                  </div>
                </div>
              </div>
            </b-collapse>
          </template>
        </b-table-column>
      </b-table>
      <!-- table des autorisations pas nom d'autorisation -->
      <b-table
        class="column"
        v-else
        :data="authorizations"
        :paginated="true"
        :per-page="perPage"
        :striped="true"
        default-sort="row.name"
        default-sort-direction="asc"
        height="100%"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        sortable
      >
        <template #pagination>
          <b-pagination
            v-model="currentPage"
            :aria-current-label="$t('menu.aria-curent-page')"
            :aria-label="$t('menu.aria-pagination')"
            :aria-next-label="$t('menu.aria-next-page')"
            :aria-previous-label="$t('menu.aria-previous-page')"
            :current-page.sync="currentPage"
            :per-page="perPage"
            :rounded="true"
            :total="authorizationLength"
            order="is-centered"
            range-after="3"
            range-before="3"
            role="navigation"
            @change="changePage"
          />
        </template>
        <b-table-column
          :label="$t('dataTypeAuthorizations.name')"
          :searchable="true"
          field="name"
          sortable
          width="26.125em"
        >
          <template #searchable="props">
            <b-input
              v-model="props.filters[props.column.field]"
              :placeholder="$t('dataTypeAuthorizations.search')"
              icon="search"
            />
          </template>
          <template v-slot="props">
            <b-collapse
              :open="false"
              animation="slide"
              aria-id="auths-name"
              class="card"
              style="box-shadow: none; background-color: transparent"
            >
              <template #trigger="detail">
                <div
                  aria-controls="auths-name"
                  class="card-header"
                  role="button"
                  style="box-shadow: none; background-color: transparent"
                >
                  <a class="card-header-icon" style="padding-left: 0; padding-right: 0">
                    <b-button
                      class="show-check-details"
                      disabled
                      icon-left="times-circle"
                      size="is-small"
                      style="
                        height: 1.5em;
                        background-color: transparent;
                        font-size: 1.45rem;
                        border: none;
                      "
                      type="is-danger is-light"
                      @click="revoke(props.row.uuid)"
                    >
                    </b-button>
                    <b-button
                      class="show-check-details"
                      icon-left="pen-square"
                      onmouseout="style.color='';"
                      onmouseover="style.color='rgba(255,140,0,0.5)'"
                      outlined
                      size="is-small"
                      style="
                        height: 1.5em;
                        background-color: transparent;
                        font-size: 1.45rem;
                        border-color: transparent;
                      "
                      type="is-warning"
                      @click="modifyAuthorization(props.row.uuid)"
                    >
                    </b-button>
                  </a>
                  <p class="card-header-title" style="padding-left: 0; padding-right: 0">
                    {{ props.row.name }}
                  </p>
                  <a class="card-header-icon" style="padding-right: 0">
                    <b-tooltip :label="$t('dataTypeAuthorizations.showMore')" position="is-left">
                      <b-icon :icon="detail.open ? 'chevron-up' : 'chevron-down'" type="is-primary">
                      </b-icon>
                    </b-tooltip>
                  </a>
                </div>
              </template>
              <div class="card-content">
                <div class="content">
                  <div class="columns">
                    <p>
                      <strong>{{ $t("applications.comment") }}</strong>
                      <small>{{ props.row.description }}</small>
                    </p>
                  </div>
                </div>
              </div>
            </b-collapse>
          </template>
        </b-table-column>
        <b-table-column
          v-if="!hasPublicAuthorizations"
          v-slot="props"
          :label="$t('dataTypeAuthorizations.users')"
          field="users"
          sortable
          width="45em"
        >
          <template>
            <b-collapse
              :open="false"
              animation="slide"
              aria-id="list-users"
              class="card"
              style="box-shadow: none; background-color: transparent"
            >
              <template #trigger="detail">
                <div
                  :aria-expanded="!detail.open"
                  aria-controls="list-users"
                  class="card-header"
                  role="button"
                  style="box-shadow: none; background-color: transparent"
                >
                  <p class="card-header-title">
                    {{
                      $t("dataTypeAuthorizations.list-users", {
                        numberUsers: (props?.row?.users || []).map((use) => use.login).length,
                      })
                    }}
                  </p>
                  <a class="card-header-icon">
                    <b-tooltip :label="$t('dataTypeAuthorizations.showMore')" position="is-left">
                      <b-icon :icon="detail.open ? 'chevron-up' : 'chevron-down'" type="is-primary">
                      </b-icon>
                    </b-tooltip>
                  </a>
                </div>
              </template>
              <div class="card-content">
                <div class="content">
                  <b-field
                    :label="$t('dataTypeAuthorizations.filter-user-by-name')"
                    class="columns"
                  >
                    <b-input
                      :placeholder="$t('dataTypeAuthorizations.search')"
                      icon="search"
                      @input="filterListUserName(listUsersHasSignedCharter, $event)"
                    ></b-input>
                  </b-field>
                  <div class="columns is-multiline">
                    <b-field
                      v-for="user in resultFilterUsersByName"
                      :key="user.id"
                      class="column is-4"
                    >
                      <b-checkbox
                        v-model="checkbox[props.row.name + '_' + user.label]"
                        :disabled="
                          ((props?.row?.users || []).map((use) => use.login).length === 1 &&
                          checkbox[props.row.name + '_' + user.label] === true) && (isApplicationManager || isUserManager)
                        "
                        @input="updateListUsersInAuthorization(props.row, user.label, $event)"
                      >
                        {{ user.label }}
                      </b-checkbox>
                    </b-field>
                  </div>
                </div>
              </div>
            </b-collapse>
          </template>
        </b-table-column>
        <b-table-column
          v-slot="props"
          :label="$t('dataTypeAuthorizations.data')"
          field="authorizations"
          sortable
          width="35em"
        >
          <template>
            <b-collapse
              :open="false"
              animation="slide"
              aria-id="list-privileges"
              class="card"
              style="box-shadow: none; background-color: transparent"
            >
              <template #trigger="detail">
                <div
                  :aria-expanded="!detail.open"
                  aria-controls="list-privileges"
                  class="card-header"
                  role="button"
                  style="box-shadow: none; background-color: transparent"
                >
                  <p class="card-header-title">
                    {{
                      $t("dataTypeAuthorizations.list-privileges", {
                        numberPrivileges: Object.keys(props.row.authorizations).length,
                      })
                    }}
                  </p>
                  <a class="card-header-icon">
                    <b-tooltip :label="$t('dataTypeAuthorizations.showMore')" position="is-left">
                      <b-icon :icon="detail.open ? 'chevron-up' : 'chevron-down'" type="is-primary">
                      </b-icon>
                    </b-tooltip>
                  </a>
                </div>
              </template>
              <div class="card-content">
                <div class="content">
                  <div class="columns">
                    <SummaryTableOfAuthorizationByData
                      :application="application"
                      :authorization="props.row.authorizations"
                      :lang="lang"
                      :list-column-name="listColumnName"
                      @show-more="modifyAuthorization(props.row.uuid)"
                    >
                    </SummaryTableOfAuthorizationByData>
                  </div>
                </div>
              </div>
            </b-collapse>
          </template>
        </b-table-column>
      </b-table>
    </div>
  </PageView>
</template>

<script>
import SubMenu, { SubMenuPath } from "@/components/common/SubMenu.vue";
import PageView from "../common/PageView.vue";
import { ApplicationResult } from "@/model/ApplicationResult";
import LoadingAnimate from "@/components/common/LoadingAnimate.vue";
import services from "@/composable/services";
import { i18n } from "@/main";
import app from "@/main";
import useArray from "@/composable/components/array";
import { onMounted, ref } from "vue";
import useObject from "@/composable/components/object";
import useBoolean from "@/composable/components/boolean";
import { LOCAL_STORAGE_LANG } from "@/services/Fetcher";
import DetailAuthorizationModalCard from "@/components/common/authorization/DetailAuthorizationModalCard.vue";
import SummaryTableOfAuthorizationByData from "@/components/common/authorization/SummaryTableOfAuthorizationByData.vue";

export default {
  name: "DataTypeAuthorizationsView",
  computed: {
    services() {
      return services;
    },
  },
  components: {
    SummaryTableOfAuthorizationByData,
    LoadingAnimate,
    PageView,
    SubMenu,
    DetailAuthorizationModalCard,
  },
  props: {
    dataTypeId: {
      type: String,
    },
    applicationName: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { shallowRefArray: listAuthorizations, doChangeArray: changelistAuthorizations } =
      useArray();
    const { shallowRefArray: resultFilterAuthByName, doChangeArray: changeresultFilterAuthByName } =
      useArray();
    const {
      shallowRefArray: resultFilterUsersByName,
      doChangeArray: changeresultFilterUsersByName,
    } = useArray();
    const {
      shallowRefArray: listAuthorizationsByPublic,
      doChangeArray: changelistAuthorizationsByPublic,
    } = useArray();
    const {
      shallowRefArray: listAuthorizationsByUsers,
      doChangeArray: changelistAuthorizationsByUsers,
    } = useArray();
    const {
      shallowRefArray: listAuthorizationsByUsersBasedOnAuth,
      doChangeArray: changelistAuthorizationsByUsersBasedOnAuth,
    } = useArray();
    const { shallowRefArray: authorizations, doChangeArray: changeAuthorizations } = useArray();
    const { refBoolean: isApplicationManager, doChangeBoolean: changeIsApplicationManager } =
      useBoolean(false);
    const { refBoolean: isUserManager, doChangeBoolean: changeIsUserManager } =
      useBoolean(false);
    const { refBoolean: isDragging } = useBoolean(false);
    const { refBoolean: isCardModalActive } = useBoolean(false);
    const { refBoolean: hasAuthorizationsByUsers } = useBoolean(false);
    const { refBoolean: editable } = useBoolean(true);
    const { refBoolean: hasPublicAuthorizations } = useBoolean(false);
    let checkbox = {};
    let filterAuthByUsers = "allUsers";
    const { reactiveObject: application, doChangeObject: changeApplication } = useObject(
      new ApplicationResult()
    );
    const {
      reactiveObject: currentAuthorizationInModal,
      doChangeObject: changeCurrentAuthorizationInModal,
    } = useObject();
    const lang = localStorage.getItem(LOCAL_STORAGE_LANG);
    const listColumnName = {
      delete: {
        display: true,
        title: "delete",
        withPeriods: false,
        withDataGroups: false,
        forPublic: false,
        forRequest: false,
        internationalizationName: {
          fr: "Suppression",
          en: "Deletion",
        },
      },
      depot: {
        display: true,
        title: "depot",
        withPeriods: false,
        withDataGroups: false,
        forPublic: false,
        forRequest: false,
        internationalizationName: {
          fr: "Dépôt",
          en: "Deposit",
        },
      },
      publication: {
        display: true,
        title: "publication",
        withPeriods: false,
        withDataGroups: false,
        forPublic: false,
        forRequest: false,
        internationalizationName: {
          fr: "Publication",
          en: "Publication",
        },
      },
      extraction: {
        display: true,
        title: "extraction",
        withPeriods: true,
        withDataGroups: true,
        forPublic: true,
        forRequest: true,
        internationalizationName: {
          fr: "Extraction",
          en: "Extraction",
        },
      },
    };
    // pagination
    let offset = 0;
    let currentPage = 1;
    let perPage = 30;
    let isSelectedName = "";
    let isSelectedAuthorization = "";
    let localizations = {};
    let listAllUsers = ref({});
    let listUsersHasSignedCharter = ref({});
    let authorizationLength = 0;
    const isLoading = ref(false);
    const isLoadingModal = ref(false);
    const { shallowRefArray: subMenuPaths, doChangeArray: changeSubMenuPaths } = useArray();
    const dragOptions = () => {
      return {
        animation: 0,
        group: "description",
        disabled: !editable.value,
        ghostClass: "ghost",
      };
    };

    async function changePage(page) {
      offset = (page - 1) * perPage;
    }

    onMounted(async () => {
      await init();
      changeSubMenuPaths([
        new SubMenuPath(
          i18n.t("menu.accueil").toLowerCase(),
          () => app.$router.push(`/applications/${props.applicationName}`),
          () => app.$router.push("/applications")
        ),
        new SubMenuPath(
          i18n.t(`dataTypeAuthorizations.sub-menu-data-type-authorizations`, {
            dataType: props.dataTypeId,
          }),
          () => {
            app.$router.push(`/applications/${props.applicationName}/authorizations`);
          },
          () => app.$router.push(`/applications/${props.applicationName}`)
        ),
      ]);
    });

    async function init() {
      isLoading.value = true;
      try {
        let application = await services.applicationService.getApplication(props.applicationName, [
          "CONFIGURATION",
          "DATATYPE",
        ]);
        changeApplication(application);
        changelistAuthorizations(
          await services.authorizationService.getDataAuthorizations(props.applicationName)
        );
        changelistAuthorizations(
          listAuthorizations.value.authorizationResults.filter(
            (auth) =>
              auth.authorizationsForUser.isAdministrator ||
              Object.keys(auth.authorizations).some((datatype) =>
                auth.authorizations[datatype]?.operationTypes?.includes("admin")
              )
          )
        );
        authorizationLength = listAuthorizations.value.length;
        /*for (let i = 0; i < authorizationLength; i++) {
          let authorizationForUser = listAuthorizations.value[i].authorizationsForUser;
          changeIsApplicationManager(
            authorizationForUser.isAdministrator ||
              Object.values(authorizationForUser.authorizationResults || []).some((a) => a.admin)
          );
        }*/
        changeIsApplicationManager(
          services.loginService.getAuthenticatedUser().isApplicationManager(application.id)
        );
        changeIsUserManager(
          services.loginService.getAuthenticatedUser().isUserManager(application.id)
        );
        let localeListAllUsers = await services.authorizationService.getAuthorizationGrantableInfos(
          props.applicationName
        );
        listAllUsers.value = localeListAllUsers.users.filter((user) => user.label !== "_public_");
        listUsersHasSignedCharter.value = localeListAllUsers.users.filter(
          (user) => user.label !== "_public_" && user.isApplicationUser
        );
        changelistAuthorizationsByPublic(
          listAuthorizations.value.filter((auth) => auth.users[0].login === "_public_")
        );
        changelistAuthorizations(
          listAuthorizations.value.filter(
            (auth) => auth.users[0].login !== "_public_" || auth.users.length < 1
          )
        );
        for (let i = 0; i < listAuthorizations.value.length; i++) {
          for (let j = 0; j < listAuthorizations.value[i].users.length; j++) {
            checkbox[
              listAuthorizations.value[i].name + "_" + listAuthorizations.value[i].users[j].login
            ] = true;
          }
        }
        changeAuthorizations(
          hasPublicAuthorizations ? listAuthorizations.value : listAuthorizationsByPublic.value
        );
        changeresultFilterAuthByName(listAuthorizations.value);
        changeresultFilterUsersByName(listUsersHasSignedCharter.value);
        updateListAuthorizationsByUsers();
      } catch (error) {
        services.alertService.toastServerError(error);
      }
      isLoading.value = false;
    }

    function updateListAuthorizationsByUsers() {
      const localeAuthByUser = {};
      listAllUsers.value.forEach((user) => {
        listAuthorizations.value.forEach((auth) => {
          if (!localeAuthByUser[user.id] && user.label !== "_public_" && user.isApplicationUser) {
            localeAuthByUser[user.id] = {
              id: user.id,
              name: user.label,
              email: user.email,
              isApplicationManager: user.isApplicationManager,
              hasSignedCharter: user.hasSignedCharter,
              isApplicationUser: user.isApplicationUser,
              isUserManager: user.isUserManager,
              auth: [],
            };
          }
          auth.users.forEach((authUser) => {
            if (authUser.id === user.id) {
              // Vérifier si cette autorisation n'est pas déjà ajoutée
              const authExists = localeAuthByUser[authUser.id].auth.some((a) => a.id === auth.uuid);
              if (!authExists) {
                localeAuthByUser[authUser.id].auth.push({
                  id: auth.uuid,
                  name: auth.name,
                });
              }
            }
          });
        });
      });
      changelistAuthorizationsByUsersBasedOnAuth(Object.values(localeAuthByUser));
      changelistAuthorizationsByUsers(listAuthorizationsByUsersBasedOnAuth.value);
    }

    function updateListUsersInAuthorization(authorization, userName, value) {
      checkbox[authorization.name + "_" + userName] = value;
      // construction de l'object de la response de la requête
      for (let i = 0; i < listAuthorizations.value.length; i++) {
        if (listAuthorizations.value[i].name === authorization.name) {
          if (value) {
            authorization.users.push(
              listUsersHasSignedCharter.value.filter(
                (user) => (user.login ? user.login : user.label) === userName
              )[0]
            );
            listAuthorizations.value[i].users = authorization.users;
          } else {
            listAuthorizations.value[i].users = authorization.users.filter(
              (user) => (user.login ? user.login : user.label) !== userName
            );
          }
          let authorizationForAll = {};
          let authorizationsWithRestriction = {};
          for (let authorization in listAuthorizations.value[i].authorizations) {
            if (
              !Object.keys(
                listAuthorizations.value[i].authorizations[authorization].requiredAuthorizations
              ).length !== 0
            ) {
              authorizationForAll[authorization] = Object.values(
                listAuthorizations.value[i].authorizations[authorization].operationTypes
              );
            } else {
              authorizationsWithRestriction[authorization] = Object.values(
                listAuthorizations.value[i].authorizations[authorization].operationTypes
              );
            }
          }
          let localAuthorization = {
            uuid: listAuthorizations.value[i].uuid,
            name: listAuthorizations.value[i].name,
            description: listAuthorizations.value[i].description,
            usersId: listAuthorizations.value[i].users.map((user) => user.id),
            authorizationForAll,
            authorizationsWithRestriction,
          };
          services.authorizationService.createAuthorization(
            props.applicationName,
            localAuthorization
          );
          updateListAuthorizationsByUsers();
        }
      }
    }

    function addAuthorization() {
      app.$router.push(`/applications/${props.applicationName}/authorizations/new`);
    }

    function modifyAuthorization(id) {
      if (hasPublicAuthorizations.value) {
        app.$router.push(`/applications/${props.applicationName}/authorizations/public/${id}`);
      } else {
        app.$router.push(`/applications/${props.applicationName}/authorizations/${id}`);
      }
    }

    async function openAuthorizationModal(id) {
      isCardModalActive.value = true;
      await displayAuthById(id);
    }

    async function displayAuthById(id) {
      isLoadingModal.value = true;
      let currentAuthorization;
      try {
        currentAuthorization =
          await services.authorizationService.getAdminAuthorizationsForOpenAdom(
            props.applicationName,
            id
          );
        changeCurrentAuthorizationInModal(currentAuthorization);
      } finally {
        isLoadingModal.value = false;
      }
    }

    async function displayUsersBasedOnAuth(event) {
      filterAuthByUsers = event;
      changelistAuthorizationsByUsersBasedOnAuth(listAuthorizationsByUsers.value);
      if (filterAuthByUsers === "userWithAuth") {
        changelistAuthorizationsByUsersBasedOnAuth(
          listAuthorizationsByUsersBasedOnAuth.value.filter((user) => user.auth.length !== 0)
        );
      } else if (filterAuthByUsers === "userWithoutAuth") {
        changelistAuthorizationsByUsersBasedOnAuth(
          listAuthorizationsByUsersBasedOnAuth.value.filter((user) => user.auth.length === 0)
        );
      }
    }

    function addPublicAuthorization() {
      app.$router.push(`/applications/${props.applicationName}/authorizations/public/new`);
    }

    async function revoke(id) {
      try {
        await services.authorizationService.revokeAuthorization(props.applicationName, id);
        services.alertService.toastSuccess(i18n.t("alert.revoke-authorization"));
        listAuthorizations.value.splice(
          listAuthorizations.value.findIndex((a) => a.id === id),
          1
        );
      } catch (error) {
        services.alertService.toastServerError(error);
      }
      window.location.reload();
    }

    function onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed;
    }

    function updateTableAuthorizations() {
      if (hasPublicAuthorizations.value) {
        hasPublicAuthorizations.value
          ? (hasAuthorizationsByUsers.value = !hasPublicAuthorizations.value)
          : hasAuthorizationsByUsers.value;
        changeAuthorizations(listAuthorizationsByPublic.value);
      } else {
        changeAuthorizations(listAuthorizations.value);
      }
    }

    function filterListAuthName(listAuth, event) {
      changeresultFilterAuthByName(
        listAuth.filter((auth) =>
          auth.name.toString().toLowerCase().includes(event.toString().toLowerCase())
        )
      );
    }

    function filterListUserName(listUser, event) {
      changeresultFilterUsersByName(
        listUser.filter((user) =>
          user.label.toString().toLowerCase().includes(event.toString().toLowerCase())
        )
      );
    }

    return {
      listAuthorizationsByPublic,
      listAuthorizationsByUsers,
      listAuthorizationsByUsersBasedOnAuth,
      application,
      listAuthorizations,
      subMenuPaths,
      isLoading,
      isLoadingModal,
      authorizationLength,
      checkbox,
      listAllUsers,
      listUsersHasSignedCharter,
      localizations,
      isSelectedAuthorization,
      isSelectedName,
      currentPage,
      offset,
      authorizations,
      isUserManager,
      isApplicationManager,
      perPage,
      isDragging,
      listColumnName,
      lang,
      dragOptions,
      onMove,
      changePage,
      addAuthorization,
      modifyAuthorization,
      addPublicAuthorization,
      revoke,
      updateListUsersInAuthorization,
      updateTableAuthorizations,
      hasAuthorizationsByUsers,
      hasPublicAuthorizations,
      filterAuthByUsers,
      isCardModalActive,
      currentAuthorizationInModal,
      openAuthorizationModal,
      displayUsersBasedOnAuth,
      filterListAuthName,
      resultFilterAuthByName,
      filterListUserName,
      resultFilterUsersByName,
    };
  },
};
</script>
<style lang="scss">
td {
  padding: 6px;

  .columns {
    margin: 0;

    .column.is-half {
      padding: 6px;
    }
  }
}

.listAuthorization {
  border: solid #dbdbdb;
  border-width: 0 0 1px;
  margin: 0 10px 0 10px;
  padding: 15px;
}

.listAuthorization:nth-child(odd) {
  background-color: #f5f5f5;
}

.b-table .table th .sort-icon {
  left: 100%;
  margin-left: 1em;
  position: initial;
}

.sort-icon {
  margin-bottom: 1em;
}

.content table tbody tr:last-child td,
.content table tbody tr:last-child td {
  border: 0.5px solid rgb(230, 230, 230);
}
</style>
