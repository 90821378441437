<template>
  <div>
    <canvas
      id="availiblity-chart"
      ref="availiblityChart"
      :aria-label="id"
      class="availiblity-chart"
      height="40px"
    />
  </div>
</template>
<script>
import { onMounted } from "vue";

export default {
  name: "AvailiblityChart",
  props: {
    minmax: [],
    ranges: [],
    id: {
      type: String,
    },
    showDates: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    let canvas = null;
    let ctx = null;
    let width;
    let canvasW = 0;
    let canvasH = 0;

    onMounted(mounted);

    function mounted() {
      canvas = this.$el.children[0];
      ctx = canvas.getContext("2d");
      canvas.width = width || this.$el.clientWidth;
      canvasW = canvas.width * 0.9;
      canvasH = canvas.height;
      draw();
    }

    function writeDates() {
      if (!props.minmax?.length) return;
      let min = new Date(props.minmax[0]);
      let currentDate = new Date();
      currentDate.setTime(min.getTime());
      let max = new Date(props.minmax[1]);
      let intervale = (max.getTime() - min.getTime()) / 10;
      let formatLocalDate = (date) => date.toLocaleDateString();
      let formatDate;
      if (max.getYear() - min.getYear() > 10) {
        formatDate = (date) => date.getYear();
      } else if (max.getYear() - min.getYear() > 1) {
        formatDate = (date) =>
          date.toLocaleDateString().slice(3, 6) + date.toLocaleDateString().slice(8, 10);
      } else if (intervale / 3600 / 24 / 30 / 100 > 1) {
        formatDate = (date) => date.toLocaleDateString().slice(0, 5);
      } else {
        formatDate = (date) => date.getHours() + ":" + date.getMinutes();
      }
      ctx.font = "16px Arial";
      for (let i = 0; i <= 10; i++) {
        ctx.textAlign = "center";
        if (i === 0) ctx.textAlign = "start";
        if (i === 10) ctx.textAlign = "end";
        currentDate.setTime(min.getTime() + intervale * i);
        let inf =
          ((currentDate.getTime() - min.getTime()) / (max.getTime() - min.getTime())) * canvasW;
        if (props.showDates || i === 0 || i === 10) {
          ctx.fillText(
            i === 0 || i === 10 ? formatLocalDate(currentDate) : formatDate(currentDate),
            inf + canvasW * 0.05,
            (canvasH * 3) / 4 + 5
          );
          ctx.beginPath();
          ctx.lineWidth = 3;
          ctx.strokeStyle = "#006464FF";
          ctx.moveTo(inf + canvasW * 0.05, canvasH / 2.5);
          ctx.lineTo(inf + canvasW * 0.05, -canvasH);
          ctx.stroke();
        }
      }
    }

    function drawIntervale(range) {
      const min = new Date(props.minmax[0]);
      const max = new Date(props.minmax[1]);
      let inf =
        ((new Date(range[0]).getTime() - min.getTime()) / (max.getTime() - min.getTime())) *
        canvasW;
      let sup =
        ((new Date(range[1]).getTime() - min.getTime()) / (max.getTime() - min.getTime())) *
        canvasW;

      ctx.beginPath();
      ctx.lineWidth = canvasH / 3;
      ctx.strokeStyle = "#00A6A3FF";
      ctx.moveTo(inf + canvas.width * 0.05, canvasH / 4);
      ctx.lineTo(sup + canvas.width * 0.05, canvasH / 4);
      ctx.stroke();
    }

    function draw() {
      for (const rangeIndex in props.ranges) {
        drawIntervale(props.ranges[rangeIndex]);
      }
      writeDates();
    }
  },
};
</script>
<style lang="scss" scoped>
.availiblity-chart {
  margin-left: auto;
  align-items: end;
}
</style>
