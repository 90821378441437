import app from "@/main";
import services from "@/composable/services";
import { ref } from "vue";

export function useRedirections(application = { authorizations: [] }) {
  const canCreateApplication = (application) =>
    services.loginService.getAuthenticatedUser().isApplicationCreator(application);
  const tmpCharte = ref();

  // application
  /*
    limited to applicationCreator
     */
  function createApplication() {
    return canCreateApplication() && app.$router.push("/applicationCreation");
  }

  /*
    limited to applicationCreator
     */
  function updateApplication(applicationName, applicationVersion) {
    return (
      canCreateApplication() &&
      app.$router.push(`/applicationCreation/${applicationName}/${applicationVersion}`)
    );
  }

  /*
    limited to applicationCreator
     */
  async function downloadYamlApplication(application) {
    await (canCreateApplication() &&
      services.fileService.download(application.name, application.configFile));
    return false;
  }

  function landingPageApplication(application) {
    let redirection = function () {
      app.$router.push(`/applications/${application.name}`);
    };
    if (!application?.mustSignCharte()) {
      redirection();
    } else {
      return downloadCharteApplication(application.id, redirection);
    }
  }
  async function downloadApplicationUploadBundle(application) {
    await (canCreateApplication() &&
      services.applicationService.downloadApplicationUploadBundle(application.name, false));
    return false;
  }
  async function downloadApplicationUploadBundleWithData(application) {
    await (canCreateApplication() &&
      services.applicationService.downloadApplicationUploadBundle(application.name, true));
    return false;
  }

  /*
    forAll
     */
  function downloadCharteApplication(applicationId, redirection) {
    let url = services.additionalFileService.getPath(
      `applications/${applicationId}/additionalFiles?` +
        new URLSearchParams({
          params: JSON.stringify({
            uuids: [applicationId],
            filetype: "__charte__",
          }),
        }).toString()
    );
    return {
      url: url.href,
      redirection,
    };
  }

  /*
    limited to applicationCreator
     */

  //applications/monsores/additionalFiles?params={"uuids":["ab66022e-db46-4fcd-8115-e62d14c54167"]}
  async function uploadCharteApplication(application) {
    (await canCreateApplication(application)) &&
      services.additionalFileService.addAdditionalFile(
        application.name,
        "__charte__",
        tmpCharte.value,
        {
          id: application.id,
          fileType: "__charte__",
        }
      );
    services.alertService.toastSuccess("alert.application-charte_upload-success");
    return downloadCharteApplication(application.id);
  }

  //references

  /*
    forAll
     */
  function displayReferencesManagement(application) {
    let redirection = function () {
      app.$router.push(`/applications/${application.name}/references`);
    };
    if (!application?.mustSignCharte()) {
      redirection();
    } else {
      return downloadCharteApplication(application.id, redirection);
    }
  }

  /*
    restricted
     */
  function showReferenceRights(application) {
    let redirection = function () {
      app.$router.push(`/applications/${application.name}/references/authorizations`);
    };
    if (!application?.mustSignCharte()) {
      redirection();
    } else {
      return downloadCharteApplication(application.id, redirection);
    }
  }

  //data
  function displayDataSetManagement(application) {
    let redirection = function () {
      app.$router.push(`/applications/${application.name}/dataTypes`);
    };
    if (!application?.mustSignCharte()) {
      redirection();
    } else {
      return downloadCharteApplication(application.id, redirection);
    }
  }

  function showDataRights(application) {
    let redirection = function () {
      app.$router.push(`/applications/${application.name}/authorizations`);
    };
    if (!application?.mustSignCharte()) {
      redirection();
    } else {
      return downloadCharteApplication(application.id, redirection);
    }
  }

  //additionalFiles

  function displayAdditionalFilesManagement() {
    let redirection = function () {
      app.$router.push(`/applications/${application.name}/additionalFiles`);
    };
    if (!application?.mustSignCharte()) {
      redirection();
      return false;
    } else {
      return downloadCharteApplication(application.id, redirection);
    }
  }
  function authorizationsManagementForApplicationUsers() {
    let redirection = function () {
      app.$router.push(
        `/applications/${application.name}/authorizationsManagementForApplicationUsers`
      );
    };
    if (!application?.mustSignCharte()) {
      redirection();
    } else {
      return downloadCharteApplication(application.id, redirection);
    }
  }

  // rights

  function requestRights() {
    let redirection = function () {
      app.$router.push(`/applications/${application.name}/authorizationsRequest`);
    };
    if (!application?.mustSignCharte()) {
      redirection();
    } else {
      return downloadCharteApplication(application.id, redirection);
    }
  }

  function showRequestRights() {
    let redirection = function () {
      app.$router.push(`/applications/${application.name}/authorizationsRequest/new`);
    };
    if (!application?.mustSignCharte()) {
      redirection();
    } else {
      return downloadCharteApplication(application.id, redirection);
    }
  }

  function showAdditionalFilesManagementRights() {
    if (!application?.mustSignCharte()) {
      app.$router.push(`/applications/${application.name}/additionalFiles/authorizations`);
      return false;
    } else {
      return downloadCharteApplication(application.id);
    }
  }

  return {
    createApplication,
    updateApplication,
    downloadYamlApplication,
    downloadCharteApplication,
    uploadCharteApplication,
    tmpCharte,
    displayReferencesManagement,
    showReferenceRights,
    displayDataSetManagement,
    showDataRights,
    displayAdditionalFilesManagement,
    showAdditionalFilesManagementRights,
    requestRights,
    showRequestRights,
    downloadApplicationUploadBundle,
    downloadApplicationUploadBundleWithData,
    landingPageApplication,
    authorizationsManagementForApplicationUsers,
  };
}
