export function dataLoader(services) {
  const dataService = services.dataService;
  const references = {};

  const getOrLoadDataByNaturalKey = async function (application, referenceName, key) {
    let applicationName = application.configuration.applicationDescription.name;
    let reference = references?.[referenceName]?.[key];
    if (reference) {
      return reference;
    }
    if (typeof key === "string")
      reference = await dataService.getDataByNaturalKey(applicationName, referenceName, key);
    if (!reference) {
      return null;
    }
    reference = {
      ...reference,
      localRefName: services.internationalisationService.localeReferenceNames(
        referenceName,
        application
      ),
      referenceName,
      values: reference.rows?.[0],
      localName:
        reference.rows.length !== 0 ? getValueDisplay(reference.rows?.[0], application) : key,
    };
    references[referenceName] = references[referenceName] || {};
    references[referenceName][key] = reference;
    return reference;
  };

  const getDisplayValueForLink = function (rowValue, column, listDisplayRowsValue, application) {
    if (
      listDisplayRowsValue[column.componentKey] &&
      Object.keys(listDisplayRowsValue[column.componentKey]).includes(rowValue)
    ) {
      return listDisplayRowsValue[column.componentKey][rowValue].localeName !== rowValue
        ? getValueDisplay(listDisplayRowsValue[column.componentKey][rowValue].rows[0], application)
        : listDisplayRowsValue[column.componentKey][rowValue].localName;
    }
    return rowValue;
  };

  const getValueDisplay = function (referenceLocal, application) {
    if (!referenceLocal?.values) {
      return "";
    }
    if (referenceLocal.values["__display_" + services.internationalisationService.getLocale()]) {
      return referenceLocal.values["__display_" + services.internationalisationService.getLocale()];
    }
    if (
      referenceLocal.values[
        "__display___display_" + services.internationalisationService.getLocale()
      ]
    ) {
      return referenceLocal.values[
        "__display___display_" + services.internationalisationService.getLocale()
      ];
    }
    if (
      application &&
      referenceLocal.values[
        "__display_" + application.configuration.applicationDescription.defaultLanguage
      ]
    ) {
      return referenceLocal.values[
        "__display_" + application.configuration.applicationDescription.defaultLanguage
      ];
    }
    if (
      application &&
      referenceLocal.values[
        "__display___display_" + application.configuration.applicationDescription.defaultLanguage
      ]
    ) {
      return referenceLocal.values[
        "__display___display_" + application.configuration.applicationDescription.defaultLanguage
      ];
    } else {
      return referenceLocal.values["__display_default"];
    }
  };

  const getColumnNameView = function (columnId, application, dataId, column) {
    let columnName;
    if (!column) {
      columnName = "this is an error " + columnId;
    } else if (column.type === "PatternComponent") {
      columnName = column.originalName || "not found";
    } else if (application.data[dataId].componentDescriptions[columnId]) {
      let importHeader = application.data[dataId].componentDescriptions[columnId].importHeader;
      let exportHeader = column
        ? column.exportHeader
        : application.data[dataId].componentDescriptions[columnId].exportHeaderName;
      if (exportHeader !== columnId) {
        columnName = exportHeader || columnId;
      } else if (importHeader !== undefined && importHeader !== columnId) {
        columnName = importHeader;
      } else if (columnId.match(".*::.*")) {
        columnName = services.internationalisationService.localeReferenceColumnsNames(
          dataId,
          columnId,
          application
        );
      } else {
        columnName = columnId;
      }
    } else if (columnId.match("(.*::.*)::.*")) {
      columnName = services.internationalisationService.localeReferenceColumnsNames(
        dataId,
        columnId.match("(.*::.*)::.*")[1],
        application
      );
    } else if (columnId.match(".*::.*")) {
      columnName = services.internationalisationService.localeReferenceColumnsNames(
        dataId,
        columnId,
        application
      );
    } else {
      columnName = columnId;
    }
    if (columnName.match(".*::.*")) {
      columnName = columnId.match("(.*)::(.*)")[2];
    }
    return columnName;
  };
  return {
    getOrLoadDataByNaturalKey,
    getValueDisplay,
    getColumnNameView,
    getDisplayValueForLink,
  };
  //const getOrLoadData = function()
}
